import React, { useEffect, useState } from 'react';
import { useAddress, useBalance } from '@thirdweb-dev/react';
import CardDataStats from '../../components/CardDataStats';
import CryptoChart from '../../components/Charts/CryptoChart';
import BitcoinDominanceChart from './OverView/BitcoinDominanceChart';
import TopCryptoCurrenciesByMarketCap from './OverView/TopCryptoCurrenciesByMarketCap';
import TableOne from '../../components/Tables/TableOne';
import DefaultLayout from '../../layout/DefaultLayout';
import { FaWallet, FaPiggyBank, FaDollarSign, FaCoins } from 'react-icons/fa';

const ECommerce: React.FC = () => {
  const address = useAddress();
  const { data: balance } = useBalance(address);

  const [walletBalance, setWalletBalance] = useState<string>('0');
  const [totalBalance, setTotalBalance] = useState<string>('0');
  const [defiBalance, setDefiBalance] = useState<string>('0');
  const [nftBalance, setNftBalance] = useState<string>('0');

  const [walletRate, setWalletRate] = useState<string>('0%');
  const [totalRate, setTotalRate] = useState<string>('0%');
  const [defiRate, setDefiRate] = useState<string>('0%');
  const [nftRate, setNftRate] = useState<string>('0%');

  useEffect(() => {
    if (balance) {
      const balanceValue = parseFloat(balance.displayValue);

      // Update wallet balance
      const prevWalletBalance = parseFloat(walletBalance);
      const newWalletBalance = balanceValue;
      const walletChange = ((newWalletBalance - prevWalletBalance) / (prevWalletBalance || 1)) * 100;
      setWalletBalance(newWalletBalance.toFixed(2));
      setWalletRate(walletChange.toFixed(2) + '%');

      // Example calculations for other balances and their rates
      const newTotalBalance = newWalletBalance * 1.1;
      const prevTotalBalance = parseFloat(totalBalance);
      const totalChange = ((newTotalBalance - prevTotalBalance) / (prevTotalBalance || 1)) * 100;
      setTotalBalance(newTotalBalance.toFixed(2));
      setTotalRate(totalChange.toFixed(2) + '%');

      const newDefiBalance = newWalletBalance * 0.5;
      const prevDefiBalance = parseFloat(defiBalance);
      const defiChange = ((newDefiBalance - prevDefiBalance) / (prevDefiBalance || 1)) * 100;
      setDefiBalance(newDefiBalance.toFixed(2));
      setDefiRate(defiChange.toFixed(2) + '%');

      const newNftBalance = newWalletBalance * 0.2;
      const prevNftBalance = parseFloat(nftBalance);
      const nftChange = ((newNftBalance - prevNftBalance) / (prevNftBalance || 1)) * 100;
      setNftBalance(newNftBalance.toFixed(2));
      setNftRate(nftChange.toFixed(2) + '%');
    }
  }, [balance, defiBalance, nftBalance, totalBalance, walletBalance]);

  return (
    <DefaultLayout>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
        <CardDataStats
          title="Total Balance"
          total={`$${totalBalance}K`}
          rate={totalRate}
          levelUp={parseFloat(totalRate) >= 0}
        >
          <FaDollarSign className="text-white" size={22} />
        </CardDataStats>
        <CardDataStats
          title="Wallet Balance"
          total={`$${walletBalance}`}
          rate={walletRate}
          levelUp={parseFloat(walletRate) >= 0}
        >
          <FaWallet className="text-white" size={20} />
        </CardDataStats>
        <CardDataStats
          title="DeFi Balance"
          total={`${defiBalance}%`}
          rate={defiRate}
          levelUp={parseFloat(defiRate) >= 0}
        >
          <FaPiggyBank className="text-white" size={22} />
        </CardDataStats>
        <CardDataStats
          title="NFT Balance"
          total={`${nftBalance}%`}
          rate={nftRate}
          levelUp={parseFloat(nftRate) >= 0}
        >
          <FaCoins className="text-white" size={22} />
        </CardDataStats>
      </div>
      <div className="mt-4 grid gap-4 md:grid-cols-2 md:gap-6 2xl:grid-cols-3 2xl:gap-7.5">
        <div className="col-span-1 md:col-span-2 2xl:col-span-1">
          <div className="w-full">
            <CryptoChart />
          </div>
        </div>
        <div className="col-span-1 md:col-span-2 2xl:col-span-1">
          <div className="w-full overflow-x-auto sm:overflow-x-hidden">
            <TableOne />
          </div>
        </div>
        <div className="col-span-1 md:col-span-2 2xl:col-span-1">
          <div className="w-full flex flex-col sm:flex-row">
            <div className="w-full sm:w-1/2 pr-2 mb-4 sm:mb-0">
              <BitcoinDominanceChart />
            </div>
            <div className="w-full sm:w-1/2 pl-2">
              <TopCryptoCurrenciesByMarketCap />
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default ECommerce;












