import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot from "react-dom/client"
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from './Context/AuthContext'; // Adjust the path as necessary
import { ThirdwebProvider, ChainId } from '@thirdweb-dev/react';


const activeChainId = ChainId.Mainnet; // or any other chain you are using

const root = createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <React.StrictMode>
    <AuthContextProvider>
    <ThirdwebProvider desiredChainId={activeChainId}>
      <App />
    </ThirdwebProvider>,
    </AuthContextProvider>
    </React.StrictMode>
  </BrowserRouter>
);
