import React from 'react';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`;

const Section = styled.section`
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
`;

const List = styled.ul`
  padding-left: 20px;
  list-style-type: disc;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  margin-bottom: 10px;
`;

const KYC = () => {
  return (
    <Container>
      <Title>Know Your Customer (KYC) and Compliance</Title>
      
      <Section>
        <SectionTitle>Security and trust are paramount.</SectionTitle>
        <Paragraph>
          We prioritize a robust Know Your Customer (KYC) program to ensure a safe and reliable experience for all users. Our KYC process verifies your identity and helps prevent fraud on our platform.
        </Paragraph>
        <SectionTitle>Why KYC</SectionTitle>
        <List>
          <ListItem>Enhanced Security: KYC helps us identify and prevent suspicious activity, protecting both you and your digital assets.</ListItem>
          <ListItem>Regulatory Compliance: We adhere to global and local regulations, including anti-money laundering (AML) standards.</ListItem>
          <ListItem>Building Trust: A secure platform fosters trust and confidence for all Laani users.</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>What to Expect During KYC:</SectionTitle>
        <Paragraph>Completing your KYC verification with Laani is a simple and secure process:</Paragraph>
        <List>
          <ListItem>Simple & Secure Verification: A streamlined process to confirm your identity.</ListItem>
          <ListItem>ID Verification Steps: A clear breakdown of the verification requirements.</ListItem>
          <ListItem>Protecting Your Information: How we prioritize your data security.</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>Our Commitment:</SectionTitle>
        <Paragraph>
          Laani is dedicated to providing a secure and trustworthy platform for all users. We prioritize the highest standards in KYC practices, ensuring regulatory compliance and upholding user safety. Throughout the verification process, we prioritize accuracy and utilize cutting-edge technology to safeguard your personal information. Your privacy and data security are paramount to us.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>Benefits of Completing KYC:</SectionTitle>
        <Paragraph>
          Completing your KYC verification unlocks a world of benefits on Laani. Verified users enjoy increased transaction limits, allowing you to trade with greater flexibility. KYC also grants access to a wider range of our features and functionalities, empowering you to take full advantage of the Laani platform. Most importantly, completing KYC verification fosters a more secure trading environment for everyone, giving you the peace of mind to trade with confidence.
        </Paragraph>
        <List>
          <ListItem>Unlock More Features & Limits: Expand your trading potential on Laani.</ListItem>
          <ListItem>Enhanced Security & Peace of Mind: Trade with confidence on a verified platform.</ListItem>
          <ListItem>Faster Transactions & More Control: Enjoy a smoother and more efficient trading experience.</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>We understand that KYC might seem like an extra step, but it's a crucial one for a secure and trustworthy platform. Thank you for your cooperation!</SectionTitle>
        <Paragraph>
          Laani is committed to maintaining transparency and compliance with regulatory requirements. We regularly monitor our platform for suspicious activities and report any suspicious transactions to relevant authorities.
        </Paragraph>
      </Section>
    </Container>
  );
}

export default KYC;
