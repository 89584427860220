import React from 'react';
import { Enteprise } from '../constants';
import Section from '../components/Section';
import Heading from '../ui/PageUI/Heading';
import Subheading from '../ui/PageUI/SubHeading';
import Button from '../components/Button';
import Description from '../ui/PageUI/Description';
import FeatureCard from '../ui/PageUI/FeatureCard';
import MainImage from '../Assets/InsttitutionalMain.png';
import Intro from '../ui/PageUI/Intro';
import ImageWithText from '../ui/PageUI/ImageWithText';
import imageSrc from '../Assets/Institutions.png';
import Arrow from '../Assets/svg/Arrow';
import { GradientLight } from '../components/Design/Benefits';
import ClipPath from '../Assets/svg/ClipPath';
import Toggle from '../components/toggle';
import Accordion from '../components/Accordion';

const faqsData = [
  {
    question: 'How do institutions get started with Laani?',
    answer: 'To get started, institutions need to create an account, complete the verification process, and set up their wallet for transactions.'
  },
  {
    question: 'How can institutions manage their account?',
    answer: 'Institutions can manage their accounts through our user-friendly dashboard, offering tools for portfolio management, transaction history, and more.'
  },
  {
    question: 'How do institutions fund their account?',
    answer: 'Institutions can fund their accounts via bank transfer, crypto transfer, or other supported payment methods listed on our platform.'
  },
  {
    question: 'What cryptocurrencies does Laani offer?',
    answer: 'Laani offers a wide range of cryptocurrencies including Bitcoin, Ethereum, Litecoin, and many others. A full list is available on our platform.'
  },
  {
    question: 'What security measures does Laani offer?',
    answer: 'Laani offers top-notch security including multi-signature wallets, 2FA, and partnerships with renowned custodians to ensure asset safety.'
  },
  {
    question: 'How do institutions trade on Laani?',
    answer: 'Institutions can trade via our spot trading platform, OTC desk, or engage in derivatives and futures trading with advanced tools and analytics.'
  },
  {
    question: 'What is OTC trading?',
    answer: 'OTC trading allows institutions to trade large volumes of cryptocurrencies directly with a counterparty, providing better liquidity and privacy.'
  },
  {
    question: 'What are derivatives and futures?',
    answer: 'Derivatives and futures are financial instruments that derive their value from underlying assets, allowing institutions to hedge risks or speculate on price movements.'
  },
  {
    question: 'How does Laani ensure deep liquidity?',
    answer: 'Laani ensures deep liquidity through its extensive network of liquidity providers, robust order matching engine, and advanced trading algorithms.'
  },
  {
    question: 'What is asset safety?',
    answer: 'Asset safety involves secure storage solutions, regulatory compliance, and insurance coverage to protect institutional assets on the Laani platform.'
  },
  {
    question: 'How does Laani achieve cost efficiency?',
    answer: 'Laani offers competitive trading fees, optimized execution costs, and comprehensive service packages to ensure cost efficiency for institutions.'
  },
  {
    question: 'What partnerships does Laani have?',
    answer: 'Laani partners with leading custodians, financial institutions, and technology providers to offer secure and reliable services to institutions.'
  },
  {
    question: 'What is proof of reserves?',
    answer: 'Proof of reserves is a process where Laani regularly publishes audit results showing that it holds enough assets to cover all customer balances.'
  },
  {
    question: 'What are Laani’s security policies?',
    answer: 'Laani’s security policies include data encryption, regular security audits, and adherence to industry best practices to safeguard institutional assets.'
  },
  {
    question: 'How can institutions contact Laani for support?',
    answer: 'Institutions can contact Laani for support by emailing enterprise@laani.io or scheduling a demo and consultation through our platform.'
  }
];

const InstitutionsPage = () => {
  return (
    <Section id="features">
      <div className="container mx-auto p-8 text-center">
        <Heading text="Enterprise" className="text-4xl font-bold mb-4" />
        <Subheading text="A simple and secure way to work with Digital Assets" className="text-2xl mb-8" />
        <Button text="Book Demo" onClick={() => alert('Button Clicked!')} />
        <div className="container relative z-2">
          <div className="my-8">
            <Intro text="Empower your business with secure and scalable crypto solutions." className="text-lg mb-8" />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mb-10">
            {Enteprise.map((item) => (
              <div
                className="block relative p-0.5 bg-no-repeat bg-[length:100%_100%] md:max-w-[32rem]"
                style={{ backgroundImage: `url(${item.backgroundUrl})` }}
                key={item.id}
              >
                <div className="relative z-2 flex flex-col min-h-[22rem] p-[2.4rem] pointer-events-none">
                  <h5 className="h5 mb-5">{item.title}</h5>
                  <p className="body-2 mb-6 text-n-3">{item.text}</p>
                  <div className="flex items-center mt-auto">
                    <img src={item.iconUrl} width={48} height={48} alt={item.title} />
                    <p className="ml-auto font-code text-xs font-bold text-n-1 uppercase tracking-wider">
                      Book Demo
                    </p>
                    <Arrow />
                  </div>
                </div>
                {item.light && <GradientLight />}
                <div
                  className="absolute inset-0.5 bg-n-8"
                  style={{ clipPath: "url(#benefits)" }}
                >
                  <div className="absolute inset-0 opacity-0 transition-opacity hover:opacity-10">
                    {item.imageUrl && (
                      <img
                        src={item.imageUrl}
                        width={380}
                        height={362}
                        alt={item.title}
                        className="w-full h-full object-cover"
                      />
                    )}
                  </div>
                </div>
                <ClipPath />
              </div>
            ))}
          </div>
          <Toggle />
        </div>
        <img className="w-1/3 h-auto my-4 mx-auto" src={MainImage} alt="About Laani" />
        <Description text="Laani is the trusted partner for forward-thinking institutions entering the crypto space." />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <FeatureCard 
            title="Security & Reliability" 
            description="Robust infrastructure prioritizes the safety of your assets. Multi-signature technology and industry-leading security protocols, to ensure peace of mind for even the most risk-averse institutions." 
          />
          <FeatureCard 
            title="Compliance at the Forefront" 
            description="We understand the regulatory landscape surrounding crypto. Laani operates with the highest compliance standards, providing the necessary tools and guidance to navigate regulations smoothly." 
          />
          <FeatureCard 
            title="Building Wealth" 
            description="We provide a secure, compliant, and customer-centric platform that empowers leading organizations like banks, fund managers, neobanks, and investment houses to confidently participate in the digital asset revolution." 
          />
        </div>
        <div className="container mx-auto px-8">
          <ImageWithText
            heading="Schedule a Demo & Consultation with Laani Today"
            paragraph="At Laani, we understand that each institution has unique needs and goals when it comes to crypto. Our team of experts is here to help you navigate the complexities of the market and discover how Laani's comprehensive solutions can empower your business."
            imageUrl={imageSrc}
          />
        </div>
        <Button text="Book Demo" onClick={() => alert('Button Clicked!')} />
        
        <div className="my-16">
          <Heading text="Frequently Asked Questions" className="text-3xl font-bold mb-4" />
          <Accordion data={faqsData} />
        </div>

        <div className="my-16">
          <Heading text="Why Institutions Choose Us" className="text-3xl font-bold mb-4" />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <FeatureCard 
              title="Deep Liquidity" 
              description="Laani ensures deep liquidity through its extensive network of liquidity providers, robust order matching engine, and advanced trading algorithms." 
            />
            <FeatureCard 
              title="Asset Safety" 
              description="Our top-notch security includes multi-signature wallets, 2FA, and partnerships with renowned custodians to ensure asset safety." 
            />
            <FeatureCard 
              title="Cost Efficiency" 
              description="We offer competitive trading fees, optimized execution costs, and comprehensive service packages to ensure cost efficiency for institutions." 
            />
          </div>
        </div>

        <div className="my-16">
          <Heading text="Safety" className="text-3xl font-bold mb-4" />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <FeatureCard 
              title="Partnerships with Renowned Custodians" 
              description="Laani partners with leading custodians, financial institutions, and technology providers to offer secure and reliable services to institutions." 
            />
            <FeatureCard 
              title="Proof of Reserves" 
              description="Proof of reserves is a process where Laani regularly publishes audit results showing that it holds enough assets to cover all customer balances." 
            />
            <FeatureCard 
              title="Comprehensive Security Policies" 
              description="Laani’s security policies include data encryption, regular security audits, and adherence to industry best practices to safeguard institutional assets." 
            />
          </div>
        </div>

        <div className="my-16 text-center">
          <Heading text="Contact Us" className="text-3xl font-bold mb-4" />
          <p className="text-lg mb-4">For enterprise inquiries, please contact us directly at <a href="enterprise@laani.io" className="text-blue-500 underline">enterprise@laani.io</a></p>
          <Button text="Email Us" onClick={() => window.location.href = 'enterprise@laani.io'} />
        </div>
      </div>
    </Section>
  );
};

export default InstitutionsPage;

