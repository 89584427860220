import React from 'react';
import Accordion from './Accordion';
import Heading from '../../ui/PageUI/Heading';
import Subheading from '../../ui/PageUI/SubHeading';

const gettingStartedData = [
  {
    question: 'Creating an Account',
    answer: 'Learn how to sign up for an account on Laani, complete the registration process, and verify your email.',
  },
  {
    question: 'Securing Your Wallet',
    answer: 'Discover the best practices for securing your crypto wallet, including enabling two-factor authentication (2FA) and using a hardware wallet.',
  },
  {
    question: 'Making Your First Trade',
    answer: 'Step-by-step guide on how to buy, sell, and trade cryptocurrencies on the Laani platform.',
  },
  {
    question: 'What is Cryptocurrency?',
    answer: 'Cryptocurrency is a digital or virtual form of currency that uses cryptography for security and operates independently of a central authority.',
  },
  {
    question: 'How Does a Crypto Exchange Work?',
    answer: 'A crypto exchange is a platform where you can buy, sell, and trade cryptocurrencies. It acts as an intermediary between buyers and sellers.',
  },
  {
    question: 'What is Blockchain Technology?',
    answer: 'Blockchain is a decentralized digital ledger that records transactions across many computers in a way that ensures security and transparency.',
  },
  {
    question: 'What is Bitcoin?',
    answer: 'Bitcoin is the first and most well-known cryptocurrency, created by an unknown person or group of people using the name Satoshi Nakamoto in 2008.',
  },
  {
    question: 'How to Deposit Funds?',
    answer: 'Learn how to deposit funds into your Laani account using various payment methods such as bank transfers, credit cards, and cryptocurrencies.',
  },
  {
    question: 'Understanding Market Orders and Limit Orders',
    answer: 'A market order executes immediately at the current market price, while a limit order only executes at a specific price set by the user.',
  },
  {
    question: 'What are Trading Pairs?',
    answer: 'Trading pairs represent the two assets that can be traded against each other on an exchange, such as BTC/USD or ETH/BTC.',
  },
  {
    question: 'How to Withdraw Funds?',
    answer: 'Learn how to withdraw your funds from Laani to your bank account or external crypto wallet.',
  },
  {
    question: 'What are Stablecoins?',
    answer: 'Stablecoins are cryptocurrencies that are pegged to a stable asset, like the US Dollar, to reduce price volatility.',
  },
  {
    question: 'What is Two-Factor Authentication (2FA)?',
    answer: '2FA adds an extra layer of security to your account by requiring a second form of verification in addition to your password.',
  },
  {
    question: 'Understanding Fees',
    answer: 'Learn about the various fees associated with trading, depositing, and withdrawing on the Laani platform.',
  },
  {
    question: 'What is a Public Key and Private Key?',
    answer: 'A public key is used to receive funds, while a private key is used to access and manage your crypto holdings. Keep your private key secure.',
  },
  {
    question: 'How to Use a Hardware Wallet?',
    answer: 'A hardware wallet is a physical device that securely stores your private keys offline, protecting them from online threats.',
  },
  {
    question: 'What is a Decentralized Exchange (DEX)?',
    answer: 'A DEX is a type of cryptocurrency exchange that operates without a central authority, allowing users to trade directly with each other.',
  },
  {
    question: 'What are Altcoins?',
    answer: 'Altcoins are any cryptocurrencies other than Bitcoin. Examples include Ethereum, Litecoin, and Ripple.',
  },
  {
    question: 'How to Read Price Charts?',
    answer: 'Learn how to read and analyze price charts to make informed trading decisions.',
  },
  {
    question: 'What is Staking?',
    answer: 'Staking involves locking up your cryptocurrency to support the operations of a blockchain network and earn rewards.',
  },
  {
    question: 'What are Smart Contracts?',
    answer: 'Smart contracts are self-executing contracts with the terms of the agreement directly written into code.',
  },
  {
    question: 'What is DeFi?',
    answer: 'DeFi, or Decentralized Finance, refers to financial services that operate on blockchain technology without the need for intermediaries.',
  },
  {
    question: 'How to Set Up Price Alerts?',
    answer: 'Learn how to set up price alerts on Laani to stay informed about market movements.',
  },
  {
    question: 'What is Liquidity?',
    answer: 'Liquidity refers to how easily an asset can be bought or sold in the market without affecting its price.',
  },
  {
    question: 'How to Use Stop-Loss Orders?',
    answer: 'A stop-loss order automatically sells your asset when it reaches a certain price, helping to minimize losses.',
  },
  {
    question: 'What is a Wallet Address?',
    answer: 'A wallet address is a unique identifier used to receive cryptocurrencies. It is similar to a bank account number.',
  },
  {
    question: 'What are ICOs and IEOs?',
    answer: 'Initial Coin Offerings (ICOs) and Initial Exchange Offerings (IEOs) are fundraising methods used by new cryptocurrency projects.',
  },
  {
    question: 'How to Participate in Airdrops?',
    answer: 'Airdrops are free distributions of cryptocurrency tokens. Learn how to participate and receive airdropped tokens.',
  },
  {
    question: 'What is a Fork?',
    answer: 'A fork is an event where a blockchain splits into two separate chains, often resulting in the creation of a new cryptocurrency.',
  },
  {
    question: 'How to Report and Pay Taxes on Crypto?',
    answer: 'Learn about the tax implications of trading cryptocurrencies and how to report your earnings to tax authorities.',
  },
  {
    question: 'What is Yield Farming?',
    answer: 'Yield farming involves providing liquidity to DeFi protocols in exchange for interest or additional cryptocurrency tokens.',
  },
  {
    question: 'What is a Token Burn?',
    answer: 'A token burn is the process of permanently removing a certain number of tokens from circulation to reduce supply and increase value.',
  },
  {
    question: 'What are NFTs?',
    answer: 'Non-Fungible Tokens (NFTs) are unique digital assets that represent ownership of a specific item, such as art or collectibles, on the blockchain.',
  },
];

const GettingStarted = () => {
  return (
    <div className="container mx-auto p-8 text-center">
      <Heading text="Getting Started with Laani" />
      <Subheading text="Your guide to starting your crypto journey" />
      <Accordion data={gettingStartedData} />
    </div>
  );
};

export default GettingStarted;
