import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaChevronDown, FaBars, FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import Button from './Button';
import LaaniLogo from '../Assets/LaaniLogo.png';

const Navbar = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRefs = useRef([]);
  const mobileMenuRef = useRef(null);

  const navItems = [
    {
      name: 'Explore',
      links: [
        { name: 'Buy Crypto', path: '/buy-crypto' },
        { name: 'Blog', path: '/blogs' },
        { name: 'Crypto Prices', path: '/coins' },
      ],
    },
    {
      name: 'Dashboard',
      links: [
        { name: 'Watchlist', path: '/Dashboard' },
        { name: 'Coins', path: '/dashboard/coins' },
        { name: 'Crypto Converter', path: '/dashboard/profile' },
        { name: 'My Watchlist', path: '/dashboard/coins/watchlist' },
      ],
    },
    {
      name: 'Institutional',
      links: [
        { name: 'Derivates, Spot and Futures', path: '/institutional' },
        { name: 'Investors', path: '/investors' },
      ],
    },
    {
      name: 'Company',
      links: [
        { name: 'Legal', path: '/legal' },
        { name: 'About us', path: '/AboutUs' },
        { name: 'Help Center', path: '/help-center' },
        { name: 'Connect with us', path: '/contactUs' },
        { name: 'Feedback', path: '/feedback' },
      ],
    },
  ];

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleDropdownToggle = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  const handleSignIn = () => {
    console.log('Sign In button clicked');
    navigate('/sign-in');
  };

  const handleSignOut = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      setUser(null);
      navigate('/sign-in');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const getFirstName = (user) => {
    if (user && user.displayName) {
      return user.displayName.split(' ')[0];
    }
    return 'User';
  };

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRefs.current.length > 0 &&
      dropdownRefs.current.every(
        (ref) => ref && !ref.contains(event.target)
      )
    ) {
      setActiveDropdown(null);
    }
    if (
      mobileMenuRef.current &&
      !mobileMenuRef.current.contains(event.target)
    ) {
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Nav>
      <div className="brand">
        <Link to="/">
          <img src={LaaniLogo} alt="Laani Logo" className="logo" />
        </Link>
      </div>
      <div
        className={`nav-links ${isMobileMenuOpen ? 'mobile-open' : ''}`}
        ref={mobileMenuRef}
      >
        {navItems.map((item, index) => (
          <div key={index} className="nav-item">
            <div
              className="nav-link"
              onClick={() => handleDropdownToggle(index)}
            >
              <span>{item.name}</span> <FaChevronDown />
            </div>
            {activeDropdown === index && (
              <ul
                className="dropdown"
                ref={(el) => (dropdownRefs.current[index] = el)}
              >
                {item.links.map((link, idx) => (
                  <li key={idx} className="dropdown-item">
                    <Link to={link.path}>{link.name}</Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
        <div className="auth">
          {user ? (
            <div className="user-menu" onClick={toggleUserMenu}>
              Hi, <span className="username">{getFirstName(user)}</span>
              {isUserMenuOpen && (
                <ul className="user-dropdown">
                  <li onClick={handleSignOut}>Sign Out</li>
                </ul>
              )}
            </div>
          ) : (
            <Button text="Sign In" onClick={handleSignIn} />
          )}
        </div>
      </div>
      <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
        {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
      </div>
    </Nav>
  );
};

const Nav = styled.nav`
  margin: 3rem 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .brand .logo {
    height: 100px;
  }

  .nav-links {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 2rem;
    font-weight: bolder;

    .nav-item {
      position: relative;
      display: flex;
      flex-direction: column;

      .nav-link {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: opacity 0.3s ease;

        svg {
          margin-left: 0.5rem;
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: #333;
      border: 1px solid #444;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      list-style: none;
      padding: 0;
      margin: 0;
      width: 200px;
      z-index: 1000;
      transition: all 0.3s ease;

      li {
        padding: 1rem;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #444;
        }

        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }

  .auth {
    display: flex;
    align-items: center;
    gap: 1rem;

    .user-menu {
      position: relative;
      cursor: pointer;

      .username {
        color: #1a73e8; /* Change this color to match your theme */
        font-weight: bold;
      }

      .user-dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: #333;
        border: 1px solid #444;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        list-style: none;
        padding: 0;
        margin: 0;
        width: 120px;
        z-index: 1000;
        transition: all 0.3s ease;

        li {
          padding: 1rem;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #444;
          }
        }
      }
    }
  }

  .mobile-menu-icon {
    display: none;
    font-size: 2rem;
    cursor: pointer;
  }

  @media screen and (max-width: 1080px) {
    margin: 1rem 2rem;

    .nav-links {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.9);
      justify-content: center;
      align-items: center;
      gap: 2rem;
      transition: all 0.3s ease;
      z-index: 999;

      .nav-item {
        width: 100%;
        text-align: center;

        .dropdown {
          position: static;
          width: 100%;
          box-shadow: none;

          li {
            padding: 1rem 0;

            &:hover {
              background-color: #444;
            }
          }
        }
      }
    }

    .nav-links.mobile-open {
      display: flex;
    }

    .mobile-menu-icon {
      display: block;
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      z-index: 1001;
    }
  }
`;

export default Navbar;