import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import SidebarLinkGroup from './SidebarLinkGroup';
import {
  FaTachometerAlt,
  FaBitcoin,
  FaExchangeAlt,
  FaEnvelope,
  FaShoppingCart,
  FaCog,
  FaAngleDown,
  FaTimes,
} from 'react-icons/fa';

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef<HTMLButtonElement>(null);
  const sidebar = useRef<HTMLDivElement>(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      const target = event.target as Node;
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target as Node)) return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, [sidebarOpen, setSidebarOpen]);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [sidebarOpen, setSidebarOpen]);

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector('body')?.classList.add('sidebar-expanded');
    } else {
      document.querySelector('body')?.classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  return (
    <>
      {/* Background overlay */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-50"
          onClick={() => setSidebarOpen(false)}
        />
      )}

      <aside
        ref={sidebar}
        className={`fixed top-0 left-0 z-50 h-screen w-full lg:w-72 overflow-y-hidden bg-gray-900 text-white transition-transform duration-300 ease-linear ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:static lg:translate-x-0`}
      >
        {/* Close button */}
        <button
          ref={trigger}
          className="lg:hidden absolute top-4 right-4 text-white"
          onClick={() => setSidebarOpen(false)}
        >
          <FaTimes />
        </button>

        <div className="no-scrollbar flex overflow-y-auto duration-300 ease-linear">
          {/* Sidebar Menu */}
          <nav className="mt-5 py-4 px-4 lg:mt-9 lg:px-6">
            {/* Menu Group */}
            <div>
              <h3 className="mb-4 ml-4 text-sm font-semibold">MENU</h3>

              <ul className="mb-6 flex flex-col gap-1.5">
                {/* Menu Item Dashboard */}
                <SidebarLinkGroup
                  activeCondition={pathname === '/' || pathname.includes('dashboard')}
                >
                  {(handleClick, open) => (
                    <>
                      <NavLink
                        to="/dashboard"
                        className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-gray-700 ${
                          (pathname === '/' || pathname.includes('dashboard')) && 'bg-gray-700'
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          setSidebarOpen(true);
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                        }}
                      >
                        <FaTachometerAlt />
                        Dashboard
                        <FaAngleDown
                          className={`ml-auto transition-transform duration-200 ${
                            open ? 'rotate-180' : ''
                          }`}
                        />
                      </NavLink>

                      {/* Dropdown Menu Start */}
                      <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                        <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
                          <li>
                            <NavLink
                              to="/dashboard/"
                              className={({ isActive }) =>
                                `group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-white duration-300 ease-in-out hover:text-white ${
                                  isActive && '!text-white'
                                }`
                              }
                            >
                              Overview
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      {/* Dropdown Menu End */}
                    </>
                  )}
                </SidebarLinkGroup>
                {/* Menu Item Dashboard */}

                {/* Menu Item Crypto */}
                <SidebarLinkGroup
                  activeCondition={pathname === '/dashboard/coins' || pathname.includes('coins')}
                >
                  {(handleClick, open) => (
                    <>
                      <NavLink
                        to="/dashboard/coins"
                        className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-gray-700 ${
                          (pathname === '/dashboard/coins' || pathname.includes('coins')) && 'bg-gray-700'
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          setSidebarOpen(true);
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                        }}
                      >
                        <FaBitcoin />
                        Crypto
                        <FaAngleDown
                          className={`ml-auto transition-transform duration-200 ${
                            open ? 'rotate-180' : ''
                          }`}
                        />
                      </NavLink>

                      {/* Dropdown Menu Start */}
                      <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                        <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
                          <li>
                            <NavLink
                              to="/dashboard/coins"
                              className={({ isActive }) =>
                                `group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-white duration-300 ease-in-out hover:text-white ${
                                  isActive && '!text-white'
                                }`
                              }
                            >
                              Cryptocurrencies
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/dashboard/coins/watchlist"
                              className={({ isActive }) =>
                                `group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-white duration-300 ease-in-out hover:text-white ${
                                  isActive && '!text-white'
                                }`
                              }
                            >
                              My Watchlist
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      {/* Dropdown Menu End */}
                    </>
                  )}
                </SidebarLinkGroup>
                {/* Menu Item Crypto */}

                {/* Menu Item Crypto Converter */}
                <li>
                  <NavLink
                    to="/dashboard/profile"
                    className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-gray-700 ${
                      pathname.includes('converter') && 'bg-gray-700'
                    }`}
                    onClick={() => setSidebarOpen(true)}
                  >
                    <FaExchangeAlt />
                    Crypto Converter
                  </NavLink>
                </li>
                {/* Menu Item Crypto Converter */}

                {/* Menu Item Contact Us */}
                <SidebarLinkGroup
                  activeCondition={pathname === '/dashboard/forms' || pathname.includes('forms')}
                >
                  {(handleClick, open) => (
                    <>
                      <NavLink
                        to="/dashboard/forms"
                        className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-gray-700 ${
                          (pathname === '/forms' || pathname.includes('forms')) && 'bg-gray-700'
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          setSidebarOpen(true);
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                        }}
                      >
                        <FaEnvelope />
                        Contact Us
                        <FaAngleDown
                          className={`ml-auto transition-transform duration-200 ${
                            open ? 'rotate-180' : ''
                          }`}
                        />
                      </NavLink>

                      {/* Dropdown Menu Start */}
                      <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                        <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
                          <li>
                            <NavLink
                              to="/Dashboard/forms/form-elements"
                              className={({ isActive }) =>
                                `group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-white duration-300 ease-in-out hover:text-white ${
                                  isActive && '!text-white'
                                }`
                              }
                            >
                              Feedback
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/dashboard/forms/form-layout"
                              className={({ isActive }) =>
                                `group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-white duration-300 ease-in-out hover:text-white ${
                                  isActive && '!text-white'
                                }`
                              }
                            >
                              Contact Us
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      {/* Dropdown Menu End */}
                    </>
                  )}
                </SidebarLinkGroup>
                {/* Menu Item Contact Us */}

                {/* Menu Item Buy Crypto */}
                <li>
                  <NavLink
                    to="/dashboard/tables"
                    className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-gray-700 ${
                      pathname.includes('tables') && 'bg-gray-700'
                    }`}
                    onClick={() => setSidebarOpen(true)}
                  >
                    <FaShoppingCart />
                    Buy Crypto
                  </NavLink>
                </li>
                {/* Menu Item Buy Crypto */}

                {/* Menu Item Settings */}
                <li>
                  <NavLink
                    to="/dashboard/settings"
                    className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-gray-700 ${
                      pathname.includes('settings') && 'bg-gray-700'
                    }`}
                    onClick={() => setSidebarOpen(true)}
                  >
                    <FaCog />
                    Settings
                  </NavLink>
                </li>
                {/* Menu Item Settings */}
              </ul>
            </div>
            {/* Menu Group */}
          </nav>
          {/* Sidebar Menu */}
        </div>
      </aside>
    </>
  );
};

export default Sidebar;