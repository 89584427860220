// src/ui/PageUI/Heading.jsx
import React from 'react';

const Heading = ({ text }) => {
  return (
    <h1 className="text-6xl font-bold mb-4 text-center">
      {text}
    </h1>
  );
};

export default Heading;

