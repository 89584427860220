import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

type CoinDetailsParams = {
  id: string;
};

const CoinDetails: React.FC = () => {
  const { id } = useParams<CoinDetailsParams>();
  const [coin, setCoin] = useState<any>(null);

  const fetchCoinData = async () => {
    try {
      const response = await axios.get(`https://api.coingecko.com/api/v3/coins/${id}`);
      setCoin(response.data);
    } catch (error) {
      console.error('Error fetching coin data:', error);
    }
  };

  useEffect(() => {
    fetchCoinData();
  }, [id]);

  if (!coin) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>{coin.name}</h1>
      <img src={coin.image.large} alt={coin.name} />
      <p>Current Price: ${coin.market_data.current_price.usd}</p>
      <p>Market Cap: ${coin.market_data.market_cap.usd}</p>
      <p>24h Change: {coin.market_data.price_change_percentage_24h}%</p>
      {/* Add more details as needed */}
    </div>
  );
};

export default CoinDetails;
