import React from 'react';
import Accordion from './Accordion';
import Heading from '../../ui/PageUI/Heading';
import Subheading from '../../ui/PageUI/SubHeading';

const tutorialsData = [
  {
    question: 'Understanding Blockchain Technology',
    answer: 'Learn the fundamentals of blockchain technology, how it works, and why it is revolutionary.',
  },
  {
    question: 'Advanced Trading Strategies',
    answer: 'Explore advanced trading strategies, including technical analysis, swing trading, and more.',
  },
  {
    question: 'Using Laani\'s Trading Tools',
    answer: 'A comprehensive guide on using the various trading tools available on the Laani platform to enhance your trading experience.',
  },
  {
    question: 'Setting Up Your Laani Account',
    answer: 'Step-by-step guide on creating your Laani account, verifying your identity, and securing your account.',
  },
  {
    question: 'How to Buy Bitcoin on Laani',
    answer: 'Learn how to purchase Bitcoin using your Laani account, including payment methods and transaction fees.',
  },
  {
    question: 'How to Sell Cryptocurrency',
    answer: 'Detailed instructions on how to sell your cryptocurrencies on Laani and withdraw the funds to your bank account.',
  },
  {
    question: 'Using Laani Mobile App',
    answer: 'A guide to navigating and using the Laani mobile app for trading and managing your crypto portfolio on the go.',
  },
  {
    question: 'Introduction to Technical Analysis',
    answer: 'Learn the basics of technical analysis, including chart patterns, indicators, and trading signals.',
  },
  {
    question: 'How to Use Stop-Loss and Take-Profit Orders',
    answer: 'Understand how to set up and use stop-loss and take-profit orders to manage your risk and maximize your gains.',
  },
  {
    question: 'Understanding Market and Limit Orders',
    answer: 'A tutorial on the differences between market and limit orders and how to use them effectively on Laani.',
  },
  {
    question: 'How to Transfer Crypto to Your Wallet',
    answer: 'Step-by-step guide on transferring your cryptocurrencies from Laani to your personal crypto wallet.',
  },
  {
    question: 'Introduction to DeFi (Decentralized Finance)',
    answer: 'Learn what DeFi is, how it works, and how you can participate in DeFi projects and earn interest on your crypto holdings.',
  },
  {
    question: 'Staking Cryptocurrencies on Laani',
    answer: 'How to stake your cryptocurrencies on Laani, earn rewards, and understand the risks involved.',
  },
  {
    question: 'Understanding Crypto Taxes',
    answer: 'A guide to understanding the tax implications of trading cryptocurrencies and how to report your crypto earnings.',
  },
  {
    question: 'Using Margin Trading on Laani',
    answer: 'Learn how to use margin trading on Laani, including the risks and benefits of trading with leverage.',
  },
  {
    question: 'Participating in Laani Airdrops',
    answer: 'How to participate in Laani airdrops and receive free cryptocurrency tokens.',
  },
  {
    question: 'Security Best Practices for Crypto Trading',
    answer: 'Tips and best practices for securing your Laani account and protecting your crypto assets from cyber threats.',
  },
  {
    question: 'How to Use Trading Bots on Laani',
    answer: 'A comprehensive guide on using trading bots on Laani to automate your trading strategies.',
  },
  {
    question: 'Understanding and Using Stablecoins',
    answer: 'Learn about stablecoins, how they work, and how to use them for trading and hedging on Laani.',
  },
  {
    question: 'Exploring Laani\'s Educational Resources',
    answer: 'A tour of the educational resources available on Laani, including articles, videos, and webinars.',
  },
  {
    question: 'How to Analyze Crypto Market Trends',
    answer: 'Techniques and tools for analyzing market trends and making informed trading decisions on Laani.',
  },
  {
    question: 'Using Laani\'s API for Automated Trading',
    answer: 'How to use the Laani API to build and implement automated trading strategies.',
  },
  {
    question: 'Understanding Crypto Wallets',
    answer: 'A guide to the different types of crypto wallets and how to choose the right one for your needs.',
  },
  {
    question: 'How to Participate in ICOs and IEOs',
    answer: 'Learn how to participate in Initial Coin Offerings (ICOs) and Initial Exchange Offerings (IEOs) on Laani.',
  },
  {
    question: 'Using Laani\'s Portfolio Management Tools',
    answer: 'A guide to using the portfolio management tools available on Laani to track and optimize your crypto investments.',
  },
  {
    question: 'Understanding Liquidity Pools',
    answer: 'Learn what liquidity pools are, how they work, and how to participate in them on Laani.',
  },
  {
    question: 'Exploring NFTs (Non-Fungible Tokens)',
    answer: 'An introduction to NFTs, how they work, and how to buy, sell, and trade them on Laani.',
  },
];

const Tutorials = () => {
  return (
    <div className="container mx-auto p-8 text-center">
      <Heading text="Tutorials" />
      <Subheading text="In-depth guides to help you master cryptocurrency trading" />
      <Accordion data={tutorialsData} />
    </div>
  );
};

export default Tutorials;
