// src/ui/PageUI/Intro.jsx
import React from 'react';

const Intro = ({ text }) => {
  return (
    <div className="mb-8">
      <p className="text-base mb-4 text-center">{text}</p>
    </div>
  );
};

export default Intro;
