// src/ui/PageUI/Description.jsx
import React from 'react';

const Description = ({ text }) => {
  return (
    <p className="text-base mb-4 text-center">
      {text}
    </p>
  );
};

export default Description;

