import React, { useState } from 'react';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import DefaultLayout from '../../layout/DefaultLayout';
import { sendSupportEmail } from '../../../../../src/emailService'; // Assuming you have a service to handle email sending

const ContactSupport = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await sendSupportEmail(formData);
      alert('Support request sent successfully!');
      setFormData({ name: '', email: '', subject: '', message: '' }); // Clear form after successful submission
    } catch (error) {
      console.error('Error sending support request:', error);
      alert('Failed to send support request.');
    }
  };

  return (
    <DefaultLayout>
      <Breadcrumb pageName="Contact Support" />

      <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-center text-gray-800 mb-6">Contact Support</h2>
        <p className="text-sm text-center text-gray-600 mb-4">
          Have a question or need assistance? Please fill out the form below, and we'll get back to you shortly.
        </p>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:ring focus:ring-primary focus:outline-none"
              style={{ backgroundColor: '#FFFFFF', color: '#000000' }} // Adjusted background and text color
              required
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:ring focus:ring-primary focus:outline-none"
              style={{ backgroundColor: '#FFFFFF', color: '#000000' }} // Adjusted background and text color
              required
            />
          </div>
          <div>
            <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
              Subject
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:ring focus:ring-primary focus:outline-none"
              style={{ backgroundColor: '#FFFFFF', color: '#000000' }} // Adjusted background and text color
              required
            />
          </div>
          <div>
            <label htmlFor="message" className="block text-sm font-medium text-gray-700">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows={5}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:ring focus:ring-primary focus:outline-none"
              style={{ backgroundColor: '#FFFFFF', color: '#000000' }} // Adjusted background and text color
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Send Message
          </button>
        </form>
      </div>
    </DefaultLayout>
  );
};

export default ContactSupport;

