/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

const InvestorRelationsPage = () => {
  return (
    <div className="container mx-auto p-8">
      <HeroSection />
      <InvestmentOpportunity />
      <InvestorInformation />
      <ContactSection />
    </div>
  );
};

const HeroSection = () => (
  <div className="text-center mb-16">
    <h1 className="text-4xl font-bold mb-4">Investor Relations</h1>
    <p className="text-xl">Invest in the future of finance with Laani</p>
  </div>
);

const InvestmentOpportunity = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleContactClick = () => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 5000);
  };

  return (
    <div className="p-10 rounded-lg mb-16 text-center">
      <h2 className="text-2xl font-bold mb-4">Join Us in Transforming Finance</h2>
      <p className="text-lg mb-8">
        At Laani, we are revolutionizing the world of finance through innovative blockchain technology and decentralized solutions. Join us in shaping the future of finance by investing in Laani today.
      </p>
    </div>
  );
};

const InvestorInformation = () => (
  <div className="mb-16">
    <h2 className="text-2xl font-bold mb-4">Financial Information</h2>
    <table className="min-w-full">
      <thead>
        <tr>
          <th className="py-2 px-4 border-b-2 border-gray-300">Date</th>
          <th className="py-2 px-4 border-b-2 border-gray-300">Event</th>
          <th className="py-2 px-4 border-b-2 border-gray-300">Details</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="py-2 px-4 border-b">2024-04-10</td>
          <td className="py-2 px-4 border-b">Q1 2024 Earnings Release</td>
          <td className="py-2 px-4 border-b">
            <a href="#" className="text-blue-500 hover:underline">View Details</a>
          </td>
        </tr>
        {/* Add more financial events as needed */}
      </tbody>
    </table>
  </div>
);

const ContactSection = () => (
  <div className="mb-16 text-center">
    <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
    <ContactForm />
  </div>
);

const ContactForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
  };

  return (
    <form className="max-w-md mx-auto" onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="block text-left mb-2">Name</label>
        <input type="text" className="w-full px-3 py-2 border rounded-lg" required />
      </div>
      <div className="mb-4">
        <label className="block text-left mb-2">Email</label>
        <input type="email" className="w-full px-3 py-2 border rounded-lg" required />
      </div>
      <div className="mb-4">
        <label className="block text-left mb-2">Message</label>
        <textarea className="w-full px-3 py-2 border rounded-lg" rows="4" required></textarea>
      </div>
      <button
        type="submit"
        className="bg-blue-600 text-white py-3 px-6 rounded-lg transition-colors duration-300 hover:bg-blue-700"
      >
        Send Message
      </button>
      {formSubmitted && (
        <p className="mt-4 text-green-600">
          Thank you for your message! We will get back to you within the next 24-48 hours.
        </p>
      )}
    </form>
  );
};

export default InvestorRelationsPage;




