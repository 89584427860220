import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

type TimeRange = '24h' | '7d' | '1m' | '3m' | '1y' | 'max';

interface DataPoint {
  date: string;
  price: number;
}

const CryptoChart: React.FC = () => {
  const [coin, setCoin] = useState('bitcoin');
  const [timeRange, setTimeRange] = useState<TimeRange>('24h');
  const [data, setData] = useState<DataPoint[]>([]);
  const [color, setColor] = useState('#00C49F');
  const [priceRange, setPriceRange] = useState({ min: 0, max: 0 });

  const coins = [
    'bitcoin',
    'ethereum',
    'ripple',
    'litecoin',
    'bitcoin-cash',
    'eos',
    'binancecoin',
    'cardano',
    'stellar',
    'tether',
    'solana',
    'polkadot',
    'dogecoin',
    'chainlink',
    'usd-coin',
    'vechain',
    'matic-network',
    'ethereum-classic',
    'theta-token',
    'filecoin',
    'wrapped-bitcoin',
    'tron',
    'binance-usd',
    'terra-luna',
    'aave',
    'eos',
    'monero',
    'pancakeswap',
    'cosmos',
    'leo-token',
    'avalanche',
  ];

  const timeRanges: Record<TimeRange, string> = {
    '24h': '1',
    '7d': '7',
    '1m': '30',
    '3m': '90',
    '1y': '365',
    'max': 'max',
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.coingecko.com/api/v3/coins/${coin}/market_chart`,
        {
          params: {
            vs_currency: 'usd',
            days: timeRanges[timeRange],
          },
        }
      );
      const prices = response.data.prices;
      const formattedData: DataPoint[] = prices.map((item: number[]) => ({
        date: new Date(item[0]).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
        }),
        price: parseFloat(item[1].toFixed(2)),
      }));
      setData(formattedData);
      
      const priceChange = formattedData[formattedData.length - 1].price - formattedData[0].price;
      setColor(priceChange >= 0 ? '#00C49F' : '#FF0000');
      
      // Calculate the min and max prices for dynamic Y-axis
      const minPrice = Math.min(...formattedData.map((item: DataPoint) => item.price));
      const maxPrice = Math.max(...formattedData.map((item: DataPoint) => item.price));
      setPriceRange({ min: minPrice, max: maxPrice });
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [coin, timeRange]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-white p-2 border border-gray-300 rounded-md text-black">
          <p className="label">{`${label}`}</p>
          <p className="intro">{`Price: $${payload[0].value.toFixed(2)}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="p-5 bg-white shadow rounded-md">
      <div className="flex justify-between items-center mb-5">
        <div>
          <label className="block text-sm font-medium">Select Coin</label>
          <select
            value={coin}
            onChange={(e) => setCoin(e.target.value)}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
          >
            {coins.map((coin) => (
              <option key={coin} value={coin}>
                {coin.charAt(0).toUpperCase() + coin.slice(1)}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium">Time Range</label>
          <div className="flex space-x-2">
            {Object.keys(timeRanges).map((range) => (
              <button
                key={range}
                onClick={() => setTimeRange(range as TimeRange)}
                className={`py-2 px-4 rounded-md ${
                  timeRange === range
                    ? 'bg-primary text-black'
                    : 'bg-gray-200 text-gray-700'
                }`}
              >
                {range}
              </button>
            ))}
          </div>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data}>
          {/* Remove CartesianGrid */}
          <XAxis dataKey="date" />
          <YAxis
            domain={[priceRange.min, priceRange.max]}
            tickMargin={10}
            width={100}
            orientation="right" // Y-axis on the right side
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Line type="monotone" dataKey="price" stroke={color} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CryptoChart;







