import Section from "./Section";
import stars from '../Assets/pricing/stars.svg'
import Heading from "./Heading";
import { CheckIcon } from '@heroicons/react/20/solid'

const includedFeatures = [
  'Sign up: Create your account in minutes.',
  'Select Amount: Decide on Purchase amount',
  'Verify Email: To ensure the security of your account',
  'Buy Crypto Its that simple',
]

const Pricing = () => {
  return (
    <Section className="overflow-hidden" id="pricing">
      <div className="container relative z-2">
        <div className="hidden relative justify-center mb-[6.5rem] lg:flex">
          <div className="absolute top-1/2 left-1/2 w-[60rem] -translate-x-1/2 -translate-y-1/2 pointer-events-none">
            <img
              src={stars}
              className="w-full"
              width={950}
              height={400}
              alt="Stars"
            />
          </div>
        </div>

        <Heading
          tag="Get started with Laani"
          title="Easily Buy and Sell Crypto"
        />

      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-white-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <p className="mt-6 text-base leading-7 text-white-600">
            Seasoned investor or starting out, we make it easy to navigate the world of digital assets. Say goodbye to complicated processes. 
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">Get started in 5 mins</h4>
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-white-600 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-white-600">Start from as little as</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-white-900">$1</span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-white-600">USD</span>
                </p>
                <a
                  href="/Sign-In"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Buy Now
                </a>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                Ready to dive into the world of cryptocurrency? Join us and experience the convenience of buying and selling crypto with ease.
                </p>
              </div>
              <div className="justify-center mt-10">
          <a
            className="text-xs font-code font-bold tracking-wider uppercase border-b"
            href="/Sign-Up"
          >
            Get Started
          </a>
        </div>
            </div>
          </div>
        </div>
      </div>
    </div>       
    </Section>
  );
};

export default Pricing;