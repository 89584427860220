import React from 'react';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`;

const Section = styled.section`
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
`;

const List = styled.ul`
  padding-left: 20px;
  list-style-type: disc;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  margin-bottom: 10px;
`;

const DigitalAssetsDisclosure = () => {
  return (
    <Container>
      <Title>Digital Asset Disclosures</Title>
      
      <Section>
        <SectionTitle>1. Risk Disclosure:</SectionTitle>
        <Paragraph>
          Investing in digital assets carries inherent risks, including but not limited to:
        </Paragraph>
        <List>
          <ListItem>Volatility: Digital asset prices can fluctuate widely within short periods, leading to potential loss of investment.</ListItem>
          <ListItem>Regulatory Risks: Regulatory changes and actions by government authorities may impact the legality and value of digital assets.</ListItem>
          <ListItem>Cybersecurity Risks: Digital assets are susceptible to hacking, cyberattacks, and other security breaches.</ListItem>
          <ListItem>Market Risks: Market manipulation, liquidity issues, and other market-related factors can affect digital asset prices.</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>2. Regulatory Compliance:</SectionTitle>
        <Paragraph>
          Laani complies with relevant laws and regulations in the jurisdictions where we operate. We adhere to strict regulatory standards to protect our users and ensure the integrity of our platform.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>3. Customer Education:</SectionTitle>
        <Paragraph>
          We encourage our users to educate themselves about digital assets and the associated risks before investing. Our platform provides educational resources, including articles, tutorials, and market analyses, to help users make informed decisions.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>4. Legal Disclaimers:</SectionTitle>
        <List>
          <ListItem>Laani does not provide investment advice or guarantee profits on digital asset investments.</ListItem>
          <ListItem>Users are solely responsible for their investment decisions and should conduct thorough research before investing.</ListItem>
          <ListItem>Laani is not liable for any losses incurred as a result of trading digital assets on our platform.</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>5. Privacy and Security:</SectionTitle>
        <Paragraph>
          Protecting the privacy and security of our users' information is our top priority. We employ advanced security measures, such as encryption and multi-factor authentication, to safeguard user data and assets.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>6. Reporting and Compliance:</SectionTitle>
        <Paragraph>
          Laani is committed to maintaining transparency and compliance with regulatory requirements. We regularly monitor our platform for suspicious activities and report any suspicious transactions to relevant authorities.
        </Paragraph>
      </Section>
    </Container>
  );
}

export default DigitalAssetsDisclosure;

