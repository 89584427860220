// AuthContext.js

import { createContext, useContext, useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateProfile as updateFirebaseProfile,
} from 'firebase/auth';
import { auth, addToWatchlist, removeFromWatchlist } from '../Firebase';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  const createUser = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      setCurrentUser(user);
      return user;
    } catch (error) {
      throw error;
    }
  };

  const signIn = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      setCurrentUser(user);
      return user;
    } catch (error) {
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
    } catch (error) {
      throw error;
    }
  };

  const updateProfile = async (profileData) => {
    try {
      await updateFirebaseProfile(auth.currentUser, profileData);
      setCurrentUser(auth.currentUser);
    } catch (error) {
      throw error;
    }
  };

  const addCoinToWatchlist = async (coinId) => {
    if (!currentUser) return;
    try {
      await addToWatchlist(currentUser.uid, coinId);
    } catch (error) {
      console.error('Error adding to watchlist:', error);
    }
  };

  const removeCoinFromWatchlist = async (coinId) => {
    if (!currentUser) return;
    try {
      await removeFromWatchlist(currentUser.uid, coinId);
    } catch (error) {
      console.error('Error removing from watchlist:', error);
    }
  };


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, createUser, signIn, logout, updateProfile, addCoinToWatchlist, removeCoinFromWatchlist }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthContextProvider');
  }
  return context;
};

export { AuthContext }; // Ensure AuthContext is exported



