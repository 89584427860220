import { BackgroundCircles, BottomLine } from "./Design/Hero";
import Section from "./Section";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const parallaxRef = useRef(null);
  const [email] = useState("");
  const [isSignedIn, setIsSignedIn] = useState(false);
  const navigate = useNavigate();


  const handleSignUp = () => {
    navigate("/sign-up", { state: { email } });
  };

  const handleSignIn = () => {
    navigate("/sign-in", { state: { email } });
    setIsSignedIn(true);
  };

  return (
    <Section
      className="pt-[12rem] -mt-[5.25rem]"
      crosses
      crossesOffset="lg:translate-y-[5.25rem]"
      customPaddings
      id="hero"
    >
      <div className="text-center"></div>
      <div className="container relative" ref={parallaxRef}>
        <div className="relative z-1 max-w-[62rem] mx-auto text-center mb-[3.875rem] md:mb-20 lg:mb-[6.25rem]">
        <div className="inline-block relative rounded-full px-3 py-1 text-sm leading-6 text-white-600 border border-blue">
          <a href="/blogs" className="inline-block max-w-[fit-content] hover:underline">
            Announcing our Seed round of funding.📣
            </a>
            <br></br>
        </div>

          <h1 className="h1 mb-6">
            Turn your money into Crypto Assets&nbsp; with{' '}
            <span className="inline-block relative">
              Laani{' '}
            </span>
          </h1>
          <p className="body-1 max-w-3xl mx-auto mb-6 text-n-2 lg:mb-8">
            The secure way to buy, sell and store crypto. 
            Join Laani Today.
          </p>
          <div className="flex justify-center">
            <div className="mt-10 flex flex-col sm:flex-row max-w-md gap-x-4 gap-y-2">
              {!isSignedIn ? (
                <>
                  <button
                    type="button"
                    className="flex-none rounded-md bg-indigo-500 px-6 py-3 text-base font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 sm:px-4 sm:py-2.5 sm:text-sm"
                    onClick={handleSignUp}
                  >
                    Sign Up
                  </button>
                  <button
                    type="button"
                    className="flex-none rounded-md bg-gray-500 px-6 py-3 text-base font-semibold text-white shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 sm:px-4 sm:py-2.5 sm:text-sm"
                    onClick={handleSignIn}
                  >
                    {isSignedIn ? "Welcome!" : "Sign In"}
                  </button>
                </>
              ) : (
                <p className="body-1 max-w-3xl mx-auto mb-6 text-n-2 lg:mb-8">
                  Welcome back, user!
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="relative max-w-[23rem] mx-auto md:max-w-5xl xl:mb-24">
          <BackgroundCircles />
        </div>
      </div>
      <BottomLine />
    </Section>
  );
};

export default Hero;














