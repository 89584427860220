import React, { useState } from 'react';
import CoinItem from '../CryptoCurrencies/CoinItem';
import { Link } from 'react-router-dom';
import './Coins.css';

const Coins = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const coinsPerPage = 10;

  const filteredCoins = props.coins.filter(coin =>
    coin.name.toLowerCase().includes(search.toLowerCase())
  );

  const indexOfLastCoin = currentPage * coinsPerPage;
  const indexOfFirstCoin = indexOfLastCoin - coinsPerPage;
  const currentCoins = filteredCoins.slice(indexOfFirstCoin, indexOfLastCoin);
  const totalPages = Math.ceil(filteredCoins.length / coinsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className='container'>
      <input
        type='text'
        placeholder='Search for a coin...'
        onChange={(e) => setSearch(e.target.value)}
        className='search-bar'
      />
      <div>
        <div className='heading'>
          <p>#</p>
          <p className='coin-name'>Coin</p>
          <p>Price</p>
          <p>24h</p>
          <p className='hide-mobile'>Volume</p>
          <p className='hide-mobile'>Mkt Cap</p>
        </div>

        {currentCoins.map((coin, index) => (
          <Link to={`/coins/${coin.id}`} key={coin.id}>
            <CoinItem coin={coin} index={index + indexOfFirstCoin + 1} />
          </Link>
        ))}
      </div>
      <div className='pagination'>
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          Prev
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Coins;



