import React from 'react';
import PropTypes from 'prop-types';

const ImageWithText = ({ heading, paragraph, bullets, imageUrl }) => {
  return (
    <div className="container mx-auto p-8">
      <div className="flex flex-col md:flex-row items-center justify-center md:justify-between">
        <div className="md:w-1/2 md:mr-4 mb-4 md:mb-0">
          <h2 className="text-2xl font-bold mb-4">{heading}</h2>
          <p className="text-base mb-4">{paragraph}</p>
          {bullets && bullets.length > 0 && (
            <ul className="list-disc list-inside">
              {bullets.map((bullet, index) => (
                <li key={index}>
                  <a href={bullet.link} className="text-blue-500 hover:underline">{bullet.text}</a>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="md:w-1/3">
          <img src={imageUrl} alt="Image" className="w-1/3 md:w-full h-auto" />
        </div>
      </div>
    </div>
  );
};

ImageWithText.propTypes = {
  heading: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  bullets: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ),
  imageUrl: PropTypes.string.isRequired,
};

ImageWithText.defaultProps = {
  bullets: [],
};

export default ImageWithText;






