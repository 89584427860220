import React, { ReactNode } from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

interface CardDataStatsProps {
  title: string;
  total: string;
  rate: string;
  levelUp?: boolean;
  levelDown?: boolean;
  children: ReactNode;
}

const CardDataStats: React.FC<CardDataStatsProps> = ({
  title,
  total,
  rate,
  levelUp,
  levelDown,
  children,
}) => {
  return (
    <div className="rounded-sm border border-stroke bg-gray py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
        {children}
      </div>

      <div className="mt-4 flex flex-col md:flex-row items-start md:items-center justify-between">
        <div className="md:flex md:flex-col md:items-start md:justify-start md:mt-0">
          <h4 className="text-title-md font-bold text-white dark:text-gray">
            {total}
          </h4>
          <span className="text-sm font-medium">{title}</span>
        </div>

        <span
          className={`flex items-center mt-2 md:mt-0 gap-1 text-sm font-medium ${
            levelUp ? 'text-meta-3' : levelDown ? 'text-meta-5' : ''
          }`}
        >
          {rate}
          {levelUp && <FaArrowUp className="text-meta-3" />}
          {levelDown && <FaArrowDown className="text-meta-5" />}
        </span>
      </div>
    </div>
  );
};

export default CardDataStats;



