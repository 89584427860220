import React from 'react';
import { useParams } from 'react-router-dom';
import { fullBlog } from '../app/blogs/lib/interface';
import { client, urlFor } from '../app/blogs/lib/sanity';
import { PortableText, PortableTextReactComponents } from '@portabletext/react';
import { Img } from 'react-image';
import { FaFacebook, FaTwitter, FaLinkedin, FaTelegram, FaWhatsapp, FaCopy } from 'react-icons/fa';

export const revalidate = 30;

interface BlogPreview {
  title: string;
  slug: { current: string };
  titleImage: any;
}

async function getData(slug: string): Promise<fullBlog> {
  const query = `
    *[_type == "blog" && slug.current == '${slug}'] {
      "currentSlug": slug.current,
      title,
      content,
      titleImage
    }[0]
  `;
  const data = await client.fetch(query);
  return data;
}

async function getFurtherReading(currentSlug: string): Promise<BlogPreview[]> {
  const query = `
    *[_type == "blog" && slug.current != '${currentSlug}'] | order(_createdAt desc)[0..2] {
      title,
      "slug": slug.current,
      titleImage
    }
  `;
  const data = await client.fetch(query);
  return data;
}

export default function BlogArticle() {
  const { slug } = useParams<{ slug?: string }>();
  const [data, setData] = React.useState<fullBlog | null>(null);
  const [furtherReading, setFurtherReading] = React.useState<BlogPreview[]>([]);

  React.useEffect(() => {
    async function fetchData() {
      if (slug) {
        const blogData: fullBlog = await getData(slug);
        setData(blogData);
        const furtherReadingData = await getFurtherReading(blogData.currentSlug);
        setFurtherReading(furtherReadingData);
      }
    }
    fetchData();
  }, [slug]);

  if (!data) {
    return <div className="text-center text-white">Loading...</div>;
  }

  const shareLink = window.location.href;
  const shareText = `Check out this blog: ${data.title}`;

  const shareButtons = [
    { icon: <FaFacebook />, url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareLink)}` },
    { icon: <FaTwitter />, url: `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareLink)}` },
    { icon: <FaLinkedin />, url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareLink)}&title=${encodeURIComponent(data.title)}` },
    { icon: <FaTelegram />, url: `https://telegram.me/share/url?url=${encodeURIComponent(shareLink)}&text=${encodeURIComponent(shareText)}` },
    { icon: <FaWhatsapp />, url: `https://api.whatsapp.com/send?text=${encodeURIComponent(shareText)}%20${encodeURIComponent(shareLink)}` },
  ];

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareLink).then(() => {
      alert('Link copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  const portableTextComponents: PortableTextReactComponents = {
    marks: {
      strong: ({ children }) => <strong className="font-bold text-white">{children}</strong>,
      underline: ({ children }) => <span style={{ textDecoration: 'underline' }} className="text-white">{children}</span>,
      link: ({ value, children }) => {
        const href = value?.href || '';
        const target = href.startsWith('http') ? '_blank' : undefined;
        return (
          <a href={href} target={target} rel={target === '_blank' ? 'noopener noreferrer' : undefined} className="text-blue-400 underline">
            {children}
          </a>
        );
      },
    },
    block: {
      h1: ({ children }) => <h1 className="text-4xl font-bold text-white">{children}</h1>,
      h2: ({ children }) => <h2 className="text-3xl font-bold text-white">{children}</h2>,
      h3: ({ children }) => <h3 className="text-2xl font-bold text-white">{children}</h3>,
      h4: ({ children }) => <h4 className="text-xl font-bold text-white">{children}</h4>,
      normal: ({ children }) => <p className="text-base text-white">{children}</p>,
    },
    list: {
      bullet: ({ children }) => <ul className="list-disc ml-6 text-white">{children}</ul>,
      number: ({ children }) => <ol className="list-decimal ml-6 text-white">{children}</ol>,
    },
    listItem: {
      bullet: ({ children }) => <li className="text-white">{children}</li>,
      number: ({ children }) => <li className="text-white">{children}</li>,
    },
    types: {
      image: ({ value }) => <Img src={urlFor(value.asset).url()} alt="Blog Image" className="rounded-lg my-4" />,
    },
    // Adding default/empty implementations for optional properties
    hardBreak: () => <br />,
    unknownMark: () => null,
    unknownType: () => null,
    unknownBlockStyle: () => null,
    unknownList: () => null,
    unknownListItem: () => null,
  };

  return (
    <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-center text-4xl font-bold text-white mb-4">
          {data.title}
        </h1>
        <div className="flex justify-center">
          <div className="relative w-full max-w-3xl" style={{ paddingTop: '50%' }}>
            <Img
              src={urlFor(data.titleImage).url()}
              alt="Title Image"
              className="absolute inset-0 w-full h-full object-cover rounded-lg border border-gray-700 shadow-lg"
            />
          </div>
        </div>
        <div className="prose prose-light max-w-full mx-auto text-white mt-4">
          <PortableText value={data.content} components={portableTextComponents} />
        </div>
        <div className="flex justify-center space-x-4 mt-8">
          {shareButtons.map((button, index) => (
            <a key={index} href={button.url} target="_blank" rel="noopener noreferrer" className="text-xl text-white">
              {button.icon}
            </a>
          ))}
          <button onClick={copyToClipboard} className="text-xl text-white">
            <FaCopy />
          </button>
        </div>
        <div className="mt-12">
          <h2 className="text-2xl font-bold text-white mb-6">Further Reading</h2>
          <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {furtherReading.map((blog, index) => (
              <div key={index} className="bg-gray-800 p-4 rounded-lg shadow-lg">
                <Img
                  src={urlFor(blog.titleImage).url()}
                  alt={blog.title}
                  className="w-full h-48 object-cover rounded-lg"
                />
                <h3 className="text-xl font-semibold text-white mt-4">{blog.title}</h3>
                <a href={`/blogs/${blog.slug}`} className="text-blue-400 underline mt-2 inline-block">
                  Read more
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}