// src/ui/PageUI/Paragraph.jsx
import React from 'react';

const Paragraph = ({ heading, text, bullets }) => {
  return (
    <div className="mb-8">
      <h3 className="text-xl font-semibold mb-2 text-center">{heading}</h3>
      <p className="text-base mb-4 text-center">{text}</p>
      {bullets && bullets.length > 0 && (
        <ul className="list-disc list-inside mb-4">
          {bullets.map((bullet, index) => (
            <li key={index} className="text-base mb-1 text-center">{bullet}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Paragraph;
