import React from "react";
import { ThirdwebProvider, PayEmbed } from "thirdweb/react";
import { createWallet, walletConnect, inAppWallet } from "thirdweb/wallets";
import { createThirdwebClient } from "thirdweb";
import Section from "./Section";

const client = createThirdwebClient({
  clientId: "34d73d1184693e56e37496f045510c1d"
});

const wallets = [
  createWallet("io.metamask"),
  createWallet("com.coinbase.wallet"),
  walletConnect(),
  inAppWallet({
    auth: {
      options: ["email", "google", "apple", "facebook", "phone"]
    }
  })
];

export default function BuyCryptoPage() {
  return (
    <Section
      className="pt-[12rem] -mt-[5.25rem]"
      crosses
      crossesOffset="lg:translate-y-[5.25rem]"
      customPaddings
      id="buy-crypto"
    >
      <div className="text-center">
        <div style={{ maxWidth: "600px", margin: "0 auto" }}>
          <ThirdwebProvider
            clientId="34d73d1184693e56e37496f045510c1d"
            supportedWallets={wallets}
          >
            <PayEmbed client={client} />
          </ThirdwebProvider>
        </div>
      </div>
    </Section>
  );
}

