import React, { useEffect, useState } from 'react';
import axios from 'axios';

type Crypto = {
  id: string;
  name: string;
  image: string;
  current_price: number;
  price_change_percentage_24h: number;
};

const TableOne: React.FC = () => {
  const [cryptos, setCryptos] = useState<Crypto[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchCryptoData = async () => {
    try {
      const cachedData = localStorage.getItem('cryptoData');
      const cachedTime = localStorage.getItem('cryptoDataTime');
      const now = new Date().getTime();
      const cachedTimeNum = Number(cachedTime);

      if (cachedData && cachedTime && (now - cachedTimeNum < 3600000)) { // 1 hour cache
        const data = JSON.parse(cachedData);
        setCryptos(data);
      } else {
        const response = await axios.get(
          'https://api.coingecko.com/api/v3/coins/markets',
          {
            params: {
              vs_currency: 'usd',
              order: 'market_cap_desc',
              per_page: 10,
              page: 1,
              sparkline: false,
            },
          }
        );
        setCryptos(response.data);
        localStorage.setItem('cryptoData', JSON.stringify(response.data));
        localStorage.setItem('cryptoDataTime', now.toString());
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching crypto data:', error);
      setError('Failed to fetch data. Please try again later.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCryptoData();
  }, []);

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price).replace(',', ' ');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="rounded-sm border border-stroke bg-white px-2 py-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-6 xl:px-8 xl:pb-1">
      <h4 className="mb-4 text-lg font-semibold text-black dark:text-white sm:mb-6 sm:text-xl">
        Top 10 Crypto Currencies
      </h4>

      <div className="overflow-x-auto">
        <div className="min-w-full inline-block align-middle">
          <div className="grid grid-cols-6 gap-4 text-xs sm:text-sm bg-black-2 dark:bg-meta-4">
            <div className="p-2 sm:p-3 xl:p-4">
              <h5 className="font-medium uppercase text-black">#</h5>
            </div>
            <div className="p-2 sm:p-3 xl:p-4">
              <h5 className="font-medium uppercase text-black">Coin</h5>
            </div>
            <div className="p-2 sm:p-3 xl:p-4">
              <h5 className="font-medium uppercase text-black text-center">Name</h5>
            </div>
            <div className="p-2 sm:p-3 xl:p-4">
              <h5 className="font-medium uppercase text-black text-center">Price (USD)</h5>
            </div>
            <div className="p-2 sm:p-3 xl:p-4">
              <h5 className="font-medium uppercase text-black text-center">24h Change</h5>
            </div>
            <div className="p-2 sm:p-3 xl:p-4">
              <h5 className="font-medium uppercase text-black text-center">Buy</h5>
            </div>
          </div>

          {cryptos.map((crypto, key) => (
            <div
              className={`grid grid-cols-6 gap-4 text-xs sm:text-sm ${
                key === cryptos.length - 1 ? '' : 'border-b border-stroke dark:border-strokedark'
              }`}
              key={crypto.id}
            >
              <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4">
                <p className="text-black dark:text-white">{key + 1}</p>
              </div>

              <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4">
                <img src={crypto.image} alt={crypto.name} className="h-6 w-6 sm:h-8 sm:w-8" />
              </div>

              <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4">
                <p className="text-black dark:text-white">{crypto.name}</p>
              </div>

              <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4 whitespace-nowrap">
                <p className="text-black dark:text-white">{formatPrice(crypto.current_price)}</p>
              </div>

              <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4">
                <p className={crypto.price_change_percentage_24h >= 0 ? 'text-green-500' : 'text-red-500'}>
                  {crypto.price_change_percentage_24h.toFixed(2)}%
                </p>
              </div>

              <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4">
                <a href="/dashboard/tables" className="bg-blue-500 text-white py-1 px-2 sm:px-3 rounded text-xs sm:text-sm">
                  Buy
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TableOne;













