import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import './Coin.css';
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';
import { FaEye, FaShoppingCart, FaExchangeAlt, FaShareAlt } from 'react-icons/fa';
import { useAuth } from '../../Context/AuthContext'; // Assuming you have an AuthContext
import { addToWatchlist, removeFromWatchlist } from '../../Firebase'; // Import Firebase functions

const Coin = () => {
    const { coinId } = useParams();
    const navigate = useNavigate();
    const { currentUser } = useAuth(); // Get the current user from your AuthContext
    const [coin, setCoin] = useState({});
    const [historicalData, setHistoricalData] = useState([]);
    const [selectedView, setSelectedView] = useState('24h');
    const [watchlist, setWatchlist] = useState([]); // State to manage the watchlist

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api.coingecko.com/api/v3/coins/${coinId}`);
                const historicalResponse = await axios.get(`https://api.coingecko.com/api/v3/coins/${coinId}/market_chart`, {
                    params: { vs_currency: 'usd', days: selectedView }
                });
                setHistoricalData(historicalResponse.data.prices.map(price => ({ time: new Date(price[0]).toLocaleDateString(), price: price[1] })));
                setCoin(response.data);
            } catch (error) {
                console.error('Error fetching coin data:', error);
            }
        };

        fetchData();
    }, [coinId, selectedView]);

    const handleViewChange = (event) => {
        setSelectedView(event.target.value);
    };

    const handleAddToWatchlist = async () => {
        if (!currentUser) {
            navigate('/login'); // Redirect to login if user is not signed in
            return;
        }
        try {
            await addToWatchlist(currentUser.uid, coinId);
            setWatchlist([...watchlist, coinId]); // Update local state
        } catch (error) {
            console.error('Error adding to watchlist:', error);
        }
    };

    const handleRemoveFromWatchlist = async () => {
        if (!currentUser) {
            navigate('/login'); // Redirect to login if user is not signed in
            return;
        }
        try {
            await removeFromWatchlist(currentUser.uid, coinId);
            setWatchlist(watchlist.filter(id => id !== coinId)); // Update local state
        } catch (error) {
            console.error('Error removing from watchlist:', error);
        }
    };

    const buyCoin = () => {
        navigate('/dashboard/tables');
    };

    const convertCrypto = () => {
        navigate('/dashboard/profile');
    };

    const shareCoin = () => {
        const shareData = {
            title: `${coin.name}`,
            text: `Check out the latest details about ${coin.name}!`,
            url: window.location.href,
        };
        if (navigator.share) {
            navigator.share(shareData).catch(console.error);
        } else {
            navigator.clipboard.writeText(`${shareData.text} ${shareData.url}`).then(() => {
                alert('Link copied to clipboard');
            }).catch(console.error);
        }
    };

    return (
        <div className="container">
            {Object.keys(coin).length > 0 && (
                <div className='coin-container'>
                    <div className='content'>
                        <h1>{coin.name}</h1>
                        <div>
                            {watchlist.includes(coinId) ? (
                                <button onClick={handleRemoveFromWatchlist} className="bg-red-500 text-white px-2 first:py-1 rounded">
                                <FaEye /> Remove {coin.name} from Watchlist
                                </button>
                            ) : (
                                <button onClick={handleAddToWatchlist} className="blue-button">
                                    <FaEye /> Add {coin.name} to Watchlist
                                </button>
                            )}
                        </div>
                    </div>
                    <div className='content'>
                        <div className='rank'>
                            <span className='rank-btn'>Rank # {coin.market_cap_rank}</span>
                        </div>
                        <div className='info'>
                            <div className='coin-heading'>
                                {coin.image && coin.image.small && <img src={coin.image.small} alt='' />}
                                <p>{coin.name}</p>
                                {coin.symbol && <p>{coin.symbol.toUpperCase()}/USD</p>}
                            </div>
                            <br></br>
                            <div className='coin-price'>
                                {coin.market_data && coin.market_data.current_price && (
                                    <h1>${coin.market_data.current_price.usd.toLocaleString()}</h1>
                                )}
                                <button onClick={shareCoin} className="blue-button">
                                    <FaShareAlt /> Share {coin.name}
                                </button>
                            </div>
                            <div className='action-buttons'>
                                <div>
                                    <button onClick={buyCoin} className="blue-button">
                                        <FaShoppingCart /> Buy {coin.name}
                                    </button>
                                </div>
                                <div>
                                    <button onClick={convertCrypto} className="blue-button">
                                        <FaExchangeAlt /> Convert {coin.name}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='content'>
                        <table>
                            <thead>
                                <tr>
                                    <th>1h</th>
                                    <th>24h</th>
                                    <th>7d</th>
                                    <th>14d</th>
                                    <th>30d</th>
                                    <th>1yr</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{coin.market_data?.price_change_percentage_1h_in_currency ? <p>{coin.market_data.price_change_percentage_1h_in_currency.usd.toFixed(1)}%</p> : null}</td>
                                    <td>{coin.market_data?.price_change_percentage_24h_in_currency ? <p>{coin.market_data.price_change_percentage_24h_in_currency.usd.toFixed(1)}%</p> : null}</td>
                                    <td>{coin.market_data?.price_change_percentage_7d_in_currency ? <p>{coin.market_data.price_change_percentage_7d_in_currency.usd.toFixed(1)}%</p> : null}</td>
                                    <td>{coin.market_data?.price_change_percentage_14d_in_currency ? <p>{coin.market_data.price_change_percentage_14d_in_currency.usd.toFixed(1)}%</p> : null}</td>
                                    <td>{coin.market_data?.price_change_percentage_30d_in_currency ? <p>{coin.market_data.price_change_percentage_30d_in_currency.usd.toFixed(1)}%</p> : null}</td>
                                    <td>{coin.market_data?.price_change_percentage_1y_in_currency ? <p>{coin.market_data.price_change_percentage_1y_in_currency.usd.toFixed(1)}%</p> : null}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='content'>
                        <div className='stats'>
                            <div className='left'>
                                <div className='row'>
                                    <h4>24 Hour Low</h4>
                                    {coin.market_data?.low_24h && <p>${coin.market_data.low_24h.usd.toLocaleString()}</p>}
                                </div>
                                <div className='row'>
                                    <h4>24 Hour High</h4>
                                    {coin.market_data?.high_24h && <p>${coin.market_data.high_24h.usd.toLocaleString()}</p>}
                                </div>
                            </div>
                            <div className='right'>
                                <div className='row'>
                                    <h4>Market Cap</h4>
                                    {coin.market_data?.market_cap && <p>${coin.market_data.market_cap.usd.toLocaleString()}</p>}
                                </div>
                                <div className='row'>
                                    <h4>Circulating Supply</h4>
                                    {coin.market_data && <p>{coin.market_data.circulating_supply}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='content'>
                        <div className='chart-container'>
                            <LineChart width={600} height={300} data={historicalData}>
                                <XAxis dataKey="time" />
                                <YAxis />
                                <Tooltip />
                                <Line type="monotone" dataKey="price" stroke="#8884d8" />
                            </LineChart>
                            <div>
                                <label>Select View:</label>
                                <select value={selectedView} onChange={handleViewChange}>
                                    <option value="24h">24 Hours</option>
                                    <option value="7d">7 Days</option>
                                    <option value="30d">30 Days</option>
                                    <option value="1y">1 Year</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='content font-bold'>
                        <h2>About</h2>
                        {coin.description?.en && (
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(coin.description.en),
                                }}
                            ></p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Coin;


