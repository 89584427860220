import React from 'react';
import { ConnectButton } from "thirdweb/react"; 
import { client } from "../../../../../app/client";

const ConnectWalletButton = () => {
  return (
    <div>
      <ConnectButton 
        client={client}
      />
    </div>
  );
}

export default ConnectWalletButton;

