import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';

const BitcoinDominanceChart: React.FC = () => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const [btcDominance, setBtcDominance] = useState<number | null>(null);

  useEffect(() => {
    const fetchBitcoinDominance = async () => {
      try {
        // Check if data is in localStorage and is still valid (cache for 1 hour)
        const cachedData = localStorage.getItem('btcDominance');
        const cacheTime = localStorage.getItem('btcDominanceTime');
        const now = new Date().getTime();

        if (cachedData && cacheTime && now - parseInt(cacheTime) < 3600000) {
          setBtcDominance(parseFloat(cachedData));
        } else {
          const response = await fetch('https://api.coingecko.com/api/v3/global');
          const data = await response.json();
          const btcDominance = data.data.market_cap_percentage.btc;

          setBtcDominance(btcDominance);

          // Store data in localStorage with current time
          localStorage.setItem('btcDominance', btcDominance.toString());
          localStorage.setItem('btcDominanceTime', now.toString());
        }
      } catch (error) {
        console.error('Error fetching Bitcoin dominance data:', error);
      }
    };

    fetchBitcoinDominance();
  }, []);

  useEffect(() => {
    if (btcDominance !== null && chartRef.current) {
      new Chart(chartRef.current, {
        type: 'doughnut',
        data: {
          labels: [`Bitcoin (${btcDominance.toFixed(2)}%)`, `Others (${(100 - btcDominance).toFixed(2)}%)`],
          datasets: [{
            label: 'Bitcoin Dominance',
            data: [btcDominance, 100 - btcDominance],
            backgroundColor: ['#FFCE56', '#36A2EB'],
            hoverBackgroundColor: ['#FFCE56', '#36A2EB'],
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        }
      });
    }
  }, [btcDominance]);

  return (
    <div className="p-4 bg-white dark:bg-boxdark rounded-sm shadow-default">
      <h4 className="mb-4 text-lg font-semibold text-black dark:text-white">
        Bitcoin Dominance
      </h4>
      <div className="relative w-full h-64 md:h-96">
        <canvas ref={chartRef} />
      </div>
    </div>
  );
};

export default BitcoinDominanceChart;








