import React, { useContext } from 'react';
import { createWallet, walletConnect, inAppWallet } from 'thirdweb/wallets';
import { ThirdwebProvider, PayEmbed } from 'thirdweb/react';
import { createThirdwebClient } from 'thirdweb';
import { AuthContext } from '../Context/AuthContext';
import Section from '../components/Section';
import { useNavigate } from 'react-router-dom';

const BuyCryptoPage = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  // Thirdweb configurations
  const client = createThirdwebClient({
    clientId: '34d73d1184693e56e37496f045510c1d'
  });

  const wallets = [
    createWallet('io.metamask'),
    createWallet('com.coinbase.wallet'),
    walletConnect(),
    inAppWallet({
      auth: {
        options: ['email', 'google', 'apple', 'facebook', 'phone']
      }
    })
  ];

  return (
    <Section className="pt-12 lg:pt-24 pb-16 lg:pb-32 px-4 lg:px-0">
      <div className="max-w-4xl mx-auto">
        <div className="text-center mb-8">
          <h1 className="text-3xl lg:text-4xl font-bold mb-2">Buy Cryptocurrency</h1>
          <p className="text-gray-600">Sign up or sign in to buy cryptocurrency instantly.</p>
        </div>

        {!currentUser ? (
          <div className="flex flex-col items-center mb-8">
            <p className="mb-4">Please sign up or sign in to buy crypto:</p>
            <button onClick={() => navigate('/sign-up')} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mb-2">
              Sign Up
            </button>
            <button onClick={() => navigate('/sign-in')} className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">
              Sign In
            </button>
            <p className="mt-4 text-sm text-gray-500">We will add more cryptocurrencies soon!</p>
            <p className="mt-2 text-sm text-gray-500">Wondering what is a wallet? <a href="#" className="text-blue-500">Learn more</a></p>
            <p className="mt-2 text-sm text-gray-500">How do I open a wallet? <a href="#" className="text-blue-500">Get started</a></p>
            <p className="mt-2 text-sm text-gray-500">Reach out to us via <strong>WhatsApp, Email, or Phone</strong> and we will help you set up a wallet and buy crypto.</p>
          </div>
        ) : (
          <div className="text-center">
            <ThirdwebProvider clientId="34d73d1184693e56e37496f045510c1d" supportedWallets={wallets}>
              <PayEmbed client={client} />
            </ThirdwebProvider>
            <p className="mt-4 text-sm text-gray-500">You can buy crypto straight into your wallet, sell crypto from your wallet, and swap crypto.</p>
          </div>
        )}
      </div>
    </Section>
  );
};

export default BuyCryptoPage;














