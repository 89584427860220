import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '../app/blogs/ui/card';
import { client, urlFor } from '../../src/app/blogs/lib/sanity';
import { Img } from 'react-image';
import { Button } from '../app/blogs/ui/button';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

interface BlogPost {
  title: string;
  smallDescription: string;
  currentSlug: string;
  titleImage: any;
}

async function getData(): Promise<BlogPost[]> {
  const query = `
    *[_type == 'blog'] | order(_createdAt desc) {
      title,
      smallDescription,
      "currentSlug": slug.current,
      titleImage
    }
  `;
  const data: BlogPost[] = await client.fetch(query);
  return data;
}

export default function Page() {
  const [blogs, setBlogs] = useState<BlogPost[]>([]);
  const [filteredBlogs, setFilteredBlogs] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  
  const blogsPerPage = 6;

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getData();
        setBlogs(data);
        setFilteredBlogs(data);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const filtered = blogs.filter(blog =>
      blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      blog.smallDescription.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredBlogs(filtered);
    setCurrentPage(1); // Reset to first page when filtering
  }, [searchTerm, blogs]);

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="min-h-[calc(100vh-80px)] py-8 px-4 sm:px-6 lg:px-8">
      <h1 className="text-4xl font-bold text-center text-white mb-8">
        Read Our Blogs
      </h1>
      <div className="text-center mb-8">
        <input
          type="text"
          placeholder="Search Blogs..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 rounded border border-white dark:border-gray-600"
        />
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-[60vh]">
          <ClipLoader color="#fff" size={48} />
        </div>
      ) : (
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {currentBlogs.length > 0 ? (
            currentBlogs.map((post: BlogPost) => (
              <Card key={post.currentSlug} className="hover:shadow-lg transition-shadow duration-300 ease-in-out">
                <Img
                  src={urlFor(post.titleImage).url()}
                  alt={post.title}
                  loader={<ClipLoader color="#fff" size={24} />}
                  unloader={<div className="text-center text-red-500">Error loading image</div>}
                  className="rounded-t-lg h-48 object-cover w-full"
                />
                <CardContent className="p-4">
                  <h3 className="text-xl font-bold text-center text-white mb-2">
                    {post.title}
                  </h3>
                  <p className="text-sm text-center text-gray-300 mb-4">
                    {post.smallDescription}
                  </p>
                  <Button className="w-full">
                    <Link to={`/blogs/${post.currentSlug}`} className="text-center text-blue-300">
                      Read More
                    </Link>
                  </Button>
                </CardContent>
              </Card>
            ))
          ) : (
            <div className="text-center text-white">No blogs available</div>
          )}
        </div>
      )}
      <div className="flex justify-center mt-8">
        <nav>
          <ul className="flex space-x-2">
            {Array.from({ length: Math.ceil(filteredBlogs.length / blogsPerPage) }, (_, i) => (
              <li key={i}>
                <button
                  onClick={() => paginate(i + 1)}
                  className={`px-4 py-2 rounded ${currentPage === i + 1 ? 'bg-primary text-white' : 'bg-gray-800 text-gray-300'}`}
                >
                  {i + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
}
