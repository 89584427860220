import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail, signOut, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../Firebase';
import { Link, useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate and useLocation
import GoogleIcon from '../Assets/social-google.svg';

const SignInPage = () => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [status, setStatus] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showReset, setShowReset] = useState(false); // Added state for showing password reset inputs
  const navigate = useNavigate();
  const location = useLocation();

  const handleInputs = (event) => {
    const { name, value } = event.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleResetInputs = (event) => {
    setResetEmail(event.target.value);
  };

  const handleSignIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, credentials.email, credentials.password);
      setStatus('success');
      setIsLoggedIn(true);
      console.log('User signed in successfully!');
      if (location.state && location.state.from === '/buy-crypto') {
        navigate('/buy-crypto');
      } else {
        // Redirect to default page after sign-in (if needed)
        navigate('/');
      }
    } catch (error) {
      setStatus('error');
      alert(error.message);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setStatus('');
      setIsLoggedIn(false);
      console.log('User signed out successfully!');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetMessage('Password reset email sent. Please check your inbox.');
    } catch (error) {
      setResetMessage(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      setStatus('success');
      setIsLoggedIn(true);
      console.log('User signed in with Google successfully!');
      if (location.state && location.state.from === '/buy-crypto') {
        navigate('/buy-crypto');
      } else {
        // Redirect to default page after sign-in (if needed)
        navigate('/');
      }
    } catch (error) {
      setStatus('error');
      alert(error.message);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setStatus('success');
        setIsLoggedIn(true);
        console.log('User is logged in');
        // Redirect to default page after sign-in (if needed)
        navigate('/');
      } else {
        setStatus('');
        setIsLoggedIn(false);
        console.log('User is logged out');
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <div className="flex justify-center items-center min-h-screen text-white">
      <div className="rounded-lg shadow-lg w-full max-w-md p-8">
        <h1 className="text-2xl font-bold mb-4">Sign In</h1>
        <p className="text-gray-400 mb-6">
          Welcome back! Securely access your Laani account and manage your crypto portfolio.
        </p>
        {status === 'error' && <p className="mb-4 text-red-500">Invalid email or password.</p>}
        {resetMessage && <p className="mb-4 text-green-500">{resetMessage}</p>}
        {isLoggedIn && <p className="mb-4 text-green-500">Now you can buy crypto, sell crypto, or manage your portfolio.</p>}

        <button
          type="button"
          onClick={handleGoogleSignIn}
          className="w-full flex items-center justify-center border border-gray-300 rounded-md py-2 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 mb-4"
        >
          <img src={GoogleIcon} alt="google" className="w-4 h-4 mr-2" />
          Sign in with Google
        </button>

        <div className="relative flex py-2 items-center">
          <div className="flex-grow border-t border-gray-300"></div>
          <span className="flex-shrink mx-4 text-gray-400">OR</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>

        <div className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium">
              Email address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 bg-white text-black"
              value={credentials.email}
              onChange={handleInputs}
            />
          </div>

          <div className="relative">
            <label htmlFor="password" className="block text-sm font-medium">
              Password
            </label>
            <div className="flex items-center">
              <input
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 bg-white text-black"
                value={credentials.password}
                onChange={handleInputs}
              />
              <button
                type="button"
                onClick={handleClickShowPassword}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                {showPassword ? 'Hide' : 'Show'}
              </button>
            </div>
          </div>

          <div className="flex justify-center items-center mt-6">
            <button
              type="button"
              onClick={handleSignIn}
              className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Sign In
            </button>
          </div>

          <div className="flex justify-center items-center mt-4">
            <button
              type="button"
              onClick={handleSignOut}
              className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Sign Out
            </button>
          </div>

          <div className="flex justify-center items-center mt-4">
            <button
              type="button"
              onClick={() => setShowReset(!showReset)}
              className="text-sm text-indigo-600 hover:text-indigo-500 focus:outline-none"
            >
              Forgot your password?
            </button>
          </div>

          {showReset && (
            <div className="mt-4">
              <label htmlFor="reset-email" className="block text-sm">
                Enter your email to reset your password
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  name="reset-email"
                  id="reset-email"
                  className="focus:ring-2 focus:ring-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                  placeholder="Enter your email address"
                  value={resetEmail}
                  onChange={handleResetInputs}
                />
                <button
                  type="button"
                  className="ml-2 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handlePasswordReset}
                >
                  Reset
                </button>
              </div>
            </div>
          )}

          <div className="mt-4 flex justify-between items-center">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 text-indigo-600 focus:ring-2 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-400">
                Remember me
              </label>
            </div>
          </div>

          <div className="flex items-center mt-6">
            <p className="mr-2 text-sm text-gray-400">Don't have an account?</p>
            <Link
              to="/sign-up"
              className="text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none"
            >
              Create an Account
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;




          


             







