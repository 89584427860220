import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Loader from './Common/Loader/index';
import PageTitle from './components/PageTitle';
import CryptoCurrencies from './pages/CryptoCurrencies'; 
import FormElements from './pages/Form/FormElements';
import FormLayout from './pages/Form/FormLayout';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import Tables from './pages/Tables';
import ECommerce from './pages/Dashboard/Ecommerce';

function Dashboard() {
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return loading ? (
    <Loader />
  ) : (
      <Routes>
        <Route
          index
          element={
            <>
              <PageTitle title="Laani Dashboard" />
              <ECommerce />
            </>
          }
        />
        <Route
          path="cryptocurrencies/*"
          element={
            <>
              <PageTitle title="Cryptocurrencies" />
              <CryptoCurrencies />
            </>
          }
        />
        <Route
          path="watchlist"
          element={
            <>
              <PageTitle title="My Watchlist" />
            </>
          }
        />
        <Route
          path="profile"
          element={
            <>
              <PageTitle title="Profile" />
              <Profile />
            </>
          }
        />
        <Route
          path="forms/form-elements"
          element={
            <>
              <PageTitle title="Form Elements" />
              <FormElements />
            </>
          }
        />
        <Route
          path="forms/form-layout"
          element={
            <>
              <PageTitle title="Form Layout" />
              <FormLayout />
            </>
          }
        />
        <Route
          path="tables"
          element={
            <>
              <PageTitle title="Tables" />
              <Tables />
            </>
          }
        />
        <Route
          path="settings"
          element={
            <>
              <PageTitle title="Settings" />
              <Settings />
            </>
          }
        />
      </Routes>
  );
}

export default Dashboard;


