// Toggle.jsx
import React, { useState } from 'react';

const Toggle = () => {
  const [selectedOption, setSelectedOption] = useState('Spot and OTC Trading');

  const renderContent = () => {
    switch (selectedOption) {
      case 'Spot and OTC Trading':
        return <div>Execute seamless trades for various cryptocurrencies at competitive market prices through our user-friendly spot market or negotiate large-volume transactions directly with our OTC desk.</div>;
      case 'Bitcoin and Eth ETFs':
        return <div>Gain exposure to leading cryptocurrencies through our secure and regulated cryptocurrency ETFs. This offers a familiar and efficient way for institutions to invest in Bitcoin and Ethereum without the complexities of direct crypto custody..</div>;
      case 'Derivatives and Futures':
        return <div>Manage risk and hedge your exposure with sophisticated derivatives and futures contracts. Laani's advanced platform allows institutions to employ advanced trading strategies and capitalize on market movements.</div>;
      default:
        return null;
    }
  };

  return (
    <div className="my-8">
      <div className="flex justify-center space-x-4">
        <button
          className={`px-4 py-2 rounded border ${selectedOption === 'Spot and OTC Trading' ? 'bg-blue-500 text-white' : 'bg-white text-black border-gray-300'}`}
          onClick={() => setSelectedOption('Spot and OTC Trading')}
        >
          Spot and OTC Trading
        </button>
        <button
          className={`px-4 py-2 rounded border ${selectedOption === 'Bitcoin and Eth ETFs' ? 'bg-blue-500 text-white' : 'bg-white text-black border-gray-300'}`}
          onClick={() => setSelectedOption('Bitcoin and Eth ETFs')}
        >
          Bitcoin and Eth ETFs
        </button>
        <button
          className={`px-4 py-2 rounded border ${selectedOption === 'Derivatives and Futures' ? 'bg-blue-500 text-white' : 'bg-white text-black border-gray-300'}`}
          onClick={() => setSelectedOption('Derivatives and Futures')}
        >
          Derivatives and Futures
        </button>
      </div>
      <div className="mt-4">
        {renderContent()}
      </div>
    </div>
  );
};

export default Toggle;

