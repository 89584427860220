import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import axios from 'axios';

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string[];
    hoverBackgroundColor: string[];
  }[];
}

const TopCryptoCurrenciesByMarketCap: React.FC = () => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const fetchTopCryptoData = async () => {
      try {
        // Check if data is in localStorage and is still valid (cache for 1 hour)
        const cachedData = localStorage.getItem('topCryptoData');
        const cacheTime = localStorage.getItem('topCryptoDataTime');
        const now = new Date().getTime();

        if (cachedData && cacheTime && now - parseInt(cacheTime) < 3600000) {
          const parsedData = JSON.parse(cachedData);
          createChart(parsedData);
        } else {
          const response = await axios.get('https://api.coingecko.com/api/v3/coins/markets', {
            params: {
              vs_currency: 'usd',
              order: 'market_cap_desc',
              per_page: 5,
              page: 1,
            },
          });

          const coins = response.data;
          const totalMarketCap = coins.reduce((acc: number, coin: any) => acc + coin.market_cap, 0);
          const labels = coins.map((coin: any) => `${coin.name} (${((coin.market_cap / totalMarketCap) * 100).toFixed(2)}%)`);
          const marketCaps = coins.map((coin: any) => coin.market_cap);
          const backgroundColors = ['#FF6384', '#36A2EB', '#FFCE56', '#FF9F40', '#4BC0C0'];

          const chartData = {
            labels,
            datasets: [
              {
                label: 'Top Crypto Currencies by Market Cap',
                data: marketCaps,
                backgroundColor: backgroundColors,
                hoverBackgroundColor: backgroundColors,
              },
            ],
          };

          localStorage.setItem('topCryptoData', JSON.stringify(chartData));
          localStorage.setItem('topCryptoDataTime', now.toString());
          createChart(chartData);
        }
      } catch (error) {
        console.error('Error fetching top crypto currencies by market cap:', error);
      }
    };

    fetchTopCryptoData();
  }, []);

  const createChart = (chartData: ChartData) => {
    if (chartRef.current) {
      new Chart(chartRef.current, {
        type: 'doughnut',
        data: chartData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }
  };

  return (
    <div className="p-4 bg-white dark:bg-boxdark rounded-sm shadow-default">
      <h4 className="mb-4 text-lg font-semibold text-black dark:text-white">
        Top Crypto Currencies by Market Cap
      </h4>
      <div className="relative w-full h-64 md:h-96">
        <canvas ref={chartRef} />
      </div>
    </div>
  );
};

export default TopCryptoCurrenciesByMarketCap;




