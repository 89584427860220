// Accordion.jsx
import React, { useState } from 'react';
import styled from 'styled-components';
import { FaAngleDown } from 'react-icons/fa';

const Accordion = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <AccordionContainer>
      {data.map((item, index) => (
        <AccordionWrapper key={index}>
          <AccordionItem onClick={() => toggleAccordion(index)}>
            <AccordionQuestion>
              <QuestionContainer>
                <QuestionText>{item.question}</QuestionText>
                <IconContainer>
                  <FaAngleDown />
                </IconContainer>
              </QuestionContainer>
            </AccordionQuestion>
            {activeIndex === index && <AccordionAnswer>{item.answer}</AccordionAnswer>}
          </AccordionItem>
        </AccordionWrapper>
      ))}
    </AccordionContainer>
  );
};

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background);
  color: var(--primary);
  padding: 20px;
`;

const AccordionWrapper = styled.div`
  width: 70%;
`;

const AccordionItem = styled.div`
  background-color: var(--dark-background);
  border-radius: 8px;
  margin-bottom: 16px;
  cursor: pointer;
`;

const AccordionQuestion = styled.div`
  padding: 16px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AccordionAnswer = styled.div`
  padding: 16px;
  font-size: 16px;
`;

const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const QuestionText = styled.div`
  flex: 1;
`;

const IconContainer = styled.div`
  color: var(--primary);
`;

export default Accordion;


