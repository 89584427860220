import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { collection, doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db, sendWelcomeEmail } from '../Firebase';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import GoogleIcon from '../Assets/social-google.svg';

const GetStarted = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Listener for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Check if email is verified
        if (user.emailVerified) {
          navigate('/'); // Redirect to home page
        }
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSignUp = async (values, { setSubmitting }) => {
    const { email, password, fullName, phoneNumber, subscribeNewsletter } = values;
    setError('');
    setSuccess('');

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await updateProfile(user, { displayName: fullName });
      await sendEmailVerification(user);

      if (auth.currentUser) {
        await addUserDataToFirestore({
          userId: user.uid,
          fullName,
          phoneNumber,
          email,
          subscribeNewsletter,
        });

        if (subscribeNewsletter) {
          await addContactToSendGrid(email, fullName);
        }

        setSuccess('User signed up successfully! Please check your email for verification instructions.');
      } else {
        setError('User is not authenticated');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      if (auth.currentUser) {
        await addUserDataToFirestore({
          userId: user.uid,
          fullName: user.displayName,
          email: user.email,
          phoneNumber: '',
          subscribeNewsletter: false,
        });
      } else {
        setError('User is not authenticated');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const addUserDataToFirestore = async (userData) => {
    const userRef = doc(collection(db, 'users'), userData.userId);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      await updateDoc(userRef, userData);
    } else {
      await setDoc(userRef, userData);
    }
  };

  const addContactToSendGrid = async (email, fullName) => {
    try {
      const response = await sendWelcomeEmail({ email, fullName });
      console.log(response.data.message);
    } catch (error) {
      console.error('Error adding contact to SendGrid:', error);
    }
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('Full name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    phoneNumber: Yup.string(),
    subscribeNewsletter: Yup.boolean(),
  });

  return (
    <div className="h-screen flex justify-center items-center  text-white">
      <div className="max-w-md w-full p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-6">Create an Account</h2>

        <Formik
          initialValues={{
            fullName: '',
            email: '',
            password: '',
            phoneNumber: '',
            subscribeNewsletter: false,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSignUp}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="fullName" className="block text-sm font-medium">Full Name</label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fullName}
                  className={`mt-1 block w-full p-2 bg-gray-700 rounded-lg ${touched.fullName && errors.fullName ? 'border-red-500' : 'border-gray-600'}`}
                />
                {touched.fullName && errors.fullName && (
                  <p className="text-red-500 text-xs mt-1">{errors.fullName}</p>
                )}
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  className={`mt-1 block w-full p-2 bg-gray-700 rounded-lg ${touched.email && errors.email ? 'border-red-500' : 'border-gray-600'}`}
                />
                {touched.email && errors.email && (
                  <p className="text-red-500 text-xs mt-1">{errors.email}</p>
                )}
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium">Password</label>
                <div className="relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    className={`mt-1 block w-full p-2 bg-gray-700 rounded-lg ${touched.password && errors.password ? 'border-red-500' : 'border-gray-600'}`}
                  />
                  <button
                    type="button"
                    onClick={handleClickShowPassword}
                    className="absolute inset-y-0 right-0 flex items-center px-2 text-sm text-gray-400"
                  >
                    {showPassword ? 'Hide' : 'Show'}
                  </button>
                </div>
                {touched.password && errors.password && (
                  <p className="text-red-500 text-xs mt-1">{errors.password}</p>
                )}
              </div>

              <div>
                <label htmlFor="phoneNumber" className="block text-sm font-medium">Phone Number</label>
                <input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                  className="mt-1 block w-full p-2 bg-gray-700 rounded-lg border-gray-600"
                />
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="subscribeNewsletter"
                  name="subscribeNewsletter"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.subscribeNewsletter}
                  className="h-4 w-4 text-blue-600 bg-gray-700 border-gray-600 rounded"
                />
                <label htmlFor="subscribeNewsletter" className="ml-2 block text-sm text-gray-300">Subscribe to Newsletter</label>
              </div>

              {error && (
                <p className="text-red-500 text-xs mt-1">{error}</p>
              )}
              {success && (
                <p className="text-green-500 text-xs mt-1">{success}</p>
              )}

              <button
                type="submit"
                className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-lg"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Signing up...' : 'Sign Up'}
              </button>
            </form>
          )}
        </Formik>

        <div className="flex items-center mt-6">
          <hr className="flex-1 border-t border-gray-600" />
          <span className="px-2 text-sm text-gray-400">or sign up with</span>
          <hr className="flex-1 border-t border-gray-600" />
        </div>

        <button
          onClick={handleGoogleSignUp}
          className="mt-4 w-full py-2 px-4 bg-white hover:bg-blue-700 text-black rounded-lg flex items-center justify-center"
        >
          <img src={GoogleIcon} alt="Google Icon" className="h-5 w-5 mr-2" />
          Sign Up with Google
        </button>

        <p className="mt-4 text-center text-sm text-gray-400">
          Already have an account? <Link to="/sign-in" className="text-blue-500">Log In</Link>
        </p>
      </div>
    </div>
  );
};

export default GetStarted;


















