import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { addToWatchlist, removeFromWatchlist } from '../../../../Firebase';
import { useAuth } from '../../../../Context/AuthContext';
import { Link } from 'react-router-dom';

type Crypto = {
  id: string;
  name: string;
  image: string;
  current_price: number;
  price_change_percentage_24h: number;
  market_cap: number;
};

type TableTwoProps = {
  currentPage: number;
  onPageChange: (page: number) => void;
  onCoinClick: (coinId: string) => void;
  watchlist: string[];
  onAddToWatchlist: (coinId: string) => void;
  onRemoveFromWatchlist: (coinId: string) => void;
  cryptos: Crypto[];
};

const TableTwo: React.FC<TableTwoProps> = ({
  currentPage,
  onPageChange,
  onCoinClick,
  watchlist,
  onAddToWatchlist,
  onRemoveFromWatchlist,
  cryptos,
}) => {
  const { currentUser } = useAuth();
  const [cache, setCache] = useState<{ [key: number]: Crypto[] }>({});
  const [totalPages, setTotalPages] = useState(0);

  const url = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=10&page=';

  const fetchCryptoData = async (page: number) => {
    if (cache[page]) {
      return;
    }

    try {
      const response = await axios.get(`${url}${page}&sparkline=false`);
      const data = response.data;
      setCache((prevCache) => ({ ...prevCache, [page]: data }));
      setTotalPages(Math.ceil(300 / 10)); // Adjust total pages based on actual data
    } catch (error) {
      console.error('Error fetching crypto data:', error);
    }
  };

  useEffect(() => {
    fetchCryptoData(currentPage);
  }, [currentPage]);

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  };

  const formatMarketCap = (marketCap: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      notation: 'compact',
      compactDisplay: 'short',
    }).format(marketCap);
  };

  const handleAddToWatchlist = async (coinId: string) => {
    if (!currentUser) return;
    try {
      await addToWatchlist(currentUser.uid, coinId);
      onAddToWatchlist(coinId);
    } catch (error) {
      console.error('Error adding to watchlist:', error);
    }
  };

  const handleRemoveFromWatchlist = async (coinId: string) => {
    if (!currentUser) return;
    try {
      await removeFromWatchlist(currentUser.uid, coinId);
      onRemoveFromWatchlist(coinId);
    } catch (error) {
      console.error('Error removing from watchlist:', error);
    }
  };

  const renderPagination = () => {
    const pages = [];
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);

    if (currentPage <= 2) {
      endPage = 4;
    }
    if (currentPage >= totalPages - 1) {
      startPage = totalPages - 3;
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => onPageChange(i)}
          className={`mx-1 px-3 py-1 rounded text-xs sm:text-sm ${
            currentPage === i
              ? 'bg-blue-600 text-white'
              : 'bg-gray-700 text-gray-300 hover:bg-blue-500'
          } transition-colors duration-300`}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  return (
    <div className="rounded-sm border border-stroke px-2 py-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-6 xl:px-8 xl:pb-1 w-full">
      <h4 className="mb-4 text-lg font-semibold text-white dark:text-white sm:mb-6 sm:text-xl">
        Cryptocurrency Prices by Market Cap
      </h4>

      <div className="overflow-x-auto">
        <table className="min-w-full text-xs sm:text-sm border-collapse">
          <thead>
            <tr className="bg-gray-800 text-white">
              <th className="p-2 text-left">#</th>
              <th className="p-2 text-left">Coin</th>
              <th className="p-2 text-left hidden md:table-cell">Name</th>
              <th className="p-2 text-left">Price (USD)</th>
              <th className="p-2 text-left hidden md:table-cell">24h Change</th>
              <th className="p-2 text-left hidden md:table-cell">Market Cap</th>
              <th className="p-2 text-left">Actions</th>
              <th className="p-2 text-left">Watchlist</th>
            </tr>
          </thead>
          <tbody>
            {cryptos.map((crypto, index) => (
              <tr
                key={crypto.id}
                className="border-b border-stroke dark:border-strokedark cursor-pointer hover:bg-gray-700"
                onClick={() => onCoinClick(crypto.id)}
              >
                <td className="p-2">{(currentPage - 1) * 10 + index + 1}</td>
                <td className="p-2 flex items-center">
                  <img src={crypto.image} alt={crypto.name} className="h-6 w-6 sm:h-8 sm:w-8 mr-2" />
                  {crypto.name}
                </td>
                <td className="p-2 hidden md:table-cell">{crypto.name}</td>
                <td className="p-2">{formatPrice(crypto.current_price)}</td>
                <td className="p-2 hidden md:table-cell">
                  <p className={crypto.price_change_percentage_24h >= 0 ? 'text-green-500' : 'text-red-500'}>
                    {crypto.price_change_percentage_24h.toFixed(2)}%
                  </p>
                </td>
                <td className="p-2 hidden md:table-cell">
                  <p className="text-white dark:text-white">{formatMarketCap(crypto.market_cap)}</p>
                </td>
                <td className="p-2">
                  <Link
                    to={`/dashboard/tables/${crypto.id}`}
                    className="bg-blue-500 text-white py-1 px-2 rounded text-xs sm:text-sm"
                  >
                    Buy
                  </Link>
                </td>
                <td className="p-2">
                  {watchlist.includes(crypto.id) ? (
                    <button
                      className="bg-red-500 text-white py-1 px-2 rounded text-xs sm:text-sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveFromWatchlist(crypto.id);
                      }}
                    >
                      Remove
                    </button>
                  ) : (
                    <button
                      className="bg-blue-500 text-white py-1 px-2 rounded text-xs sm:text-sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddToWatchlist(crypto.id);
                      }}
                    >
                      Add
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center mt-4">
        {renderPagination()}
      </div>
    </div>
  );
};

export default TableTwo;


