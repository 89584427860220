import React from 'react';
import './Coins.css';

const CoinItem = ({ coin }) => {
  // Check if all properties of coin are defined
  if (!coin || typeof coin.market_cap_rank === 'undefined' || typeof coin.image === 'undefined' || typeof coin.symbol === 'undefined' || typeof coin.current_price === 'undefined' || typeof coin.price_change_percentage_24h === 'undefined' || typeof coin.total_volume === 'undefined' || typeof coin.market_cap === 'undefined') {
    return null; // or return some fallback UI
  }

  return (
    <div className='coin-row'>
      <p>{coin.market_cap_rank}</p>
      <div className='img-symbol'>
        <img src={coin.image} alt='' />
        <p>{coin.symbol.toUpperCase()}</p>
      </div>
      <p>${coin.current_price.toLocaleString()}</p>
      <p>{coin.price_change_percentage_24h.toFixed(2)}%</p>
      <p className='hide-mobile'>${coin.total_volume.toLocaleString()}</p>
      <p className='hide-mobile'>${coin.market_cap.toLocaleString()}</p>
    </div>
  );
};

export default CoinItem;

