import React from "react";
import styled from "styled-components";

export default function Button({ text, icon = false, subduedButton = false, onClick }) {
  return (
    <ButtonContainer>
      <button className={`${subduedButton ? "subduedBtn" : ""}`} onClick={onClick}>
        {text}
        {icon}
      </button>
    </ButtonContainer>
  );
}

const ButtonContainer = styled.div`
  display: inline-block; /* Ensures buttons are displayed in a row */
  margin-right: 1rem; /* Adjust margin between buttons as needed */
  
  button {
    padding: 0.7rem 1.3rem;
    background-color: var(--blue);
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    font-weight: bolder;
    cursor: pointer;
  }

  .subduedBtn {
    background-color: var(--subdue-button);
  }
`;

