import React, { useState } from 'react';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import DefaultLayout from '../layout/DefaultLayout';
import { useAuth } from '../../../Context/AuthContext.js';

const Settings = () => {
  const { currentUser } = useAuth();
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [defaultCurrency, setDefaultCurrency] = useState('USD');
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    try {
      const newEmail = formData.get('newEmail')?.toString() || '';
      const is2FAEnabled = formData.get('2faEnabled') === 'on';
      const selectedCurrency = formData.get('defaultCurrency')?.toString() || 'USD';

      // Password Update Logic
      if (isChangingPassword) {
        const { currentPassword, newPassword, confirmPassword } = passwordData;

        if (newPassword !== confirmPassword) {
          throw new Error('Passwords do not match');
        }

        if (currentPassword && newPassword && currentPassword === newPassword) {
          throw new Error('New password must be different from the current password');
        }

        // Add your password update logic here (e.g., Firebase API call)
        console.log('Password updated');
      }

      // Email Update Logic
      if (newEmail !== currentUser.email) {
        throw new Error('Email update not implemented');
      }

      // Enable/Disable 2FA Logic
      console.log('2FA Enabled:', is2FAEnabled);

      // Update Default Currency Logic
      console.log('Selected Currency:', selectedCurrency);

      setSuccess('Settings updated successfully');
    } catch (error: any) {
      setError(error.message.toString());
    }
  };

  const handlePasswordChange = () => {
    setIsChangingPassword((prev) => !prev);
    setPasswordData({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
  };

  const handlePasswordInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPasswordData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (!currentUser) {
    return (
      <DefaultLayout>
        <div className="mx-auto max-w-270">
          <Breadcrumb pageName="Settings" />
          <div className="flex justify-center items-center h-full">
            <div>Loading...</div>
          </div>
        </div>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <div className="mx-auto max-w-270">
        <Breadcrumb pageName="Settings" />

        <div className="grid grid-cols-5 gap-8">
          <div className="col-span-5 xl:col-span-3">
            <div className="rounded-sm border border-stroke shadow-md bg-white dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                <h3 className="font-medium text-gray-800 dark:text-white">Settings</h3>
              </div>
              <div className="p-7">
                <form onSubmit={handleSubmit} className="space-y-6">
                  {/* Password Change */}
                  <div className="flex items-center justify-between">
                    <h4 className="text-lg font-semibold text-gray-800 dark:text-white">Change Password</h4>
                    <button
                      type="button"
                      className="text-blue-500 hover:text-blue-700"
                      onClick={handlePasswordChange}
                    >
                      {isChangingPassword ? 'Cancel' : 'Change Password'}
                    </button>
                  </div>
                  {isChangingPassword && (
                    <>
                      <div>
                        <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700">
                          Current Password
                        </label>
                        <input
                          type="password"
                          id="currentPassword"
                          name="currentPassword"
                          placeholder="Enter your current password"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:ring focus:ring-primary focus:outline-none"
                          required
                          value={passwordData.currentPassword}
                          onChange={handlePasswordInputChange}
                        />
                      </div>

                      <div>
                        <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                          New Password
                        </label>
                        <input
                          type="password"
                          id="newPassword"
                          name="newPassword"
                          placeholder="Enter your new password"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:ring focus:ring-primary focus:outline-none"
                          required
                          value={passwordData.newPassword}
                          onChange={handlePasswordInputChange}
                        />
                      </div>

                      <div>
                        <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                          Confirm New Password
                        </label>
                        <input
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                          placeholder="Confirm your new password"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:ring focus:ring-primary focus:outline-none"
                          required
                          value={passwordData.confirmPassword}
                          onChange={handlePasswordInputChange}
                        />
                      </div>
                    </>
                  )}

                  {/* Email Change */}
                  <div>
                    <label htmlFor="newEmail" className="block text-sm font-medium text-gray-700">
                      New Email Address
                    </label>
                    <input
                      type="email"
                      id="newEmail"
                      name="newEmail"
                      placeholder="Enter your new email address"
                      defaultValue={currentUser.email || ''}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:ring focus:ring-primary focus:outline-none"
                      style={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                      required
                    />
                  </div>

                  {/* Two Factor Authentication */}
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="2faEnabled"
                      name="2faEnabled"
                      className="rounded border-gray-300 text-primary focus:ring-primary focus:outline-none focus:ring-2"
                    />
                    <label htmlFor="2faEnabled" className="ml-2 block text-sm text-gray-700">
                      Enable Two Factor Authentication
                    </label>
                  </div>

                  {/* Default Currency Selection */}
                  <div>
                    <label htmlFor="defaultCurrency" className="block text-sm font-medium text-gray-700">
                      Default Currency
                    </label>
                    <select
                      id="defaultCurrency"
                      name="defaultCurrency"
                      value={defaultCurrency}
                      onChange={(e) => setDefaultCurrency(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:ring focus:ring-primary focus:outline-none"
                      style={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                      required
                    >
                      <option value="USD">USD</option>
                      <option value="EUR">EUR</option>
                      <option value="JPY">JPY</option>
                      <option value="GBP">GBP</option>
                      <option value="AUD">AUD</option>
                      <option value="CAD">CAD</option>
                      <option value="CHF">CHF</option>
                      <option value="CNY">CNY</option>
                      <option value="KRW">KRW</option>
                      <option value="BTC">BTC</option>
                      <option value="ZAR">ZAR</option> {/* South African Rand */}
                    </select>
                  </div>

                  <div className="flex justify-end gap-4.5 mt-6">
                    <button
                      type="button"
                      className="px-4 py-2 border rounded-md text-gray-700 bg-white shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                      onClick={() => {
                        // Reset form fields or cancel action
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 border rounded-md text-gray-700 bg-white shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      Save
                    </button>
                  </div>

                  {success && (
                    <div className="text-green-600 mt-4">{success}</div>
                  )}
                  {error && (
                    <div className="text-red-600 mt-4">{error}</div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Settings;
