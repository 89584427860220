import { getFirestore, collection, addDoc } from 'firebase/firestore'; 

const db = getFirestore();

export const sendFeedbackEmail = async (data: { name: string; email: string; feedback: string }) => {
  await addDoc(collection(db, 'feedbacks'), {
    ...data,
    to: 'Feedback@laani.io',
  });
};

export const sendSupportEmail = async (data: { name: string; email: string; subject: string; message: string }) => {
  await addDoc(collection(db, 'supports'), {
    ...data,
    to: 'Support@laani.io',
  });
};
