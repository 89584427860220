import React from 'react';

const ErrorMessage = ({ message }) => {
  return (
    <div className="flex justify-center items-center h-full">
      <p className="text-red-500">{message}</p>
    </div>
  );
};

export default ErrorMessage;
