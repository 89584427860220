import React from 'react';
import Accordion from './Accordion';
import Heading from '../../ui/PageUI/Heading';
import Subheading from '../../ui/PageUI/SubHeading';

const faqsData = [
  {
    question: 'What is cryptocurrency?',
    answer: 'Cryptocurrency is a digital or virtual currency that uses cryptography for security and operates independently of a central bank. Examples include Bitcoin, Ethereum, and Litecoin.',
  },
  {
    question: 'How do I buy cryptocurrency?',
    answer: 'You can buy cryptocurrency from various online platforms called exchanges. You typically need to create an account, verify your identity, and then deposit funds to make a purchase.',
  },
  {
    question: 'Is cryptocurrency safe?',
    answer: 'Cryptocurrency can be safe if you take proper precautions. This includes using secure exchanges, enabling two-factor authentication, and storing your coins in a secure wallet.',
  },
  {
    question: 'What is a crypto wallet?',
    answer: 'A crypto wallet is a digital tool that allows you to store and manage your cryptocurrencies. Wallets can be software-based (online, desktop, or mobile) or hardware-based (physical devices).',
  },
  {
    question: 'How do I secure my crypto wallet?',
    answer: 'Secure your crypto wallet by using strong passwords, enabling two-factor authentication, and considering hardware wallets for long-term storage.',
  },
  {
    question: 'What is two-factor authentication (2FA)?',
    answer: 'Two-factor authentication (2FA) is an extra layer of security used to ensure that people trying to gain access to an online account are who they say they are. First, a user will enter their username and a password. Then, they will be required to provide another piece of information.',
  },
  {
    question: 'What is blockchain technology?',
    answer: 'Blockchain technology is a decentralized digital ledger that records transactions across many computers in such a way that the registered transactions cannot be altered retroactively.',
  },
  {
    question: 'Can I transfer cryptocurrency to others?',
    answer: 'Yes, you can transfer cryptocurrency to others using their wallet address. Ensure you have the correct address to avoid losing your funds.',
  },
  {
    question: 'What is a public key and a private key?',
    answer: 'A public key is a cryptographic code that allows a user to receive cryptocurrencies into their account. A private key is a cryptographic code that allows the user to access and manage their cryptocurrency funds.',
  },
  {
    question: 'What are transaction fees?',
    answer: 'Transaction fees are small amounts paid to the network (miners or validators) for processing a crypto transaction. The fees vary based on the network and current demand.',
  },
  {
    question: 'What is the difference between Bitcoin and Ethereum?',
    answer: 'Bitcoin is a cryptocurrency primarily used as a store of value and a medium of exchange. Ethereum is a blockchain platform that allows developers to build decentralized applications (dApps) and execute smart contracts.',
  },
  {
    question: 'How do I choose a cryptocurrency exchange?',
    answer: 'When choosing a cryptocurrency exchange, consider factors such as security, fees, available cryptocurrencies, ease of use, and customer support.',
  },
  {
    question: 'What is a smart contract?',
    answer: 'A smart contract is a self-executing contract with the terms of the agreement directly written into code. Smart contracts run on blockchain networks like Ethereum.',
  },
  {
    question: 'What is a decentralized exchange (DEX)?',
    answer: 'A decentralized exchange (DEX) is a type of cryptocurrency exchange that operates without a central authority, allowing users to trade directly with each other peer-to-peer.',
  },
  {
    question: 'How do I withdraw cryptocurrency to my bank account?',
    answer: 'To withdraw cryptocurrency to your bank account, you typically need to sell your crypto for a fiat currency (like USD or EUR) on an exchange and then transfer the funds to your bank account.',
  },
  {
    question: 'What is staking in cryptocurrency?',
    answer: 'Staking is the process of holding funds in a cryptocurrency wallet to support the operations of a blockchain network. Users can earn rewards for staking their coins.',
  },
  {
    question: 'What are the risks of investing in cryptocurrency?',
    answer: 'The risks of investing in cryptocurrency include market volatility, regulatory changes, cybersecurity threats, and the potential for loss of funds.',
  },
  {
    question: 'Can I lose my cryptocurrency?',
    answer: 'Yes, you can lose your cryptocurrency if you lose access to your private keys, fall victim to a scam, or if the exchange or wallet you use is hacked.',
  },
  {
    question: 'How do I keep track of my cryptocurrency investments?',
    answer: 'You can keep track of your cryptocurrency investments using portfolio tracking apps, spreadsheets, or the tools provided by the exchange you use.',
  },
  {
    question: 'What is an Initial Coin Offering (ICO)?',
    answer: 'An Initial Coin Offering (ICO) is a fundraising method used by new cryptocurrency projects to raise capital by selling a portion of their cryptocurrency tokens to early investors.',
  },
  {
    question: 'What is a stablecoin?',
    answer: 'A stablecoin is a type of cryptocurrency that is pegged to a stable asset, such as a fiat currency or a commodity, to reduce volatility and maintain a stable value.',
  },
  {
    question: 'What is DeFi (Decentralized Finance)?',
    answer: 'DeFi (Decentralized Finance) refers to a movement that aims to create an open, permissionless, and decentralized financial ecosystem using blockchain technology and smart contracts.',
  },
  {
    question: 'How do I participate in crypto airdrops?',
    answer: 'To participate in crypto airdrops, you usually need to hold a specific cryptocurrency or complete certain tasks. The project team then distributes free tokens to eligible participants.',
  },
  {
    question: 'What is mining in cryptocurrency?',
    answer: 'Mining is the process of using computational power to verify and add new transactions to a blockchain. Miners are rewarded with new cryptocurrency coins for their efforts.',
  },
  {
    question: 'How do I report cryptocurrency on my taxes?',
    answer: 'Reporting cryptocurrency on your taxes varies by country. Generally, you need to report any capital gains or losses from selling, trading, or using cryptocurrency. Consult a tax professional for specific guidance.',
  },
];

const FAQs = () => {
  return (
    <div className="container mx-auto p-8 text-center">
      <Heading text="Frequently Asked Questions (FAQs)" />
      <Subheading text="Answers to common questions about Laani and cryptocurrency trading" />
      <Accordion data={faqsData} />
    </div>
  );
};

export default FAQs;




