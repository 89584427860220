import React from 'react';

const TermsOfUse = () => {
  return (
    <div className="min-h-screen text-gray-900">
      <header className="bg-blue-600 text-white py-4">
        <div className="container mx-auto text-center">
          <h1 className="text-2xl font-bold">Terms of Use - Laani Crypto Exchange</h1>
        </div>
      </header>
      <main className="container mx-auto p-6">
        <section className="text-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-white file:text-xl font-semibold mb-4">Introduction</h2>
          <p>
          These Terms constitute a legally binding agreement between you (“you” or “your”) and Laani Crypto Exchange (“Laani”, “we”, “our” or “us”). The Terms govern your use of the Laani Services made available to you on or through the Platform or otherwise. Laani Services may be provided by Laani or, if specified in these Terms, any Product Terms or any additional terms, by any Laani Affiliate.
          By registering for a Laani Account, accessing the Platform and/or using the Laani Services, you agree that you have read, understood, and accepted these Terms, together with any additional documents or terms referred to in these Terms. You acknowledge and agree that you will be bound by and will comply with these Terms, as updated and amended from time to time.
          If you do not understand and accept these Terms in their entirety, you should not register for a Laani Account or access or use the Platform or any Laani Service.
        RISK WARNING    
        As with any asset, the value of Digital Assets can fluctuate significantly, and there is a material risk of economic loss when buying, selling, holding, or investing in Digital Assets. You should, therefore, consider whether trading or holding Digital Assets is suitable for you in light of your financial circumstances.
        Further information on the risks associated with using the Laani Services is set out in our Risk Warning, which may be updated from time to time. You should read the Risk Warning carefully; however, it does not explain all of the risks that may arise, or how such risks relate to your personal circumstances.
        It is important that you fully understand the risks involved before making a decision to use the Laani Services.
        We are not your broker, intermediary, agent, or advisor and we have no fiduciary relationship or obligation to you in connection with any Transactions or other activities you undertake when using the Laani Services. We do not provide investment or consulting advice of any kind and no communication or information that we provide to you is intended as, or should be construed as, advice of any kind.
        It is your responsibility to determine whether any investment, investment strategy, or related transaction is appropriate for you according to your personal investment objectives, financial circumstances, and risk tolerance, and you are responsible for any associated loss or liability. We do not recommend that any Digital Asset should be bought, earned, sold, or held by you. Before making the decision to buy, sell, or hold any Digital Asset, you should conduct your own due diligence and consult your financial advisor. We are not responsible for the decisions you make to buy, earn, sell, or hold Digital Assets based on the information provided by us, including any losses you incur arising from those decisions.
          </p>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">1. Introduction</h2>
          <p>
          Laani Crypto Exchange is a centralized exchange based in South Africa. We provide users with a platform to buy and sell Digital Assets, an integrated custody solution allowing users to store their Digital Assets, and other Digital Asset-related services.          </p>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">1.2. These Terms</h2>
          <p>
          By registering to open a Laani Account, you are entering into a legally binding agreement with us. These Terms will govern your use of the Laani Services and tell you who we are, how we will provide the Laani Services to you, how these Terms may be changed or terminated, what to do if there is a problem, along with other important information.
          You must read these Terms, together with the documents referenced in the Terms, carefully and let us know if you do not understand anything.
          Where any Local Terms apply to your use of the Laani Services, such Local Terms shall govern your use of the Laani Services.
          </p>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">User Responsibilities</h2>
          <ul className="list-disc list-inside">
            <li><strong>Eligibility:</strong> You must be at least [minimum age] years old to use our Site and services.</li>
            <li><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
            <li><strong>Prohibited Activities:</strong> You agree not to engage in any activities that may harm our Site, interfere with others' use of the Site, or violate any applicable laws.</li>
          </ul>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">1.3. Additional Documents</h2>
          <p>
          These Terms refer to a number of additional documents which also apply to your use of the Laani Services. This includes:
•	Our Privacy Notice, which sets out the terms on which we process any personal data we collect about you, or that you provide to us. By using the Laani Services, you understand and agree to such processing and you promise that all data provided by you is accurate and up to date.
•	Our Risk Warning, which sets out important information on the risks that can arise when buying, selling, holding, or investing in Digital Assets.
•	The Fee Structure page on our Website.
•	Product Terms, which set out additional terms and conditions that will apply to your use of specific Laani Services.
You acknowledge that you will be bound by, and agree that you will comply with, any relevant additional terms and conditions that apply to your use of the Laani Services.
          </p>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">2. Eligibility</h2>
          <h2 className="text-xl font-semibold mb-4">2.1. Eligibility Criteriay</h2>

          <p>
          To be eligible to register for a Laani Account and use the Laani Services, you must:
•	Be an individual, corporation, legal person, entity, or other organization with the full power, authority, and capacity to (1) access and use the Laani Services; and (2) enter into and comply with your obligations under these Terms;
•	If you are an individual, be at least 18 years old;
•	If you act as an employee or agent of a legal entity, and enter into these Terms on their behalf, you must be duly authorized to act on behalf of and bind such legal entity for the purposes of entering into these Terms;
•	Not have been previously suspended or removed from using Laani Services;
•	Not be a Restricted Person;
•	Not currently have an existing Laani Account; and
•	Not be located, incorporated, otherwise established in, or resident of, or have business operations in:
o	A jurisdiction where it would be illegal under Applicable Law for you to access or use the Laani Services, or cause us or any third party to contravene any Applicable Law; or
o	A country listed in our List of Prohibited Countries.
          </p>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">2.2. Amending Our Eligibility Criteria</h2>
          <p>
          We may amend our eligibility criteria at any time in our sole discretion. Where possible, we will give you notice in advance of the change. However, we may occasionally need to make changes without telling you in advance. This may include where:
•	We are making the change as a result of legal and/or regulatory changes;
•	The changes being made are in your interest; and/or
•	There is any other valid reason which means there is no time to give you notice.
Where we are unable to give you advance notice, we will let you know of the change as soon as possible after it is made.
          </p>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">3.	How We Contact Each Other</h2>
          <p>
          3.1. How you can contact us. For more information on Laani Crypto Exchange, you may refer to the information found on our Website. If you have questions, feedback, or complaints, you can contact us via our Customer Support team at https://www.laani.com/support. These Terms may specify contact details for particular notices. This address is not monitored for those notices.
3.2. How we will contact you. We will contact you using the details you provide to us. This may include contacting you by email, SMS, or telephone. It is important that you ensure that your contact details are correct and up to date. If your contact details change, you must let us know immediately. If you do not, we will not be responsible if you do not receive information, notices, or other important information from us.
          </p>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">4.	Laani Services</h2>
          <p>
          4.1. Specific product terms. Once you have opened a Laani Account, you will be able to use the Laani Services in accordance with these Terms and the Product Terms that govern your use of each specific Laani Service.
4.2. Intra-group services. You acknowledge and agree that some of the Laani Services may be provided by Laani Affiliates.
          </p>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">6.	Fees and Calculations</h2>
          <p>
          6.1. Payment of fees. You agree to pay all applicable fees in connection with your use of the Laani Services as set out on the Fee Structure page on our Website or otherwise communicated to you in any relevant Product Terms.
You authorise us to deduct all applicable fees, commissions, interest, charges, and other sums that you owe from your Laani Account under these Terms or any Product Terms in accordance with the method of calculation set out on our Fee Structure page. If you owe us an amount in one Digital Asset and do not have sufficient assets in that Digital Asset, we may deduct the sums owed in another Digital Asset to effect payment (in which case we will convert the Digital Asset you hold into the Digital Asset in which the sums owed to us are denominated (or the Fiat Currency equivalent), at the rate currently offered on the Platform or at such other commercially reasonable rate as we may determine). In the event that there are insufficient Digital Assets in your Laani Account, you acknowledge that any amount due and payable from you under this clause is a debt immediately due and owing by you to us in such amount and form (whether in the form of a Digital Asset or otherwise) as we may determine, acting in a commercially reasonable manner.
6.2. Amending our fees. We may adjust our fees from time to time in accordance with clause 18.3 of these Terms.
6.3. Calculations: Any calculations made by Laani in connection with the Laani Services are final and binding on you in the absence of Manifest Error. Calculations will be made in accordance with the stated methodology for the relevant Laani Service in our good faith discretion.
          </p>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">INFORMATION ABOUT YOUR LAANI ACCOUNT</h2>
          <h2 className="text-xl font-semibold mb-4">7.	Creating a Laani Account</h2>

          <p>
          7.1. Account opening. You must create and maintain a Laani Account in order to access the Laani Services and the Platform. This may be a Laani Account for an individual user or a Corporate Laani Account where the user is a corporation, entity, or other organisation.
All Laani Accounts are provided at our absolute discretion. We reserve the right to refuse any application for a Laani Account without reason or to limit the number of Laani Accounts that you may hold.
7.2. Sole benefit. By opening a Laani Account you agree that:
a. where you are an individual user, you will use your Laani Account only for yourself, and not on behalf of any third party, unless you have obtained our prior written consent to do so; and
b. where you are a corporate user, your Permitted Users will use the Corporate Laani Account only for your benefit, and not on behalf of any third party, unless our prior written consent has been obtained. Additionally, you will not offer direct market access to the Platform to any other party, including through the use of a sub-account, unless and until such other party has completed identity verification deemed appropriate by Laani and has separately onboarded onto the Platform. Any other party so verified shall be considered a “Permitted User” as that term is described herein. For avoidance of doubt, any entity or individual using a Laani Sub-Account must be separately verified to be considered a “Permitted User” and to be permitted to use the Laani Services.
You are fully responsible for all activity that occurs under your Laani Account.
7.3. Identity verification. You will need to comply with our identity verification procedures before you are permitted to open a Laani Account and access and use the Laani Services, whether independently or through a third-party service, by providing us with certain information about yourself and, where relevant, all of your Permitted Users. All information that you provide must be complete, accurate, and truthful. You must update this information whenever it changes.
You authorise us to make inquiries, whether directly or through third parties, that we consider necessary to verify your identity, and that of any Permitted Users, or protect you and/or us against fraud, money laundering, terrorist financing, or other financial crime, and to take any action we deem necessary based on the results of such inquiries.
When we carry out inquiries, you acknowledge and understand that your personal data, and that of any Permitted Users, may be disclosed to identity verification, compliance data recordation, credit reference, fraud prevention, or financial crime agencies and that these agencies may respond to our inquiries in full.
You can review our Privacy Notice to have more information about how we process your personal data.
7.4. Enhanced due diligence. We may also require you to comply with our enhanced due diligence procedures by submitting additional information about yourself, your business, or your Permitted Users, providing additional records or documentation, or having face-to-face meetings with representatives of Laani.
7.5. Records. We keep your personal data to enable your continued use of Laani Services, for as long as it is required in order to fulfil the relevant purposes described in this Privacy Notice, and as may be required by law such as for tax and accounting purposes, compliance with anti-money laundering laws, or as otherwise communicated to you. Please review our Privacy Notice for more information on how we collect and use your personal data relating to the use and performance of our Sites and the Laani Services.
7.6. Sub-Accounts. At our discretion, you may create and access a Laani Sub-Account. Each natural person associated with a Laani Sub-Account is subject to the identity verification requirements set out in this clause ‎7. Only one natural person or corporate entity may be associated with a particular Laani Sub-Account.
          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">8. Information Requests</h2>
          <p>
          8.1. When We May Request Information
We may require information from you at any time for the purposes of complying with any Applicable Law, identity verification requirements, or in connection with the detection of money laundering, terrorist financing, fraud, or any other financial crime, or for any other valid reason. You agree to provide us with any such information we request and permit us to keep a record of the information for the lifetime of your Laani Account as long as it is required to fulfill its intended purposes, or such other period as prescribed by Applicable Law.
8.2. What Happens When You Provide Information
Your access to your Laani Account and the Transaction limits that apply to your use of the Laani Services may be altered as a result of information collected about you on an ongoing basis. If there is a reasonable suspicion that any information provided by you is wrong, untruthful, outdated, or incomplete, we may send you a notice to request corrections, remove relevant information, or take other necessary actions to ensure that the information provided by you is true and correct.
8.3. If You Fail to Provide Any Requested Information
You must comply with any information request we send to you. If you decline to provide the requested information or otherwise do not comply in a timely manner, we reserve the right to suspend or terminate your access to your Laani Account, or to all or part of the Laani Services immediately, without notice.
          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">9. Accessing Your Laani Account</h2>
          <p>
          9.1. Access
To access your Laani Account, you or, where relevant, your Permitted Users, must have the necessary equipment (such as a computer or smartphone) and access to the internet. Your Laani Account can be accessed directly using the Platform or by such other mode of access (including APIs) as we may prescribe. You are only permitted to access your Laani Account by using the Access IDs we provide to you or your Permitted Users for such purposes. We may require multi-factor authentication to keep your Laani Account safe and secure. The use of the Platform and other access methods may be subject to such additional terms as we communicate to you.
9.2. Restricting Access to Third Parties
You must ensure that any Laani Account(s) registered under your name will not be used by any person other than yourself or, with respect to Corporate Laani Accounts, your Permitted Users, other than in accordance with these Terms.
          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">10. Account Information and Transaction Records</h2>
          <p>
          10.1. Your Account History
You will be able to access your Account History on the Platform. You must review your Account History carefully and notify us if you see any entries or Transactions that you do not recognize or believe are incorrect within fourteen (14) calendar days of the date that your Account History is provided or made available to you.
10.2. Errors
We may rectify any error in your Account History at any time and reserve the right to void, cancel, or reverse any Transaction involving or deriving from a Manifest Error or to amend the details of such Transaction to reflect what we reasonably consider to be the correct or fair details of such a Transaction.
You acknowledge and agree that where you execute any Transaction with Improper Intent and/or in the case of Manifest Error, Laani is authorized by you (without any payment or penalty or liability due by Laani and provided that such action is in compliance with Applicable Law) to either:
a. Cancel/void such Transaction and treat such Transaction as if it had never been entered into; or
b. Amend the price(s) of such Transaction (upon notice from Laani to you of the amended price(s)) to reflect the prevailing market price(s) as at the relevant time, as determined by Laani by reference to an available source for fair market pricing.
If Laani cancels a Transaction pursuant to the rights referred herein, Laani will reverse any transfers of Digital Assets that have been made to and/or from your Laani Account in connection with such Transaction as if that Transaction had never taken place.
If Laani amends a Transaction pursuant to the rights referred herein, Laani will confirm the details of the amended terms of the Transaction to you through an agreed communication channel and will effect such transfers of Digital Assets to and/or from your Laani Account as are required to reflect the terms of the amended Transaction.
You acknowledge and agree that you shall be solely responsible for your own transactions with any third parties that may have been entered into in connection with or reliance on any Transaction(s) that may be subject to any cancellation/amendment pursuant to Laani’s rights herein.
10.3. Information Sharing
We may be required under these Terms or Applicable Law to share information about your Laani Account and Account History with third parties and Laani Affiliates. You acknowledge and agree that we are entitled to disclose such information. For more information about how we process your personal data you can review our Privacy Notice.
          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">11. Using Your Account</h2>
          <p>
          11.1. Giving Instructions
You must ensure that any Instruction submitted is complete and accurate. We are not required to verify the accuracy, authenticity, or validity of any Instruction and will not monitor or reject Instructions on the basis that they are, or appear to be, duplicates. However, if we are in doubt as to the accuracy, authenticity, or validity of an Instruction, we may refuse to act upon or defer acting upon any Instruction, or seek further information with respect to the Instruction.
Instructions are irrevocable and therefore once an Instruction has been submitted, you, or your Permitted Users, have no right to rescind or withdraw it without our written consent. Your Instruction is not deemed to be received by us until it has been received by our server. Our record of all Instructions will be conclusive and binding on you for all purposes.
11.2. Acting on Your Instructions
By submitting an Instruction, you or your Permitted Users are authorizing us to initiate the Transaction on your Laani Account. We are therefore authorized to credit or debit (or provide settlement information to third parties for the purposes of the third party crediting or debiting) your Digital Assets from your Laani Account in accordance with your Instruction. If you have insufficient Digital Assets or Fiat Currency in your Laani Account to effect the Transaction (i.e., less than the required amount to settle the Transaction and to pay all the fees associated with the Transaction), then we have the right to refuse to effect any Transaction. Laani may also refuse to act on instructions to the extent permitted by these Terms. It is your responsibility to hold sufficient Digital Assets or Fiat Currency credited in your Laani Account.
11.3. Protection of Instructions
You are aware that Instructions and information transmitted on the Platform or by email are generally transmitted via the internet and may be routed via public, transnational installations which are not specifically protected. We cannot guarantee that the Instructions and information transmitted will be completely protected against unauthorized access, and you accept the associated risks.
11.4. Withdrawals
Subject to these Terms and any applicable Product Terms, and provided that you have sufficient balance on your Laani Account and the relevant Digital Assets are not on hold in your Laani Account in connection with any Laani Service, you may give Instructions to Laani to transfer Digital Assets to an external wallet address by submitting a withdrawal request on the Platform. Upon receipt of the withdrawal request, Laani will: (i) deduct your Laani Account balance; and (ii) initiate an on-chain transfer to an external wallet designated by you. Laani may not process a withdrawal request if, in our reasonable opinion, we consider that Applicable Law prevents the execution of the relevant withdrawal. Laani may also suspend withdrawals at such times deemed appropriate by Laani to resolve any incidents on the Platform. Once such incidents have been resolved, Laani will resume withdrawals.
          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">12. Transactions</h2>
          <p>
          12.1. Entering into Transactions. You may enter into Transactions directly with us or directly with other users, which may or may not be facilitated by us.
We do not represent or warrant that any Transaction will be completed successfully or within a specific time period.
12.2. Unauthorized Transactions. You are responsible for the control and use of your Laani Account. As such, we will assume that you, or a Permitted User, have authorized any Instruction sent from your Laani Account unless we are notified otherwise. It is important that you monitor your Account History to ensure any unauthorized or suspicious activity on your Laani Account is identified and notified to us as soon as possible. We are not responsible for any claim or losses resulting from a Transaction executed as a result of an unauthorized Instruction unless you have notified us in accordance with this clause and it is confirmed by our internal investigation that you, or a Permitted User, have not authorized the Instruction in any way, even by mistake, negligence, error, or as a consequence of fraud, and it is proven that the unauthorized Instruction is solely due to a technical issue attributable to Laani.
12.3. Retention of Transaction Information. To facilitate compliance with global industry standards for data retention, you agree to permit us (but agree not to require us) to keep a record of all Transaction information for the lifetime of your Laani Account as long as it is required to fulfill their intended purposes, or such other period as prescribed by Applicable Law. Please review our Privacy Notice for more information on how we collect and use data relating to the use and performance of our Sites and the Laani Services.
          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">13. Material Interests and Conflicts</h2>
          <p>
          13.1. Laani Group. You understand that Laani is a member of a group of companies involved in activities connected with Digital Assets.
13.2. Nature of Our Duties. You understand and agree that neither your relationship with us nor any Laani Service we provide to you, nor any other matter, will give rise to any duties on our part or on the part of any Laani Affiliate, whether legal, equitable, or fiduciary in nature, save as are expressly set out in these terms. In particular, we and any Laani Affiliate may from time to time act in more than one capacity, and in those capacities, we may receive fees or commissions from more than one user (including you). You agree that we may act in such capacities and provide any other Laani Services or carry out any business with or for you, any Laani Affiliate, or any other user.
13.3. Material Interests. You understand and agree that neither we nor any Laani Affiliate will be required to: (1) have regard to any information known to us, or to any Laani Affiliate, which is a material interest; (2) disclose any such information to you; or (3) use any such information for your benefit. You further acknowledge that from time to time we may receive general market information in the course of providing Laani Services to you, which we may use in the ordinary course of our business.
13.4. Conflicts of Interest. We have established and maintain effective organizational and administrative arrangements with a view to taking all appropriate steps to identify and manage conflicts of interest between us and our users and relevant third parties, so as to prevent conflicts of interest from adversely affecting the interests of our users. In cases where such organizational and administrative arrangements are not sufficient to ensure that the risks of damage to your interests will be prevented, we will inform you of the nature and/or sources of the relevant conflicts of interest and the steps taken to mitigate those risks in order to allow you to make an informed decision as to whether to continue to transact with us. We reserve the right at all times to decline to act for you where we are not able to manage a conflict of interest in any other way.
          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">14. Transaction Limits</h2>
          <p>
          14.1. Your Transaction Limits. Your Laani Account may be subject to a limit on:
a. the amount or volume of Transactions you may undertake in connection with your Laani Account; and/or
b. the amount or value of Fiat Currency or Digital Assets you may transfer into or out of your Laani Account,
in each case in a given period (e.g., daily). Any applicable limits are shown in your Laani Account.
14.2. Changes to Your Transaction Limits. We reserve the right to change any Transaction limit that applies to your Laani Account at any time at our absolute discretion. It may also be possible for you to request a change in your limits. Any change will be made in our absolute discretion and will be subject to any further conditions that we deem necessary.
          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">15. Supported Digital Assets</h2>
          <p>
          15.1. Supported Digital Assets. The Laani Services are only available in connection with Supported Digital Assets, which may change from time to time. A list of the Supported Digital Assets is published on our Website. We may remove or suspend one or more Digital Assets from the list of Supported Digital Assets, and we will use reasonable commercial efforts to notify you in advance, meaning that you will no longer be able to access such Digital Assets as part of the Laani Services and will only be permitted to withdraw the Digital Assets from your Laani Account. If Digital Assets that are no longer Supported Digital Assets remain in your Laani Account beyond a specified period notified to you, Laani may in its reasonable discretion convert such Digital Assets into a different type of Digital Asset that is a stablecoin. Laani shall notify you in advance of any conversion and you may withdraw such Digital Assets within a reasonable period as specified by Laani to you, prior to their conversion into stablecoins.
We assume no liability in connection with any attempt to use your Laani Account for Digital Assets that we do not support nor for the conversion to a different type of Digital Asset, as described in this clause. In addition, we assume no liability or obligation whatsoever with regard to unsupported Digital Assets sent to a Laani Account or with regard to Supported Digital Assets sent to an incompatible Digital Asset wallet address. If you send unsupported Digital Assets to a Laani Account or Supported Digital Assets to an incompatible Digital Asset wallet address, then you will lose those Digital Assets. For some lost Digital Assets, Laani may in its sole discretion offer you the option to attempt a recovery. We may charge fees to process the recovery attempt on your behalf. We will calculate all fees at our discretion, and notify you of the applicable fees at or before the time you authorize the recovery attempt. Laani does not guarantee in any way the amount of assets (if any) that may be recovered in a recovery attempt. The actual amount recovered may differ from the estimated recovery amount. Laani does not evaluate or provide any assurance as to the authenticity, safety, or security of unsupported Digital Assets. You acknowledge and agree that Laani is not liable for any loss incurred during the recovery attempt or subsequent use of the recovered Digital Asset.
15.2. Forks. We may temporarily suspend any Laani Services in relation to a particular Digital Asset while we determine whether or not to support a Fork. We are under no obligation to support a Fork of a Digital Asset that you hold in your Laani Account, regardless of whether any resulting version of such Forked Digital Asset is a Dominant Digital Asset or not. If we elect to support a Fork of a Digital Asset, we will make a public announcement through our Website or by such other means as we may deem appropriate.
You acknowledge that we have no control over, nor do we have the ability to influence, the creation or implementation of a Fork. We can provide no assurances about the security, functionality, or supply of any Digital Asset, including both the new Dominant Digital Asset or other Digital Assets subject to the relevant Fork. You may not be able to trade the Forked Digital Assets on the Platform and you may lose any value associated with the relevant Digital Assets.
15.3. Forks and Airdrops. Laani makes no promises, guarantees, or warranties on the outcome of or support for potential or proposed Forks, Forked Digital Assets, or Airdrops. Laani may determine in its sole discretion whether to claim, list, or distribute any Airdrop, Forked Digital Asset, or any other Digital Asset, as well as the terms and conditions (including eligibility criteria) that will apply to any claim, listing, or distribution of any Airdrop or Forked Digital Asset. If you wish to participate in a Fork or Airdrop, please withdraw the affected Digital Asset to your own private wallet well ahead of the potential or proposed Fork or Airdrop.
15.4. Backed Digital Assets. We may from time to time support Digital Assets that purport to be backed by or otherwise tied or pegged in value to another asset, including without limitation Digital Assets, Fiat Currency, or commodities such as silver or gold (“Backed Digital Assets”). You acknowledge and agree that (a) you have read, understood, and accepted all of the terms and conditions and risks associated with each particular Backed Digital Asset before entering into any Transaction relating to that Backed Digital Asset and (b) Laani does not and will not in any circumstances have any obligation whatsoever to purchase, repurchase, or effect or facilitate the redemption of your Backed Digital Assets. We reserve the right to change, suspend, or discontinue any service in relation to any Backed Digital Asset at any time in our sole discretion. We make no representation as to whether any particular Backed Digital Asset will hold its value as against any asset, nor as to the amount or quality of reserves or collateral held by each issuer or any third party in relation to any Backed Digital Asset.
          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">16. Account Security</h2>
          <p>
          16.1. Your Responsibility. You are responsible for maintaining the security of your Laani Account and for all activities that occur under it. You must notify us immediately of any unauthorized use of your Laani Account or any other breach of security. We are not liable for any loss or damage arising from your failure to protect your Laani Account details or for any unauthorized use of your Laani Account.
16.2. Two-Factor Authentication. You agree to enable and use two-factor authentication (2FA) for your Laani Account, where available. We strongly recommend using 2FA as an additional layer of security for your Laani Account.
          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">17. Termination, Suspension, Holds, and Restrictions</h2>
          <p>
          17.1. Our Right
We reserve the right to modify or discontinue any portion or feature of the Laani Services at any time, either temporarily or permanently. Specifically, we may:
1.	Refuse to complete or block, cancel, or, where permitted by Applicable Law, reverse any Transaction you have authorized.
2.	Terminate, suspend, or restrict your access to any or all of the Laani Services.
3.	Terminate, suspend, close, hold, or restrict your access to any or all of your Laani Accounts.
4.	Refuse to transmit information or instructions to third parties, including but not limited to third-party wallet operators.
5.	Take any necessary action with immediate effect for any reason, including but not limited to:
a. If you are not, or are no longer, eligible to use one or more Laani Services.
b. If we reasonably suspect that:
i. The person logged into your Laani Account is not you, or we suspect the account is being used for illegal, fraudulent, or unauthorized purposes.
ii. The person logged into your Corporate Laani Account is not a Permitted User, or we suspect the Corporate Account is being used for illegal, fraudulent, or unauthorized purposes.
iii. More than one natural person has access to and/or transacts using the same           </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">20. Termination, Suspension, Holds, and Restrictions</h2>
          <p>
          20.1. Our Right
We reserve the right to modify or discontinue any portion or feature of the Laani Services at any time, either temporarily or permanently. Specifically, we may:
1.	Refuse to complete or block, cancel, or, where permitted by Applicable Law, reverse any Transaction you have authorized.
2.	Terminate, suspend, or restrict your access to any or all of the Laani Services.
3.	Terminate, suspend, close, hold, or restrict your access to any or all of your Laani Accounts.
4.	Refuse to transmit information or instructions to third parties, including but not limited to third-party wallet operators.
5.	Take any necessary action with immediate effect for any reason, including but not limited to:
a. If you are not, or are no longer, eligible to use one or more Laani Services.
b. If we reasonably suspect that:
i. The person logged into your Laani Account is not you, or we suspect the account is being used for illegal, fraudulent, or unauthorized purposes.
ii. The person logged into your Corporate Laani Account is not a Permitted User, or we suspect the Corporate Account is being used for illegal, fraudulent, or unauthorized purposes.
iii. More than one natural person has access to and/or transacts using the same Laani Account, or we suspect the account is being used for illegal, fraudulent, or unauthorized purposes.
iv. Information provided by you is incorrect, untruthful, outdated, or incomplete.
c. If we have reasonable concerns regarding your creditworthiness or financial status, including:
i. For individuals, if you become bankrupt, are of unsound mind, commit an act of bankruptcy, or have bankruptcy proceedings commenced against you.
ii. For partnerships, if any partners die, become bankrupt, or have bankruptcy proceedings commenced against them, or if the partnership is dissolved or altered.
iii. For corporations, if the corporation is unable to pay its debts as they become due, or if insolvency, judicial management, receivership, or similar proceedings are commenced.
iv. If you convene a creditors' meeting or propose or make any compromise or arrangement with your creditors.
d. Pending submission of information and documents as per clause 8.
e. Pending enhanced due diligence as per clause 7.4.
f. If required by Applicable Law, or any court or authority to which we are subject.
g. If we determine or suspect:
i. That you have breached these Terms or any Product Terms.
ii. That you have breached any warranties or representations made under these Terms.
iii. That any Transaction is unauthorized, erroneous, fraudulent, or unlawful, or if your account or the Services are being used fraudulently, unauthorizedly, or unlawfully.
iv. That there is money laundering, terrorist financing, fraud, or other crimes related to your account or use of the Services.
h. If your account is subject to any litigation, investigation, or regulatory proceedings and/or if we perceive a heightened risk of legal or regulatory non-compliance.
i. If you owe amounts to Laani that are unsatisfied, including chargebacks.
j. If any email communication to your email account is returned as undeliverable.
k. If there is an issue with verifying your identity.
l. If you have taken actions to circumvent our controls, such as opening multiple accounts without consent or abusing promotions.
m. Any other valid reason requiring action.
We will make reasonable efforts to provide you with notice. However, there may be instances where we are required not to provide such notice by Applicable Law.
          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">20.2. Your Acknowledgement</h2>
          <p>
          You acknowledge and agree that:
a. The examples provided in clause 20.1 of when we might take action to terminate, suspend, or restrict access to your account and/or Services are non-exhaustive.
b. Our decisions to take action, including terminating, suspending, or restricting your account, may be based on confidential criteria essential to our risk management and security protocols. We are not obligated to disclose details of our risk management and security procedures.
          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">20.3. What Happens When We Exercise Our Right</h2>
          <p>
          If we terminate, suspend, hold, or restrict access to any Laani Services:
a. Any open Instructions, trades, positions, or Transactions may be closed by you or us, depending on the circumstances.
b. Chargebacks may result in immediate suspension and/or restriction of your account and Services.
c. To reactivate a suspended or restricted account, you may need to reimburse us for the full value of the chargeback, including applicable fees.
d. You are liable for credited amounts in the case of a chargeback and authorize us to deduct costs and fees directly from your assets without notice.
          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">20.4. Unlawful Possession</h2>
          <p>
          If we are informed or reasonably believe that any Digital Assets or Fiat Currencies in your account are stolen or unlawfully possessed, we may place a hold on those funds or your account. The hold may continue until acceptable evidence proves your entitlement to the assets. We will not resolve disputes related to these assets.          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">20.5. Access to Services in Other Jurisdictions</h2>
          <p>
          Residents of some countries may have limited access to certain Laani Services. If you travel to a prohibited location, access may be blocked. You must not attempt to circumvent such restrictions, including using virtual private networks.          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Intellectual Property</h2>
          <p>
          21. Background IP
All Laani IP remains vested in Laani.
22. Licence of Laani IP
We grant you a non-exclusive licence for the duration of these Terms, or until your access is suspended or terminated, to use Laani IP (excluding Trade Marks) solely for non-commercial personal or internal business use in accordance with these Terms.
23. Licence of User IP
23.1. Your Grant of Licence
You grant us a perpetual, irrevocable, royalty-free, worldwide, and non-exclusive licence to use User IP:
a. To the extent it forms part of or is necessary for the use of Created IP.
b. To provide you with Laani Services.
23.2. Our Right to Sub-Licence
This licence includes the right to sub-licence to third parties as necessary to provide you with the Services or any part thereof.
24. Created IP
24.1. Created IP
Created IP automatically vests in us upon creation.
24.2. Assignment
You assign to us all rights, title, and interest in Created IP. You will, at our request, sign and execute documents to perfect this assignment.
25. General
25.1. We Are Not Responsible
We are not responsible for User Material provided by you or third parties on the Platform or Sites. Use of User Material is at your own risk, and we do not provide warranties for it.
25.2. Our Rights
We reserve the right to remove, modify, or reject any content you submit or post on the Platform or Sites. We may take actions such as giving warnings, removing content, or suspending/terminating accounts, as deemed appropriate.
25.3. Recording
You agree that we may record communications with you in relation to these Terms and that these recordings will serve as evidence of our interactions.
          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">26. Generaln</h2>
          <p>
          We are not responsible for User Material provided by you or third parties on the Platform or Sites. Use of User Material is at your own risk, and we do not provide warranties for it.          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Your Obligations and Liability</h2>
          <p>
          By using Laani Services, you agree not to:
a. Breach these Terms or any related agreements.
b. Use Services in violation of our Prohibited Use Policy.
c. Use Services for resale or commercial purposes unless agreed upon in writing.
d. Engage in market manipulation or fraudulent activities.
e. Use Services for lotteries, gambling, or games of chance.
f. Engage in actions that may cause complaints, disputes, or chargebacks.
g. Provide false or misleading information.
h. Use automated devices or methods to access or interfere with the Platform.
i. Modify, disassemble, or reverse-engineer the Platform.
j. Facilitate or transmit viruses or malware.
k. Use anonymizing proxies or temporary email addresses when opening an account.
l. Create any security over your Fiat Currency or Digital Assets without our consent.
m. Violate Applicable Law or intellectual property rights.
n. Access Services from high-risk jurisdictions or persons.
          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Representations and Warranties</h2>
          <p>
          You represent and warrant that:
a. All documents and information provided are true, accurate, and complete.
b. Decisions regarding these Terms are based on your own judgment and appraisal.
c. You have the power and authority to access and use the Services and perform your obligations.
d. If a legal entity, it is compliant with Applicable Law and will inform us of any changes in its structure.
e. All necessary consents, approvals, and authorizations are obtained and disclosed.
f. These Terms and any related agreements are legally binding and enforceable.
g. You are not a Restricted Person.
h. If a legal entity, you are duly organized and in good standing.
          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Liability</h2>
          <p>
          Our Liability
Laani Crypto Exchange, including its affiliates, will not be responsible for any losses suffered by you or any third party except where such losses arise solely and directly from our gross negligence, willful misconduct, actual fraud, or significant and ongoing breaches of our obligations under these Terms. In no event will the total liability of Laani Crypto Exchange and its affiliates exceed the amount of fees paid by you to us in the 12-month period immediately preceding the incident that caused the loss. This sum shall be considered full and final settlement of our liability. You acknowledge that neither Laani Crypto Exchange nor its affiliates are aware of any special circumstances specific to you, and you agree that damages are the sole remedy available to you. No other legal or equitable remedies, including injunctions or specific performance, will be available.
          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Limitations on Liability</h2>
          <p>
          Regardless of any other clause in these Terms, you agree that neither Laani Crypto Exchange nor its affiliates will be liable for:
a. Any direct or indirect losses (including loss of profits or business opportunities), damages, or costs arising out of or related to our services, including but not limited to:
i. Risks identified in our Risk Warning, as updated periodically;
ii. The operation, functionality, security, or availability of underlying protocols for any digital assets;
iii. The value of digital assets or the issuer's reserves related to any digital asset;
iv. Actions or inactions as per these Terms;
v. Inaccuracies, defects, or omissions in digital asset price data, or delays and interruptions in such data;
vi. Maintenance activities, including service interruptions and changes resulting from such maintenance;
vii. Theft of devices used to access our services;
viii. Actions, omissions, or breaches by other users or third parties;
ix. Damage caused by computer viruses, spyware, malware, phishing, spoofing, or technical problems including hardware failures or internet connectivity issues;
x. Rejection of your account application;
xi. Termination, suspension, or restriction of access to your account or services;
xii. Transaction limits applied to your account;
xiii. Decisions regarding support for digital assets;
xiv. Inability to contact you using the contact information provided;
xv. Closure of dormant accounts;
xvi. Failures or delays in transactions;
xvii. Refusal or delay in processing instructions;
xviii. Security breaches or email account issues;
xix. Losses from third-party actions, including fraud or scams involving our platform;
xx. Losses from transferring digital assets from our platform;
xxi. Losses related to new digital asset offerings or decisions on listing assets;
xxii. Network events, forks, or airdrops;
xxiii. Accuracy, reliability, or availability of our services or interruptions in access;
xxiv. Transactions or operations purportedly carried out through your account.
b. Losses related to business, profits, savings, or special, punitive, or consequential damages arising from the use of our services;
c. Losses due to extreme market volatility, manifest errors, or transaction cancellations, even if we were aware of the potential for such losses;
d. Claims not commenced within one calendar year from the event giving rise to the claim. This clause modifies any applicable legal limitation periods, and if such modification is not permissible, it will be read as the shortest enforceable period.
          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Fiat Currency</h2>
          <p>
          A balance displayed in fiat currency on your account does not imply that Laani Crypto Exchange holds fiat currency, engages in deposit taking, or similar activities.          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">30. General Terms</h2>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">30.1. Applicable Law</h2>
          <p>
          You and any Permitted User must adhere to all relevant laws, licensing requirements, and third-party rights (including data privacy laws, anti-money laundering regulations, and counter-terrorism financing laws) in your use of Laani Crypto Exchange Services, your Laani Account, and the Platform.          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">30.2. Notices</h2>
          <p>
          We may send notices to your Email Account. It is your responsibility to keep your Email Account current and accurate. Notices are considered delivered if sent to your Email Account, regardless of any delivery failure notifications. You may give us notices only as we specify, which may change over time. Notices, consents, or other communications must be in writing, in English, and signed or authorized by the party sending them.
          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">30.3. Announcements</h2>
          <p>
          Official announcements, news, promotions, competitions, and airdrops will be posted on our Website. These are important as they may affect the value or security of your Digital Assets. It is your responsibility to monitor the Website and review these announcements.          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">30.4. Entire Agreement</h2>
          <p>
          These Terms, along with any applicable Product Terms, constitute the entire agreement between you and us regarding the Laani Crypto Exchange Services. Both parties acknowledge that they have not relied on any statements, representations, or warranties other than those explicitly stated in these Terms or any Product Terms.          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">30.5. Assignment</h2>
          <p>
          You may not transfer any rights or obligations under these Terms without our prior written consent, which may require additional information or enhanced due diligence. However, we may transfer any of our rights or obligations under these Terms to anyone else, including in connection with mergers, acquisitions, or other corporate reorganizations involving Laani Crypto Exchange.          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">30.7. Records</h2>
          <p>
          We may record any telephone, email, chat conversations, or other communications with you, including those used to give instructions or effect transactions. These records will be our sole property and may be used as evidence in any proceedings related to any agreement with you.          </p>
        </section>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">30.8. Language</h2>
          <p>
          Translations of these Terms into other languages are provided for convenience. In the event of any inconsistencies or ambiguities, the English version will prevail.          </p>
        </section>

      </main>
      <footer className="bg-blue-600 text-white py-4 mt-6">
        <div className="container mx-auto text-center">
          <p>&copy; {new Date().getFullYear()} Laani Crypto Exhange . All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default TermsOfUse;
