// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth"; // Import necessary auth functions
import { getFunctions, httpsCallable } from "firebase/functions"; // Import functions and httpsCallable
import { getFirestore, collection, doc, setDoc, deleteDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDCLfO_-JiMm2qEPBinOVvItGR8OUMAelM",
  authDomain: "laani-crypto-exchange.firebaseapp.com",
  projectId: "laani-crypto-exchange",
  storageBucket: "laani-crypto-exchange.appspot.com",
  messagingSenderId: "809129078525",
  appId: "1:809129078525:web:f6f0703b9ecf7ff0f29894",
  measurementId: "G-RHML5JK10E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app); // Initialize Firestore if you plan to use it
const functions = getFunctions(app); // Initialize Cloud Functions
const getJWT = httpsCallable(functions, 'getJWT'); // Cloud function to generate JWT
const sendWelcomeEmail = httpsCallable(functions, 'sendWelcomeEmail');



export const signUp = async (email, password) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  const tokenResult = await getJWT({ uid: userCredential.user.uid });
  return tokenResult.data.token; // Return the JWT
};

export const signIn = async (email, password) => {
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  const tokenResult = await getJWT({ uid: userCredential.user.uid });
  return tokenResult.data.token; // Return the JWT
};

export const addToWatchlist = async (userId, coinId) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      watchlist: arrayUnion(coinId)
    });
  } catch (error) {
    console.error('Error adding to watchlist:', error);
  }
};

export const removeFromWatchlist = async (userId, coinId) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      watchlist: arrayRemove(coinId)
    });
  } catch (error) {
    console.error('Error removing from watchlist:', error);
  }
};

export { auth, db, sendWelcomeEmail };



