import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BsFacebook, BsTwitterX, BsYoutube, BsInstagram } from "react-icons/bs";

const Footer = () => {
  const companyLinks = [
    { name: "About Us", url: "/AboutUs" },
    { name: "Blogs", url: "/blogs" },
    { name: "Investors", url: "/investors" },
    { name: "Legal & Privacy", url: "/legal" },
  ];

  const learnLinks = [
    { name: "Browse crypto prices", url: "/coins" },
    { name: "What is Bitcoin?", url: "/blogs/what-is-bitcoin" },
    { name: "What is Cryptocurrency?", url: "/blogs/what-is-cryptocurrency" },
    { name: "What is a blockchain?", url: "/blogs/understanding-blockchain-what-is-blockchain-technology" },
    { name: "How to Buy Crypto", url: "/blogs/a-step-by-step-guide-to-buying-cryptocurrency" },
  ];

  const enterpriseLinks = [{ name: "Institutional", url: "/institutional" }];

  const socialIcons = [
    <BsFacebook key="facebook" />,
    <BsTwitterX key="twitter" />,
    <BsInstagram key="instagram" />,
    <BsYoutube key="youtube" />,
  ];

  const socialLinks = {
    facebook: "https://www.facebook.com/profile.php?id=61558770672827&mibextid=LQQJ4d",
    twitter: "https://x.com/laaniexchange?s=21&t=p7_x7snuHVdN0I8zhX2dew",
    instagram: "https://www.instagram.com/laaniexchange?igsh=aDViY2JkY2N4aHJ4&utm_source=qr",
    youtube: "https://youtube.com/@laani_exchange?si=eeq6Lq7qlv2nvTTO",
  };

  return (
    <Foot className="flex column gap">
      <div className="upper-footer">
        <div className="flex column gap-1">
          <div className="brand">
            <h2>Laani</h2>
          </div>
          <div className="address">
            <p>43 Century Boulevard, Fourways.</p>
          </div>
          <div className="info">
            <span>Email: hello@laani.io</span>
          </div>
          <div className="social-icons flex gap-2">
            {socialIcons.map((icon, index) => {
              return (
                <div className="icon" key={index}>
                  <a
                    href={socialLinks[Object.keys(socialLinks)[index]]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {icon}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex column gap-1">
          <div className="title">
            <h3>Company</h3>
          </div>
          <ul className="flex column gap-half">
            {companyLinks.map((link) => {
              return (
                <li key={link.name}>
                  <Link to={link.url}>{link.name}</Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="flex column gap-1">
          <div className="title">
            <h3>Learn</h3>
          </div>
          <ul className="flex column gap-half">
            {learnLinks.map((link) => {
              return (
                <li key={link.url}>
                  <Link to={link.url}>{link.name}</Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="flex column gap-1">
          <div className="title">
            <h3>Enterprise</h3>
          </div>
          <ul className="flex column gap-half">
            {enterpriseLinks.map((link) => {
              return (
                <li key={link.name}>
                  <Link to={link.url}>{link.name}</Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="lower-footer flex a-center j-between gap">
        <div className="col1">
          <span>&copy; By Laani Pty Ltd </span>
        </div>
        <div className="col2 flex a-center gap">
          <span>
            <Link to="/legal/terms-of-use">Terms & Conditions</Link>
          </span>
          <span className="cards"></span>
        </div>
      </div>
    </Foot>
  );
};

export default Footer;

const Foot = styled.footer`
  padding: 0rem 5rem;
  padding-bottom: 3rem;
  position: relative;
  .upper-footer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    .social-icons {
      margin-top: 2rem;
      svg {
        font-size: 2rem;
      }
      a {
        color: inherit;
        transition: color 0.3s ease;
        &:hover {
          color: #007bff; /* Change to your hover color */
        }
      }
    }
    .spacemen {
      position: absolute;
      right: 0rem;
      top: -23rem;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    padding: 1rem 2rem;
    .social-icons {
      display: none;
      flex-direction: row;
    }
    .upper-footer {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
    .spacemen {
      display: none;
    }
    .cards {
      img {
        width: 80vw;
      }
    }
  }
`;




