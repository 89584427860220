import React, { createContext, useState, useContext, useEffect } from 'react';
import Fuse from 'fuse.js';

// Define the shape of search results
interface SearchResult {
  title: string;
  url: string;
}

// Define the shape of the context props
interface SearchContextProps {
  query: string;
  setQuery: (query: string) => void;
  results: SearchResult[];
  setResults: (results: SearchResult[]) => void;
  content: SearchResult[];
  setContent: (content: SearchResult[]) => void;
}

// Create context
const SearchContext = createContext<SearchContextProps | undefined>(undefined);

// Custom hook for using search context
export const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};

// Provider component
export const SearchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<SearchResult[]>([]);
  const [content, setContent] = useState<SearchResult[]>([]);

  useEffect(() => {
    if (query) {
      const fuse = new Fuse(content, {
        keys: ['title'],
        includeScore: true,
        threshold: 0.3,
      });
      const searchResults = fuse.search(query).map(result => result.item);
      setResults(searchResults);
    } else {
      setResults([]);
    }
  }, [query, content]);

  return (
    <SearchContext.Provider value={{ query, setQuery, results, setResults, content, setContent }}>
      {children}
    </SearchContext.Provider>
  );
};
