// src/ui/PageUI/Subheading.jsx
import React from 'react';

const Subheading = ({ text }) => {
  return (
    <h2 className="text-1xl mb-1 text-center">
      {text}
    </h2>
  );
};

export default Subheading;
