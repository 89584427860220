import React from 'react';
import { ThirdwebProvider, PayEmbed } from 'thirdweb/react';
import { createWallet, walletConnect, inAppWallet } from 'thirdweb/wallets';
import { createThirdwebClient } from 'thirdweb';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import DefaultLayout from '../layout/DefaultLayout';

const client = createThirdwebClient({
  clientId: '34d73d1184693e56e37496f045510c1d'
});

const wallets = [
  createWallet('io.metamask'),
  createWallet('com.coinbase.wallet'),
  walletConnect(),
  inAppWallet({
    auth: {
      options: ['email', 'google', 'apple', 'facebook', 'phone']
    }
  })
];

const BuyCryptoPage = () => {
  return (
    <DefaultLayout>
      <Breadcrumb pageName="Buy Crypto" />

      <div className="flex flex-col items-center pt-48 -mt-20">
        <div className="max-w-lg w-full mx-auto text-center">
        <ThirdwebProvider
            clientId="34d73d1184693e56e37496f045510c1d"
            supportedWallets={wallets}
          >
            <PayEmbed client={client} />
          </ThirdwebProvider>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default BuyCryptoPage;

