import React from 'react';
import { Link } from 'react-router-dom';
import NotFoundImage from '../Assets/404PageNotFound.png';


const PageNotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <img src={NotFoundImage} alt="404 Not Found" className="w-1/3 h-auto" />
      <h1 className="text-4xl font-bold mb-4">Oops! Page Not Found</h1>
      <p className="mb-8">
        The page you're looking for doesn't exist. Please try one of the following:
      </p>
      <div className="flex space-x-4">
        <button className="bg-primary text-white px-6 py-2 rounded-md hover:bg-primary-dark">
          <Link to="/" className="block">
            Go to Home
          </Link>
        </button>
        <button className="bg-primary text-white px-6 py-2 rounded-md hover:bg-primary-dark">
          <Link to="/blogs" className="block">
            Visit Our Blogs
          </Link>
        </button>
        <button className="bg-primary text-white px-6 py-2 rounded-md hover:bg-primary-dark">
          <Link to="/help-Center" className="block">
            Get Help
          </Link>
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;