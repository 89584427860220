import React, { useState } from 'react';
import styled from 'styled-components';
import { FaAngleDown } from 'react-icons/fa';

const faqData = [
  {
    question: 'What is cryptocurrency?',
    answer:
      'Cryptocurrency is a digital or virtual currency that uses cryptography for security and operates independently of a central bank. Examples include Bitcoin, Ethereum, and Litecoin.',
  },
  {
    question: 'How do I buy cryptocurrency?',
    answer:
      'You can buy cryptocurrency from various online platforms called exchanges. You typically need to create an account, verify your identity, and then deposit funds to make a purchase.',
  },
  {
    question: 'Is cryptocurrency safe?',
    answer:
      'Cryptocurrency can be safe if you take proper precautions. This includes using secure exchanges, enabling two-factor authentication, and storing your coins in a secure wallet.',
  },
  {
    question: 'What is blockchain?',
    answer:
      'Blockchain is a decentralized, distributed ledger technology that records transactions across multiple computers in a way that is secure, transparent, and tamper-resistant.',
  },
  {
    question: 'What is Bitcoin?',
    answer:
      'Bitcoin is the first and most well-known cryptocurrency. It was created in 2009 by an unknown person or group of people using the pseudonym Satoshi Nakamoto.',
  },
  {
    question: 'How can I store my cryptocurrency?',
    answer:
      'You can store your cryptocurrency in a digital wallet. There are different types of wallets, including hardware wallets, software wallets, and paper wallets.',
  },
  {
    question: 'What is cryptocurrency mining?',
    answer:
      'Cryptocurrency mining is the process of validating transactions and adding them to a blockchain ledger. It involves using computer hardware to solve complex mathematical puzzles.',
  },
  // Additional questions
  {
    question: 'How do I start trading cryptocurrency?',
    answer:
      'To start trading cryptocurrency, you need to sign up for an account on a cryptocurrency exchange platform, verify your identity, deposit funds, and then you can start buying and selling various cryptocurrencies.',
  },
  {
    question: 'What is the difference between a cryptocurrency exchange and a wallet?',
    answer:
      'A cryptocurrency exchange is a platform where you can buy, sell, and trade cryptocurrencies, while a wallet is used to store your cryptocurrencies securely. Wallets come in different forms, such as hardware wallets, software wallets, and paper wallets.',
  },
  // Add more questions as needed
];

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <AccordionContainer>
      <AccordionHeading>Frequently Asked Questions</AccordionHeading>
      {faqData.map((item, index) => (
        <AccordionWrapper key={index}>
          <AccordionItem onClick={() => toggleAccordion(index)}>
            <AccordionQuestion>
              <QuestionContainer>
                <QuestionText>{item.question}</QuestionText>
                <IconContainer>
                  <FaAngleDown style={{ transform: activeIndex === index ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                </IconContainer>
              </QuestionContainer>
            </AccordionQuestion>
            {activeIndex === index && <AccordionAnswer>{item.answer}</AccordionAnswer>}
          </AccordionItem>
        </AccordionWrapper>
      ))}
    </AccordionContainer>
  );
};

// Styled components
const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background);
  color: var(--primary);
  padding: 20px;
`;

const AccordionHeading = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const AccordionWrapper = styled.div`
  width: 70%;
`;

const AccordionItem = styled.div`
  background-color: var(--dark-background);
  border-radius: 8px;
  margin-bottom: 16px;
  cursor: pointer;
`;

const AccordionQuestion = styled.div`
  padding: 16px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AccordionAnswer = styled.div`
  padding: 16px;
  font-size: 16px;
`;

const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const QuestionText = styled.div`
  flex: 1;
`;

const IconContainer = styled.div`
  color: var(--primary);
`;

export default Accordion;




