import React from 'react';
import { Link } from 'react-router-dom';
import Heading from '../../ui/PageUI/Heading';
import Subheading from '../../ui/PageUI/SubHeading';
import Button from '../../components/Button';
import Description from '../../ui/PageUI/Description';
import FeatureCard from '../../ui/PageUI/FeatureCard';
import ImageWithText from '../../ui/PageUI/ImageWithText';
import imageSrc from '../../Assets/blog01.jpg';
import Paragraph from '../../ui/PageUI/Paragraph';
import Intro from '../../ui/PageUI/Intro';

const HelpCenter = () => {
  return (
    <div className="container mx-auto p-8">
      <div className="text-center mb-12">
        <Heading text="Help Center" className="text-4xl font-bold mb-4" />
        <Subheading text="How can we help you today?" className="text-2xl mb-8" />
        <Intro text="Welcome to the Laani Help Center. We're here to support you on your journey with cryptocurrencies. Whether you're just getting started, have questions, or want to dive deep into tutorials, we have resources to guide you every step of the way." className="text-lg mb-8" />
      </div>

      <Description text="Explore our Help Center:" className="text-xl mb-4" />

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
        <Link to="/Help-Center/Getting-Started">
          <FeatureCard 
            title="Getting Started" 
            description="New to Laani? Learn how to set up your account, secure your wallet, and make your first trade." 
            className="transition-transform transform hover:scale-105"
          />
        </Link>
        <Link to="/Help-Center/FAQs">
          <FeatureCard 
            title="FAQs" 
            description="Have questions? Find answers to the most common inquiries about our platform and services." 
            className="transition-transform transform hover:scale-105"
          />
        </Link>
        <Link to="/Help-Center/Tutorials">
          <FeatureCard 
            title="Tutorials" 
            description="Looking for detailed guides? Our tutorials will help you understand advanced trading strategies and platform features." 
            className="transition-transform transform hover:scale-105"
          />
        </Link>
      </div>

      <div className="py-12 mb-12 ">
        <div className="container mx-auto px-8">
          <ImageWithText
            heading="Explore Our Resources"
            paragraph="At Laani, we prioritize your understanding and security in the crypto space. Our resources are designed to provide you with comprehensive information and guidance to ensure a safe and informed trading experience. Check out the sections below for more details:"
            bullets={[
              { text: "Getting Started", link: "/Help-Center/Getting-Started" },
              { text: "FAQs", link: "/Help-Center/FAQs" },
              { text: "Tutorials", link: "/Help-Center/Tutorials" },
            ]}
            imageUrl={imageSrc}
          />
        </div>
      </div>

      <div className="text-center mb-12">
        <Paragraph 
          heading="Our Commitment"
          text="Laani is committed to providing a transparent, secure, and educational environment for all our users. We believe that informed users are empowered users, and we strive to deliver the highest quality resources and support to help you navigate the world of cryptocurrencies with confidence."
          className="text-lg mb-12"
        />

        <div className="flex justify-center space-x-4">
          <Link to="/blogs">
            <Button text="Visit Blogs" />
          </Link>
          <Link to="/ContactUs">
            <Button text="Support" />
          </Link>
        </div>
      </div>

      <div className="container mx-auto px-8 mb-12">
        <h2 className="text-3xl font-bold mb-4">Additional Resources</h2>
        <p className="text-lg mb-6">Stay informed and enhance your knowledge with our additional resources:</p>
        <ul className="list-disc list-inside text-left space-y-2 mb-6">
          <li><Link to="/Help-Center/Security-Tips" className="text-blue-600 hover:underline">Security Tips</Link></li>
          <li><Link to="/Help-Center/Glossary" className="text-blue-600 hover:underline">Cryptocurrency Glossary</Link></li>
          <li><Link to="/Help-Center/Market-Insights" className="text-blue-600 hover:underline">Market Insights</Link></li>
          <li><Link to="/Help-Center/Customer-Stories" className="text-blue-600 hover:underline">Customer Stories</Link></li>
        </ul>
      </div>
    </div>
  );
};

export default HelpCenter;



