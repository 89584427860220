import benefitIcon1 from '../Assets/benefits/icon-1.svg';
import benefitIcon2 from '../Assets/benefits/icon-2.svg';
import benefitIcon3 from '../Assets/benefits/icon-3.svg';
import benefitIcon4 from '../Assets/benefits/icon-4.svg';
import benefitImage2 from '../Assets/benefits/image-2.png';
import chromecast from '../Assets/chrome-cast.svg';
import disc02 from '../Assets/disc-02.svg';
import discord from '../Assets/collaboration/tether-usdt-logo.png';
import discordBlack from '../Assets/socials/discord.svg';
import facebook from '../Assets/socials/facebook.svg';
import figma from '../Assets/collaboration/usd-coin-usdc-logo.png';
import file02 from '../Assets/file-02.svg';
import framer from '../Assets/collaboration/avalanche-avax-logo.png';
import homeSmile from '../Assets/home-smile.svg';
import instagram from '../Assets/socials/instagram.svg';
import notification2 from '../Assets/notification/image-2.png';
import notification3 from '../Assets/notification/image-3.png';
import notification4 from '../Assets/notification/image-4.png';
import notion from '../Assets/collaboration/uniswap-uni-logo.png';
import photoshop from '../Assets/collaboration/ethereum-eth-logo.png';
import plusSquare from '../Assets/plus-square.svg';
import protopie from '../Assets/collaboration/bnb-bnb-logo.png';
import raindrop from '../Assets/collaboration/bitcoin-btc-logo.png';
import recording01 from '../Assets/recording-01.svg';
import recording03 from '../Assets/recording-03.svg';
import roadmap1 from '../Assets/roadmap/image-1.png';
import roadmap2 from '../Assets/roadmap/image-2.png';
import roadmap3 from '../Assets/roadmap/image-3.png';
import roadmap4 from '../Assets/roadmap/image-4.png';
import searchMd from '../Assets/search-md.svg';
import slack from '../Assets/collaboration/solana-sol-logo.png';
import sliders04 from '../Assets/sliders-04.svg';
import telegram from '../Assets/socials/telegram.svg';
import twitter from '../Assets/socials/twitter.svg';
import mastercardlogo from '../Assets/CompanyLogos/mastercardLogo.png';
import thirdweblogo from '../Assets/CompanyLogos/thirdwebLogo.png';
import visalogo from '../Assets/CompanyLogos/visaLogo.png';
import wintermutelogo from '../Assets/CompanyLogos/wintermuteLogo.png';




export const navigation = [
  {
    id: "0",
    title: "Features",
    url: "#features",
  },
  {
    id: "1",
    title: "Pricing",
    url: "#pricing",
  },
  {
    id: "2",
    title: "How to use",
    url: "#how-to-use",
  },
  {
    id: "3",
    title: "Roadmap",
    url: "#roadmap",
  },
  {
    id: "4",
    title: "New account",
    url: "#signup",
    onlyMobile: true,
  },
  {
    id: "5",
    title: "Sign in",
    url: "#login",
    onlyMobile: true,
  },
];

export const heroIcons = [homeSmile, file02, searchMd, plusSquare];

export const notificationImages = [notification4, notification3, notification2];

export const companyLogos = [mastercardlogo, thirdweblogo, visalogo, wintermutelogo];

export const brainwaveServices = [
  "Photo generating",
  "Photo enhance",
  "Seamless Integration",
];

export const brainwaveServicesIcons = [
  recording03,
  recording01,
  disc02,
  chromecast,
  sliders04,
];

export const roadmap = [
  {
    id: "0",
    title: "Voice recognition",
    text: "Enable the chatbot to understand and respond to voice commands, making it easier for users to interact with the app hands-free.",
    date: "May 2023",
    status: "done",
    imageUrl: roadmap1,
    colorful: true,
  },
  {
    id: "1",
    title: "Gamification",
    text: "Add game-like elements, such as badges or leaderboards, to incentivize users to engage with the chatbot more frequently.",
    date: "May 2023",
    status: "progress",
    imageUrl: roadmap2,
  },
  {
    id: "2",
    title: "Chatbot customization",
    text: "Allow users to customize the chatbot's appearance and behavior, making it more engaging and fun to interact with.",
    date: "May 2023",
    status: "done",
    imageUrl: roadmap3,
  },
  {
    id: "3",
    title: "Integration with APIs",
    text: "Allow the chatbot to access external data sources, such as weather APIs or news APIs, to provide more relevant recommendations.",
    date: "May 2023",
    status: "progress",
    imageUrl: roadmap4,
  },
];

export const collabText =
  "With access to over 100 blockchains and tokens, ensuring you have the flexibility and resources to meet your unique business needs.";

export const collabContent = [
  {
    id: "0",
    title: "Simplied Crypto Management ",
    text: "Our secure wallet solutions allow your enterprise to manage and store digital assets with ease.",
  },
  {
    id: "1",
    title: "Optimized Financial Strategy.",
    text: "Efficiently manage your cryptocurrency holdings with our treasury management tools.",
  },
  {
    id: "2",
    title: "Unlock New Funding Opportunities.",
    text: "Our tokenization services make it simple to convert assets like real estate, equity, and commodities into blockchain-based tokens.",

  },
];

export const collabApps = [
  {
    id: "0",
    title: "Figma",
    icon: figma,
    width: 26,
    height: 36,
  },
  {
    id: "1",
    title: "Notion",
    icon: notion,
    width: 34,
    height: 36,
  },
  {
    id: "2",
    title: "Discord",
    icon: discord,
    width: 36,
    height: 28,
  },
  {
    id: "3",
    title: "Slack",
    icon: slack,
    width: 34,
    height: 35,
  },
  {
    id: "4",
    title: "Photoshop",
    icon: photoshop,
    width: 34,
    height: 34,
  },
  {
    id: "5",
    title: "Protopie",
    icon: protopie,
    width: 34,
    height: 34,
  },
  {
    id: "6",
    title: "Framer",
    icon: framer,
    width: 26,
    height: 34,
  },
  {
    id: "7",
    title: "Raindrop",
    icon: raindrop,
    width: 38,
    height: 32,
  },
];

export const pricing = [
  {
    id: "0",
    title: " Step 1: Sign Up ",
    description: "Quick & Secure Set Up",
    features: [
      "Create your account in minutes with just your email and a password.",
      "Enhanced Protection: Verify your identity for additional security.",
      "Peace of Mind: We prioritize user safety with industry-leading security measures.",
    ],
  },
  {
    id: "1",
    title: "Step 2: Fund Your Account",
    description: " Multiple Payment Options",
    features: [
      "Choose your preferred deposit method - bank transfer, debit/credit card or crypto transfer.",
      "Fast & Flexible: Funds are instantly credited to your account for most deposit methods.",
      "Transparent Fees: See all fees upfront before confirming your deposit.",
    ],
  },
  {
    id: "2",
    title: " Step 3:Buy Your Crypto",
    description: "Buy Bitcoin or 100 other CryptoCurrencies",
    features: [
      "Simple Interface: Our user-friendly platform makes buying crypto effortless.",
      "Access a wide range of popular cryptocurrencies to suit your investment goals.",
      "Easily Swop Cryptocurrencies: Swop Bitcoin for Solana without a fuss",
    ],
  },
];

export const benefits = [
  {
    id: "0",
    title: "Safe and Secure",
    text: "Rest assured knowing your funds are protected by industry-leading security protocols, including secure storage and account verification processes.",
    backgroundUrl: "./src/assets/benefits/card-1.svg",
    iconUrl: benefitIcon1,
    imageUrl: benefitImage2,
  },
  {
    id: "1",
    title: "Easy to Use",
    text: "Our intuitive interface makes buying, selling, and managing your crypto assets a breeze. No prior experience necessary!",
    backgroundUrl: "./src/assets/benefits/card-2.svg",
    iconUrl: benefitIcon2,
    imageUrl: benefitImage2,
    light: true,
  },
  {
    id: "2",
    title: " Deep Liquidity",
    text: "Execute trades quickly and efficiently with our deep order books, minimizing price impact and slippage.",
    backgroundUrl: "./src/assets/benefits/card-3.svg",
    iconUrl: benefitIcon3,
    imageUrl: benefitImage2,
  },
  {
    id: "3",
    title: "DeFi Functions",
    text: "Dive deeper into the decentralized world with access to innovative DeFi applications and the power to interact with DApps directly.",
    backgroundUrl: "./src/assets/benefits/card-4.svg",
    iconUrl: benefitIcon4,
    imageUrl: benefitImage2,
    light: true,
  },
  {
    id: "4",
    title: "Regulated Exchange",
    text: "We operate with compliance at the forefront, ensuring a safe and trustworthy environment for all our users.",
    backgroundUrl: "./src/assets/benefits/card-5.svg",
    iconUrl: benefitIcon1,
    imageUrl: benefitImage2,
  },
  {
    id: "5",
    title: "Seamless Payments",
    text: " Fund your account quickly and conveniently via, bank transfers, debit/credit cards, and crypto transfers. Seamless withdrawals ensure you can access your funds whenever you need them.",
    backgroundUrl: "./src/assets/benefits/card-6.svg",
    iconUrl: benefitIcon2,
    imageUrl: benefitImage2,
  },
];

export const socials = [
  {
    id: "0",
    title: "Discord",
    iconUrl: discordBlack,
    url: "#",
  },
  {
    id: "1",
    title: "Twitter",
    iconUrl: twitter,
    url: "#",
  },
  {
    id: "2",
    title: "Instagram",
    iconUrl: instagram,
    url: "#",
  },
  {
    id: "3",
    title: "Telegram",
    iconUrl: telegram,
    url: "#",
  },
  {
    id: "4",
    title: "Facebook",
    iconUrl: facebook,
    url: "#",
  },
];

export const Enteprise = [
  {
    id: "0",
    title: "Wallets and Self Custody",
    text: "Digital asset management with our secure and reliable custodial wallet solutions. We provide multi-signature security, access controls, and integration with your existing systems.",
    backgroundUrl: "./src/assets/benefits/card-1.svg",
    iconUrl: benefitIcon1,
    imageUrl: benefitImage2,
  },
  {
    id: "1",
    title: "Treasury Management",
    text: "We offer sophisticated trade execution, real-time portfolio tracking, and comprehensive reporting functionalities, allowing you to make informed investment decisions and manage risk effectively.",
    backgroundUrl: "./src/assets/benefits/card-2.svg",
    iconUrl: benefitIcon2,
    imageUrl: benefitImage2,
    light: true,
  },
  {
    id: "2",
    title: "Financial Tokenization",
    text: "We offer expertise in transforming traditional assets into secure, tradable digital tokens, opening up new avenues for fundraising, capital management, and market access for your business.",
    backgroundUrl: "./src/assets/benefits/card-3.svg",
    iconUrl: benefitIcon3,
    imageUrl: benefitImage2,
  },
];