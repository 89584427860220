import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';

const ProtectedRoute = ({ element }) => {
  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return element;
  } else {
    return <Navigate to="/sign-in" replace />;
  }
};

export default ProtectedRoute;


