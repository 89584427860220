import React from 'react';
import Heading from '../ui/PageUI/Heading';
import Subheading from '../ui/PageUI/SubHeading';
import Description from '../ui/PageUI/Description';
import FeatureCard from '../ui/PageUI/FeatureCard';
import AboutImage from '../Assets/about.png';
import Paragraph from '../ui/PageUI/Paragraph';
import Intro from '../ui/PageUI/Intro';
import ImageWithText from '../ui/PageUI/ImageWithText';
import imageSrc from '../Assets/Legal.png';

const links = [
  { name: 'Open roles', href: '#' },
  { name: 'Internship program', href: '#' },
  { name: 'Our vision', href: '#' },
  { name: 'Meet our leadership', href: '#' },
];

const stats = [
  { name: 'Liquidity Partners Worldwide', value: '12' },
  { name: 'Cryptocurrencies Available', value: '300+' },
  { name: 'Regulation First Approach', value: '4+' },
  { name: 'Available in 25+ Countries', value: '25+' },
];

const AboutUs = () => {
  return (
    <div className="relative overflow-hidden py-12 sm:py-24 text-white">
      <div className="container mx-auto px-6 lg:px-8 flex flex-col md:flex-row items-center justify-between">
        <div className="md:w-1/2 md:pr-10">
          <Heading text="About Laani" />
          <Subheading text="Crypto Accessibility: Invest Simply, Securely" />
          <Intro
            text="Investing in the future shouldn't feel complex. We focus on what matters most - making it easy and secure for you to buy, sell, and manage your digital assets. Join Laani and experience crypto investing simplified."
          />
        </div>
        <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center">
          <img className="w-full h-auto rounded-lg shadow-lg" src={AboutImage} alt="About Laani" />
        </div>
      </div>

      <Description text="Here's how we're making a difference:" className="mt-12 text-center" />
      <div className="container mx-auto px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
          <FeatureCard
            title="Financial Literacy"
            description="We offer a wealth of educational resources to help users understand cryptocurrencies and make informed investment decisions."
          />
          <FeatureCard
            title="Accessibility"
            description="Our platform is designed for everyone, regardless of experience level. We offer a variety of tools and features to simplify buying, selling, and managing crypto assets."
          />
          <FeatureCard
            title="Building Wealth"
            description="We provide a secure and reliable environment for you to invest in crypto and potentially grow your wealth over time."
          />
        </div>
      </div>

      <div className="container mx-auto px-6 lg:px-8 mt-16">
        <ImageWithText
          heading="Building Trust Through Compliance"
          paragraph="At Laani, we understand that security and compliance are paramount for building trust in the crypto space. That's why we prioritize a robust compliance framework that protects both our users and the broader crypto ecosystem. We invite you to explore the resources below for a deeper understanding of our compliance practices:"
          bullets={[
            { text: 'Know-Your-Customer (KYC) verification', link: '/KYC' },
            { text: 'Compliance Requirements', link: '/Compliance-Requirements' },
            { text: 'Digital Asset Disclosures', link: '/Digital-Assets-Disclosure' },
          ]}
          imageUrl={imageSrc}
        />
      </div>

      <div className="container mx-auto px-6 lg:px-8 mt-16">
        <Paragraph
          heading="Our Mission"
          text="Our mission is to unlock the transformative potential of cryptocurrencies, empowering individuals and institutions to build a more inclusive and prosperous financial future. We believe crypto fosters economic freedom by dismantling barriers to entry and creating a transparent, accessible financial system. Through a user-friendly platform and commitment to education, we strive to onboard over 1 billion people into the crypto economy, enabling them to participate, invest, and build wealth."
        />
      </div>

      <div className="container mx-auto px-6 lg:px-8 mt-16 text-center">
        <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-8 text-lg font-semibold">
          {links.map((link) => (
            <a key={link.name} href={link.href} className="hover:underline text-blue-400">
              {link.name} <span aria-hidden="true">&rarr;</span>
            </a>
          ))}
        </div>
        <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4 text-center">
          {stats.map((stat) => (
            <div key={stat.name} className="flex flex-col">
              <dt className="text-base font-medium">{stat.name}</dt>
              <dd className="text-2xl font-bold">{stat.value}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default AboutUs;











