import React from 'react';
import ConnectWalletButton from './Thirdweb/ConnectWalletButton';
import { FiMenu } from 'react-icons/fi'; // Import the menu icon from react-icons

const Header = (props: {
  sidebarOpen: string | boolean | undefined;
  setSidebarOpen: (arg0: boolean) => void;
}) => {
  return (
    <header className="top-0 z-30 bg-gray-900 w-full">
      <div className="flex items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11">
        {/* Menu Button */}
        <button
          aria-controls="sidebar"
          onClick={(e) => {
            e.stopPropagation();
            props.setSidebarOpen(!props.sidebarOpen);
          }}
          className="z-50 block rounded-full border border-stroke bg-gray-800 p-2 shadow-sm dark:border-strokedark dark:bg-gray-800 lg:hidden"
        >
          <FiMenu className="text-white dark:text-black" />
        </button>

        {/* Search Bar */}
        <div className="flex-grow flex items-center mx-4">
          <form action="https://formbold.com/s/unique_form_id" method="POST" className="flex w-full">
            <div className="relative flex items-center w-full">
              <input
                type="text"
                placeholder="Type to search ..."
                className="w-full bg-white pl-4 pr-10 py-2 rounded-full text-black focus:outline-none dark:text-black"
              />
              <button type="submit" className="absolute right-3 top-1/2 transform -translate-y-1/2">
                <svg
                  className="fill-current text-gray-500 dark:text-gray-500"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.16666 3.33332C5.945 3.33332 3.33332 5.945 3.33332 9.16666C3.33332 12.3883 5.945 15 9.16666 15C12.3883 15 15 12.3883 15 9.16666C15 5.945 12.3883 3.33332 9.16666 3.33332ZM1.66666 9.16666C1.66666 5.02452 5.02452 1.66666 9.16666 1.66666C13.3088 1.66666 16.6667 5.02452 16.6667 9.16666C16.6667 13.3088 13.3088 16.6667 9.16666 16.6667C5.02452 16.6667 1.66666 13.3088 1.66666 9.16666Z"
                    fill=""
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.2857 13.2857C13.6112 12.9603 14.1388 12.9603 14.4642 13.2857L18.0892 16.9107C18.4147 17.2362 18.4147 17.7638 18.0892 18.0892C17.7638 18.4147 17.2362 18.4147 16.9107 18.0892L13.2857 14.4642C12.9603 14.1388 12.9603 13.6112 13.2857 13.2857Z"
                    fill=""
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>

        {/* Connect Wallet Button */}
        <ConnectWalletButton /> {/* Add the Connect Wallet Button here */}
      </div>
    </header>
  );
};

export default Header;









