import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import TopCoin from '../../Pages/Coin/TopCoin';
import './TopCoins.css';
import Section from "../Section";
import LoadingSpinner from '../../ui/LoadingSpinner'; // A component to show loading spinner
import ErrorMessage from '../../ui/ErrorMessage'; // A component to show error message


const TopCoins = () => {
    const [topCoins, setTopCoins] = useState([]);
    const [topGainers, setTopGainers] = useState([]);
    const [trendingAssets, setTrendingAssets] = useState([]);
    const [toggle, setToggle] = useState('Top Coins'); // State for toggle selection

    useEffect(() => {
        const fetchCoins = async () => {
            try {
                const response = await axios.get(
                    'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=50&page=1&sparkline=false'
                );
                setTopCoins(response.data.slice(0, 3));
                setTopGainers(response.data.sort((a, b) => b.price_change_percentage_24h - a.price_change_percentage_24h).slice(0, 3));
                setTrendingAssets(response.data.sort((a, b) => b.market_cap_change_percentage_24h - a.market_cap_change_percentage_24h).slice(0, 3));
            } catch (error) {
                console.error('Error fetching coins:', error);
            }
        };

        fetchCoins();
    }, []);

    const renderContent = () => {
        switch (toggle) {
            case 'Top Gainers':
                return (
                    <div className="top-coins">
                        {topGainers.map((coin) => (
                             <Link to={`/coins/${coin.id}`} key={coin.id}>
                             <TopCoin coin={coin} />
                         </Link>
                        ))}
                    </div>
                );
            case 'Trending Assets':
                return (
                    <div className="top-coins">
                        {trendingAssets.map((coin) => (
                            <Link to={`/coins/${coin.id}`} key={coin.id}>
                            <TopCoin coin={coin} />
                        </Link>
                        ))}
                    </div>
                );
            case 'Top Coins':
            default:
                return (
                    <div className="top-coins">
                        {topCoins.map((coin) => (
                             <Link to={`/coins/${coin.id}`} key={coin.id}>
                                <TopCoin coin={coin} />
                            </Link>
                        ))}
                    </div>
                );
        }
    };

    return (
        <Section
        className="pt-[12rem] -mt-[5.25rem]"
        crosses
        crossesOffset="lg:translate-y-[5.25rem]"
        customPaddings>
        <div className="top-coins-wrapper">
            <div className="top-coins-container">
                <div className="left-half">
                    <div className="header">
                        <h2>Explore Crypto Assets</h2>
                        <p>Simply and securely buy, sell, and manage hundreds of cryptocurrencies.</p>
                        <Link to="/coins">
                            <button className="see-more-button">See more Assets</button>
                        </Link>
                    </div>
                </div>
                <div className="right-half">
                    <div className="toggle-buttons">
                        <button className={toggle === 'Top Coins' ? 'active' : ''} onClick={() => setToggle('Top Coins')}>Top Coins</button>
                        <button className={toggle === 'Top Gainers' ? 'active' : ''} onClick={() => setToggle('Top Gainers')}>Top Gainers</button>
                        <button className={toggle === 'Trending Assets' ? 'active' : ''} onClick={() => setToggle('Trending Assets')}>Trending Assets</button>
                    </div>
                    {renderContent()}
                </div>
            </div>
        </div>
    </Section>
    );
};

export default TopCoins;









