import React from 'react';
import './Coin.css';

const TopCoin = ({ coin }) => {
    const priceChangePercentage = coin.price_change_percentage_24h;

    return (
        <div className="top-coin coin-card">
            <div className="top-coin-info">
                <img className="coin-logo" src={coin.image} alt={coin.name} />
                <div className="coin-details">
                    <h3>{coin.name}</h3>
                    <p>${coin.current_price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</p>
                    <p className={priceChangePercentage < 0 ? 'price-down' : 'price-up'}>
                        {priceChangePercentage.toFixed(2)}%
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TopCoin;



