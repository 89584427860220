import React from 'react';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`;

const Section = styled.section`
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
`;

const List = styled.ul`
  padding-left: 20px;
  list-style-type: disc;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  margin-bottom: 10px;
`;

const ComplianceRequirements = () => {
  return (
    <Container>
      <Title>Laani Compliance requirements</Title>
      <Paragraph>
      Here's a breakdown of some frequently asked questions (FAQs) to help you understand what information we may request during the onboarding process:
      </Paragraph>
      
      <Section>
        <SectionTitle>Company Verification:</SectionTitle>
        <List>
          <ListItem>Shareholders/Members Register: Acceptable documents include extracts from government registrars, certificates of incumbency, and articles or constitutions of the legal entity. These documents should detail the legal entity name, incorporation details, and information for each shareholder (full name, number of shares, and percentage ownership). This ensures transparency regarding the company's beneficial owners.</ListItem>
          <ListItem>Director Register: Similar to the Shareholder/Member Register, this document (extracts from government registrars, certificate of incumbency, or articles of incorporation) should detail the legal entity name, incorporation details, and a list of current directors.</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>Understanding Monitorships and Receiverships:</SectionTitle>
        <List>
          <ListItem> Monitorship: This occurs when a court or regulator appoints a monitor to oversee and evaluate your company's compliance practices.</ListItem>
          <ListItem>Receivership: A court or regulator appoints a receiver who manages all aspects of your business to recover funds or return the company to profitability.</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>Certificate of Incumbency:</SectionTitle>
        <Paragraph>
        This document confirms your company's good legal standing and should include:</Paragraph>
        <List>
          <ListItem> Legal entity name and address.</ListItem>
          <ListItem>Country/place of incorporation.</ListItem>
          <ListItem>Current list of directors, officers, and beneficial owners (if applicable)</ListItem> 
        </List>
      </Section>

      <Section>
        <SectionTitle>Authorized Representative List:</SectionTitle>
        <Paragraph>
        This list should include all individuals authorized to sign contracts and agreements on behalf of the institution, including control persons, beneficial owners, and those who can submit applications for the institution.
      </Paragraph>
        <List>
          <ListItem>Control Person: Individuals with significant control over the company, including executive officers, senior managers, or those with similar functions.</ListItem>
          <ListItem>Director: Individuals appointed to manage the company's affairs, either insider (employee, officer, major shareholder) or outsider (non-employee with minimal company interest). Some directors may also be Control Persons.</ListItem>
          <ListItem>Beneficial Owner: Individuals who directly or indirectly own or control 25% or more of the company's ownership or control interest.</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>For Trusts (if applicable):</SectionTitle>
        <Paragraph>
          Laani is committed to maintaining transparency and compliance with regulatory requirements. We regularly monitor our platform for suspicious activities and report any suspicious transactions to relevant authorities.
        </Paragraph>
        <List>
          <ListItem>Trustee: Holds and administers assets for beneficiaries.</ListItem>
          <ListItem>Beneficiary: The individual or group who benefits from the trust.</ListItem>
          <ListItem>Settlor: The entity or individual who establishes the trust.</ListItem>
        </List>
      </Section>

    <SectionTitle>Laani is committed to creating a safe and compliant trading environment. By working together, we can ensure a successful and secure crypto experience for all.</SectionTitle>
    </Container>
  );
}

export default ComplianceRequirements;