import React from 'react';
import { Link } from 'react-router-dom';
import Heading from '../ui/PageUI/Heading';
import Subheading from '../ui/PageUI/SubHeading';
import Description from '../ui/PageUI/Description';
import FeatureCard from '../ui/PageUI/FeatureCard';
import Paragraph from '../ui/PageUI/Paragraph';
import ImageWithText from '../ui/PageUI/ImageWithText';
import imageSrc from '../Assets/Legal.png';

const Legal = () => {
  return (
    <div className="container mx-auto p-8 text-center">
      <Heading text="Legal and Compliance" className="text-4xl font-bold mb-4" />
      <Subheading text="View our agreements and other legal resources" className="text-2xl mb-8" />
      <Description text="Explore our legal documents and compliance information:" className="text-xl mb-6" />
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
        <Link to="/legal/terms-of-use" className="block">
          <FeatureCard 
            title="Terms of Use" 
            description="Understand the terms and conditions for using our platform."
          />
        </Link>
        <Link to="/legal/arbitration-agreement" className="block">
          <FeatureCard 
            title="Arbitration Agreement" 
            description="Review the process for resolving disputes through arbitration."
          />
        </Link>
        <Link to="/legal/privacy" className="block">
          <FeatureCard 
            title="Privacy" 
            description="Learn how we protect your personal information and data."
          />
        </Link>
        <Link to="/legal/security" className="block">
          <FeatureCard 
            title="Security" 
            description="Discover the measures we take to ensure the security of your assets."
          />
        </Link>
        <Link to="/legal/general-risk-warning" className="block">
          <FeatureCard 
            title="General Risk Warning" 
            description="Understand the risks associated with investing in cryptocurrencies."
          />
        </Link>
        <Link to="/legal/prohibited-use-policy" className="block">
          <FeatureCard 
            title="Prohibited Use Policy" 
            description="You shall use the Site or Platform solely in compliance with these Terms, solely for your own Account or your internal business purposes."
          />
        </Link>
      </div>

      <div className="py-12 mb-12 rounded-lg shadow-lg">
        <div className="container mx-auto px-8 text-left">
          <ImageWithText
            heading="Building Trust Through Compliance"
            paragraph="At Laani, we understand that security and compliance are paramount for building trust in the crypto space. That's why we prioritize a robust compliance framework that protects both our users and the broader crypto ecosystem. We invite you to explore the resources below for a deeper understanding of our compliance practices:"
            bullets={[
              { text: "Know-Your-Customer (KYC) verification", link: "/KYC" },
              { text: "Compliance Requirements", link: "/Compliance-Requirements" },
              { text: "Digital Asset Disclosures", link: "/Digital-Assets-Disclosure" },
            ]}
            imageUrl={imageSrc}
          />
        </div>
      </div>

      <Paragraph 
        heading="Our Mission"
        text="Our mission is to unlock the transformative potential of cryptocurrencies, empowering individuals and institutions to build a more inclusive and prosperous financial future. We believe crypto fosters economic freedom by dismantling barriers to entry and creating a transparent, accessible financial system. Through a user-friendly platform and commitment to education, we strive to onboard over 1 billion people into the crypto economy, enabling them to participate, invest, and build wealth."
        className="text-lg mb-12"
      />
    </div>
  );
};

export default Legal;






