import React, { useEffect, useState } from 'react';
import Button from "./Button";
import Heading from "./Heading";
import Section from "./Section";
import Tagline from "./Tagline";
import grid from '../Assets/grid.png';
import { Gradient } from "./Design/Roadmap";
import { client, urlFor } from '../../src/app/blogs/lib/sanity';
import { Link } from 'react-router-dom';

const TopBlogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    async function fetchBlogs() {
      const query = `
        *[_type == 'blog'] | order(_createdAt desc) [0...4] {
          title,
          smallDescription,
          "currentSlug": slug.current,
          titleImage,
          _createdAt
        }
      `;
      const data = await client.fetch(query);
      setBlogs(data);
    }

    fetchBlogs();
  }, []);

  return (
    <Section className="overflow-hidden" id="topblogs">
      <div className="container md:pb-8">
        <Heading tag="Your Knowledge Center" title="Crypto Learning Hub" className="text-center" />
        <div className="relative grid gap-6 md:grid-cols-2 md:gap-4 md:pb-[5rem]">
          {blogs.map((item) => {
            const status = "Done";  // Adjust status logic if needed
            const date = new Date(item._createdAt).toLocaleDateString();  // Format the date as needed

            return (
              <div
                className="md:flex even:md:translate-y-[2.5rem] p-0.25 rounded-[1rem] bg-conic-gradient"
                key={item.currentSlug}
              >
                <div className="relative p-4 bg-n-8 rounded-[1rem] overflow-hidden xl:p-8">
                  <div className="absolute top-0 left-1/2 transform -translate-x-1/2 max-w-[80%]">
                    <img
                      className="w-24 md:w-32"
                      src={grid}
                      width={200}
                      height={200}
                      alt="Grid"
                    />
                  </div>
                  <div className="relative z-1 text-center">
                    <div className="flex items-center justify-center flex-wrap mb-4 md:mb-6">
                      <Tagline>{date}</Tagline>
                      <div className="flex items-center px-2 py-1 bg-n-1 rounded text-n-8 mx-2">
                        <div className="tagline">{status}</div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="w-full aspect-w-1 aspect-h-1">
                        <img
                          className="object-cover h-full rounded-[0.25rem] w-32 md:w-40 mx-auto"
                          src={urlFor(item.titleImage).width(200).height(200).url()}
                          alt={item.title}
                        />
                      </div>
                    </div>
                    <h4 className="h4 mb-2">{item.title}</h4>
                    <p className="body-2 text-n-4">{item.smallDescription}</p>
                    <div className="flex justify-center mt-2">
                      <Link to={`/blogs/${item.currentSlug}`}>
                        <Button text="Read More" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <Gradient />
        </div>
        <div className="flex justify-center mt-8 md:mt-10 xl:mt-12">
          <Link to="/blogs">
            <Button text="Read Blogs" />
          </Link>
        </div>
      </div>
    </Section>
  );
};

export default TopBlogs;

