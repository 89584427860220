import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Routes, Route } from 'react-router-dom'; // Moved the import to the top
import Coin from '../Pages/Coin/Coin'; // Corrected import path
import Coins from '../components/CryptoCurrencies/Coins'; // Corrected import path
import '../ui/DailyPrices.css'; // Import the CSS file
import LoadingSpinner from '../ui/LoadingSpinner'; // A component to show loading spinner
import ErrorMessage from '../ui/ErrorMessage'; // A component to show error message

function DailyPrices() {
  const [coins, setCoins] = useState([]);
  const [totalMarketCap, setTotalMarketCap] = useState(0);
  const [marketCapChange, setMarketCapChange] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  
  const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
  const url = `${proxyUrl}https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=200&page=1&sparkline=false`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cachedData = localStorage.getItem('cryptoData');
        const cachedTime = localStorage.getItem('cryptoDataTime');
        const now = new Date().getTime();

        if (cachedData && cachedTime && (now - cachedTime < 3600000)) { // 1 hour cache
          const data = JSON.parse(cachedData);
          setCoins(data);
          const totalCap = data.reduce((acc, coin) => acc + coin.market_cap, 0);
          setTotalMarketCap(totalCap);
          setMarketCapChange(data[0].market_cap_change_percentage_24h); // Assuming the first coin's market cap change as overall
          setLoading(false);
        } else {
          const response = await axios.get(url);
          setCoins(response.data);
          const totalCap = response.data.reduce((acc, coin) => acc + coin.market_cap, 0);
          setTotalMarketCap(totalCap);
          setMarketCapChange(response.data[0].market_cap_change_percentage_24h); // Assuming the first coin's market cap change as overall
          localStorage.setItem('cryptoData', JSON.stringify(response.data));
          localStorage.setItem('cryptoDataTime', now);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setError('Failed to fetch data. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredCoins = coins.filter(coin =>
    coin.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;

  return (
    <div className="container mx-auto px-4 py-6">
      <header className="text-center mb-6">
        <h1 className="text-3xl font-bold">Top Crypto Currencies by Market Cap</h1>
        <p style={{ color: marketCapChange >= 0 ? 'green' : 'red' }}>
          The total market cap today is ${totalMarketCap.toLocaleString()} with a {marketCapChange.toFixed(2)}% change over the last 24 hours.
        </p>
        <p>Trade, Swap & Track: Your Crypto Command Center</p>
        <p>Price Converter: See how your crypto stacks up against any currency at the click of a button</p>
      </header>

      <Routes>
        <Route path="/" element={<Coins coins={filteredCoins} />} /> {/* Corrected route */}
        <Route path="/coins/:coinId" element={<Coin />} /> {/* Corrected route */}
      </Routes>
    </div>
  );
}

export default DailyPrices;









